import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { baseURL } from "../../service/API.js";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from "@material-ui/core/Modal";
import Swal from "sweetalert2";
import "./component.css";
import { useSelector } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";

export const EditCustomerProfileSite = (props) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Edit Customer Profile Site");
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();

  const [accounts, setAccounts] = useState([]);
  // const [campaign_period, setCampaign_period] = useState();
  const [customer_email, setCustomer_email] = useState();
  const [customer_name, setCustomer_name] = useState();
  const [segment, setSegment] = useState({});
  const [site_id, setSite_id] = useState();
  const [segmentData, setSegmentData] = useState([]);

  const [site_id_check, setSite_id_check] = useState();
  const [site_id_Error, setSite_id_Error] = useState();
  const [site_data, setSite_data] = useState();
  const [site_name, setSite_name] = useState();
  const [id, setId] = useState();
  // const [target_revenue, setTarget_revenue] = useState();
  const [transactionNo, setTransactionNo] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [account, setAccount] = useState([{ account_number: "", check_validate: false }]);
  const [siteCampaigns, setSiteCampaigns] = useState([]);


  const AlertError = (message) => {
    Swal.fire("Edit Customer Profile Site!", message, "error");
  };


  useEffect(() => {
    getSite()
    getSegment();
    setSite_id_Error(false)
    setAccounts(props.location.state.row.accounts);
    // setCampaign_period(props.location.state.row.campaign_period);
    setCustomer_email(props.location.state.row.customer_email);
    setCustomer_name(props.location.state.row.customer_name);
    setSite_id(props.location.state.row.site_id);
    setSite_id_check(props.location.state.row.site_id);
    setSite_name(props.location.state.row.site_name);
    setId(props.location.state.row.id);
    // setTarget_revenue(props.location.state.row.target_revenue);
    setTransactionNo(props.location.state.row.transactionNo);
    setSiteCampaigns(props.location.state.row.siteCampaigns);
  }, []);


  const getSegment = async () => {
    var allData = []
    axios({
      method: "get",
      url: `${baseURL}/segment`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
    }).then((res) => {
      res.data.map((data, index) => {
        data = {
          id: data.id,
          name: data.name,
        };
        allData.push(data);
      });

      var data_res = allData.find(s => Number(s.id) === Number(props.location.state.row.segment))
      console.log(data_res)
      setSegment(data_res);
      setSegmentData(allData);
    })
      .catch((err) => {
        console.log(err);
      })
  };

  const getSite = async () => {
    const allData = [];
    axios({
      method: "get",
      url: `${baseURL}/site`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
    }).then((res) => {
      res.data.data.map((data, index) => {
        data = {
          site_id: data.site_id,
        };
        allData.push(data);
      });
      setSite_data(allData)
    });
  };
  const editUser = async () => {
    setSite_id_Error(false)

    if (!site_id) {
      return AlertError("Please insert site id !!");
    }

    if (site_id) {
      let check = getSiteIdCheck(site_id)
      if (check) {
        if (check.site_id !== site_id_check) {
          setSite_id_Error(true)
          return AlertError("Site id cannot use. !!");
        }
      }
    }


    if (!site_name) {
      return AlertError("Please insert site name !!");
    }

    if (!customer_name) {
      return AlertError("Please insert customer name !!");
    }

    if (!customer_email) {
      return AlertError("Please insert customer email  !!");
    }

    if (customer_email) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(customer_email)) {
        return AlertError("Please enter valid email address. !!");

      }
    }

    if (!segment.id) {
      return AlertError("Please select segment !!");
    }

    let site_campaigns_data = []
    siteCampaigns.map((data, key) => {
      site_campaigns_data.push({ campaignId: data.campaign.id, rev_target: data.rev_target })
    })

    let data = {
      site_id: site_id,
      site_name: site_name,
      customer_name: customer_name,
      customer_email: customer_email,
      segment: Number(segment.id),
      // target_revenue: Number(values.target_revenue),
      // campaign_period: Number(values.campaign_period),
      siteCampaigns: site_campaigns_data
    };

    axios({
      method: "put",
      url: `${baseURL}/site/${props.location.state.row.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
      data: data,
    })
      .then(async (res) => {
        await Swal.fire(
          "Save !",
          "Save customer profile site success",
          "success"
        );
        await history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Created !",
          "Your can not edit customer profile site. !! " + err,
          "error"
        );
      });
  };
  const accountItem = () => {
    history.push("/DHLtoyou/AccountNo", props.location.state.row);

  };


  const handleClose = () => {
    setOpenModal(false);
  }


  const CampaignTarget = () => {

    if (site_id) {
      let check = getSiteIdCheck(site_id)
      if (check) {
        if (check.site_id !== site_id_check) {
          setSite_id_Error(true)
          return AlertError("Site id cannot use. !!");
        } else {
          setSite_id_Error(false)
          setOpenModal(true);
        }
      }
    } else {
      return AlertError("Please insert site id !!");
    }
};
  const getSiteIdCheck = (site_id) => {
    try {
      return site_data.find(s => s.site_id === site_id)
    } catch (error) {
      return ""
    }
  }

  const handleCostsChange = (event) => {
    const _tempCosts = [...siteCampaigns];
    _tempCosts[event.target.dataset.id][event.target.name] = Number(event.target.value);
    setSiteCampaigns(_tempCosts);
  };


  const Confirm_account = () => {
    setOpenModal(false);
  }
  return (
    <Card>
      <CardHeader title="Edit Customer Profile Site"></CardHeader>
      <CardBody>
        <div>
          <div className="container">
            <div className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                Site Id :{" "}
              </p>
              <input
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={site_id}
                onChange={(site_id) => setSite_id(site_id.target.value)}
              />
            </div>

            <div className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                Account No :{" "}
              </p>
              {site_id ? <button
                type="button"
                className="btn btn-primary mr-2"
                onClick={() => accountItem()}
              >
                Account
                    </button> : <button
                type="button"
                className="btn btn-primary mr-2"
                disabled
              >
                Account
                    </button>}

            </div>

            <div className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                Campaign target :{" "}
              </p>

              {site_id ? <button
                type="button"
                className="btn btn-primary mr-2"
                onClick={() => CampaignTarget()}
              >
                Target
                    </button> : <button
                type="button"
                className="btn btn-primary mr-2"
                disabled
              >
                Target
                    </button>}
            </div>

            <div className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                Site Name :{" "}
              </p>
              <input
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={site_name}
                onChange={(site_name) => setSite_name(site_name.target.value)}
              />
            </div>

            <div className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                Customer Name :{" "}
              </p>
              <input
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={customer_name}
                onChange={(customer_name) => setCustomer_name(customer_name.target.value)}
              />
            </div>

            <div className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                Customer Email :{" "}
              </p>

              <input
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={customer_email}
                onChange={(customer_email) => setCustomer_email(customer_email.target.value)}
              />
            </div>

            <div className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                Segment :{" "}
              </p>
              <Autocomplete
                id="grouped-demo"
                options={segmentData}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                value={segment}
                onChange={(event, newValue) => {
                  setSegment(newValue);
                }}
                style={{
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                renderInput={(params) =>
                  <TextField {...params} variant="outlined" />
                }
              />
            </div>

            <CardHeaderToolbar>
              <div
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              ></div>
              <Link to="/DHLtoyou/CustomerProfileSite">
                <button type="button" className="btn btn-secondary wbt">
                  Cancel
                      </button>
              </Link>
              <button
                className="btn btn-primary wbt"
                type="submit"
                onClick={() => editUser()}
              >
                Save
                    </button>
            </CardHeaderToolbar>
          </div>
        </div>
      </CardBody>



      <Modal
        open={openModal}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <div className="modal-dialog-full-width modal-dialog momodel modal-fluid">
          <div className="modal-content">
            <div className="modal-body my_scroll_divs">
              <div className="card" style={{ width: "100%" }}>
                <div className="card-body">
                  <h5 className="card-title">Site : {site_id} </h5>
                  <div
                    className="form-inline"
                  >
                    <div
                      className="table mt-1 mb-1"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <div
                          className="table mt-1 mb-1"
                          style={{ width: "650px", marginLeft: "20%" }}
                        >
                          {/* <div className="table-title">Voucher</div> */}
                          <div className="table-content">
                            <div className="table-header">
                              <div className="table-row">
                                <div className="table-data">
                                  <div>Campaign name</div>
                                </div>
                                <div className="table-data">
                                  <div>Target revenue</div>
                                </div>
                                <div className="table-data">
                                  <div></div>
                                </div>
                              </div>
                            </div>
                            <div className="table-body">
                              {siteCampaigns
                                ? siteCampaigns.map((item, index) => (
                                  <div className="table-row mt-1 form-inline" key={index}>
                                    <p style={{
                                      float: "left",
                                      width: "40%",
                                      marginLeft: "10px"
                                    }}>
                                      {item.campaign.name}
                                    </p>
                                    <input
                                      name="rev_target"
                                      data-id={index}
                                      type="number"
                                      value={item.rev_target}
                                      onChange={handleCostsChange}
                                      style={{
                                        backgroundColor: "#F4F6F9",
                                        borderRadius: "5px",
                                        borderWidth: "0",
                                      }}
                                    />
                                    {/* <button
                                        className="btn btn-danger"
                                        type="button"
                                        onClick={() => RemoveFields(index)}
                                      >
                                        -
                          </button> */}
                                  </div>
                                ))
                                : null}
                              {/* <div className="table-row"> */}
                              {/* <div className="table-data text-center">
                                    <button className="btn btn-primary" onClick={addNewCost}>
                                      +
                      </button>
                                  </div> */}
                              {/* </div> */}
                            </div>
                            {/* <div className="table-footer">
                  <div className="table-row">
                    <div className="table-data">
                      <div>Total</div>
                    </div>
                    <div className="table-data">
                      <div>{getTotalCosts()}</div>
                    </div>
                  </div>
                </div> */}
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>


                </div>
              </div>

              <div
                style={{
                  color: "#3b4fbc",
                  fontSize: "16px",
                  textAlign: "right",
                }}
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => Confirm_account()}
                >
                  Confirm
                      </button>

                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => handleClose()}
                >
                  Cancel
                    </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>




    </Card>
  );
};
