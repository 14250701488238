import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  baseURL,
} from "../../service/API.js";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";

import "./component.css";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "530px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, category, theme) {
  return {
    fontWeight:
      category.findIndex((t) => t.tag_name === name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const CreateUserProfile = (props) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Create User Manangement");
  const history = useHistory();
  const [state, setState] = useState(false);

  const classes = useStyles();
  const theme = useTheme();

  const handleSubmit = async (values, { setSubmitting }) => {
    await setSubmitting(false);
    await createUser(values);
    return;
  };

  const handleChangeSwitch = () => {
    setState(!state);
  };

  const createUser = async (values) => {

    let data = {
      email: values.email,
      password: values.password,
      username: values.username,
      fullname: values.full_name,
      is_active: state,
    };

    if (data.email && data.password && data.username && data.fullname) {
      axios
        .post(`${baseURL}/user/create`, data)
        .then((res) => {
          history.goBack();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Card>
        <CardHeader title="Create User Manangement"></CardHeader>
        <CardBody>
          <Formik
            initialValues={{
              email: "",
              username: "",
              password: "",
              full_name: "",
            }}
            validate={(values) => {
              let errors = {};

              if (!values["email"]) {
                errors["email"] = "Please enter your email Address.";
              }

              if (typeof values["email"] !== "undefined") {
                var pattern = new RegExp(
                  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
                );
                if (!pattern.test(values["email"])) {
                  errors["email"] = "Please enter valid email address.";
                }
              }

              if (!values.username) errors.username = "Username is required.";

              if (!values.password) errors.password = "Password is required.";

              if (!values.full_name)
                errors.full_name = "Full name is required.";

              //check if my values have errors
              return errors;
            }}
            onSubmit={handleSubmit}
            render={(formProps) => {
              return (
                <Form>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      Email :{" "}
                    </p>
                    <Field
                      type="text"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                    />
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></p>
                    <ErrorMessage className="colorError" name="email">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      Username :{" "}
                    </p>
                    <Field
                      type="text"
                      name="username"
                      className="form-control"
                      placeholder="Username"
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                    />
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></p>
                    <ErrorMessage className="colorError" name="username">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      Password :{" "}
                    </p>
                    <Field
                      type="text"
                      name="password"
                      className="form-control"
                      placeholder="Password"
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                    />
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></p>
                    <ErrorMessage className="colorError" name="password">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      Full name :{" "}
                    </p>
                    <Field
                      type="text"
                      name="full_name"
                      className="form-control"
                      placeholder="Full name"
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                    />
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></p>
                    <ErrorMessage className="colorError" name="full_name">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <hr></hr>
                  <div className="form-inline" style={{ marginBottom: "25px" }}>
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      Active User:{" "}
                    </p>
                    <Switch
                      checked={state}
                      onChange={() => handleChangeSwitch()}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <CardHeaderToolbar>
                  <div
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              ></div>
                    <Link to="/iExpress/UserProfile">
                      <button type="button" className="btn btn-secondary wbt">
                      Cancel
                      </button>
                    </Link>
                    <button
                      className="btn btn-primary wbt"
                      type="submit"
                      disabled={formProps.isSubmitting}
                    >
                      Save
                    </button>
                  </CardHeaderToolbar>
                </Form>
              );
            }}
          />
        </CardBody>
      </Card>
    </>
  );
};
