import React, { useEffect, useState, useCallback } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import JoditEditor from "./JoditEditor";
import { Link } from "react-router-dom";
import JoditEditorEmailNew from "./JoditEditorEmailNew";
import JoditEditorEmailNewComing from "./JoditEditorEmailNewComing";
import { useSelector } from "react-redux";

const EditEmailTemplateNewAccount = (props) => {
  const suhbeader = useSubheader();
  const { user } = useSelector((state) => state.auth);

  suhbeader.setTitle("Edit Email Template New Account");
  const [state, setState] = React.useState({
    is_active: false,
  });
  const [params, setParams] = useState();
  const [is_newComing, setIs_newComing] = useState(true);
  const [is_additional, setIs_additional] = useState(false);
  const [is_sagment, setIs_sagment] = useState(false);
  const [type, setType] = useState(true);

  const [templateName, setTemplateName] = useState();
  const [templateSubject, setTemplateSubject] = useState();
  const [templateMessage, setTemplateMessage] = useState();
  const [editorInit, setEditorInit] = useState();

  const history = useHistory();

  useEffect(() => {
    setTemplateMessage(props.location.state.row.template_message);
    setTemplateName(props.location.state.row.template_name);
    setTemplateSubject(props.location.state.row.template_subject);
    setIs_newComing(props.location.state.row.is_newComing);
    setIs_additional(props.location.state.row.is_additional);

    if (
      props.location.state.row.is_newComing &&
      !props.location.state.row.is_additional
    ) {
      setType("is_newComing");
    } else if (
      !props.location.state.row.is_newComing &&
      props.location.state.row.is_additional
    ) {
      setType("is_additional");
    }

    setState({
      id: props.location.state.row.id,
      is_active: props.location.state.row.is_active,
    });
  }, []);
  const AlertError = (message) => {
    Swal.fire("Email Template!", message, "error");
  };

  const handleSend = async () => {
    if (templateName === undefined || templateName === "") {
      return AlertError("Please insert name email template !!");
    }
    if (templateSubject === undefined || templateSubject === "") {
      return AlertError("Please insert subject email template !!");
    }
    if (templateMessage === undefined || templateMessage === "") {
      return AlertError("Please insert message email template !!");
    }

    var events = {
      is_newComing: is_newComing,
      is_additional: is_additional,
      is_segment: is_sagment,
      template_name: templateName,
      template_subject: templateSubject,
      template_message: templateMessage,
      is_active: state.is_active ? true : false,
      created_by_id: 1,
    };
    axios({
      method: "put",
      url: `${baseURL}/emailTemplate/${props.location.state.row.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
      data: events,
    })
      .then((res) => {
        Swal.fire("Save !", "Save Email template success", "success");
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Edit !",
          "Your can not Edit Email Template New Account. !! " + err,
          "error"
        );
      });
  };
  const handleChangeSwitch = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };
  const handleMessageChange = (message) => {
    setTemplateMessage(message);
  };

  const typeCheck = (even) => {
    if (even === "is_newComing") {
      setIs_newComing(true);
      setIs_additional(false);
      setIs_sagment(false);
    } else if (even === "is_additional") {
      setIs_newComing(false);
      setIs_additional(true);
      setIs_sagment(false);
    } else {
      setIs_newComing(false);
      setIs_additional(false);
      setIs_sagment(true);
    }
  };

  return (
    <Card>
      <CardHeader title="Edit Email Template New Account"></CardHeader>
      <CardBody>
        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <p
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          >
            Type:{" "}
          </p>
          <select
            as="select"
            disabled
            style={{
              height: "38px",
              width: "530px",
              backgroundColor: "#F4F6F9",
              borderRadius: "5px",
              borderWidth: "0",
            }}
            onChange={(type) => typeCheck(type.target.value)}
            value={type}
          >
            <option value="is_newComing">New Coming</option>
            <option value="is_additional">Additional</option>
            <option value="is_sagment">Segment</option>
          </select>
        </div>
        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <p
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          >
            Template Name:{" "}
          </p>
          <input
            style={{
              height: "38px",
              width: "530px",
              backgroundColor: "#F4F6F9",
              borderRadius: "5px",
              borderWidth: "0",
            }}
            value={templateName}
            onChange={(name) => setTemplateName(name.target.value)}
          />
        </div>

        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <p
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          >
            Template Subject:{" "}
          </p>
          <input
            style={{
              height: "38px",
              width: "530px",
              backgroundColor: "#F4F6F9",
              borderRadius: "5px",
              borderWidth: "0",
            }}
            value={templateSubject}
            onChange={(name) => setTemplateSubject(name.target.value)}
          />
        </div>

        {templateMessage !== undefined ? (
          is_newComing ? (
            <div>
              <JoditEditorEmailNewComing
                messageChange={handleMessageChange}
                messageValue={templateMessage}
              />
            </div>
          ) : (
            <div>
              <JoditEditorEmailNew
                messageChange={handleMessageChange}
                messageValue={templateMessage}
              />
            </div>
          )
        ) : (
          ""
        )}

        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        ></div>
        <hr></hr>
        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <p
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          >
            Active Email Template:{" "}
          </p>
          <Switch
            checked={state.is_active}
            onChange={handleChangeSwitch("is_active")}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </div>

        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <div
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          ></div>
          <Link to="/DHLtoyou/EmailTemplateNewAccount">
            <button type="button" className="btn btn-secondary wbt">
              Cancel
            </button>
          </Link>
          <button className="btn btn-primary" onClick={() => handleSend()}>
            Save
          </button>
        </div>
      </CardBody>
    </Card>
  );
};
export default EditEmailTemplateNewAccount;
