
import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import axios from "axios";
import { privilegeURLV2, privilegeURL, campaigns_id_privilage } from "../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import JoditEditor from "./JoditEditor";
import { Link } from "react-router-dom";
import "./component.css";
import { useSelector } from "react-redux";

export const EditTopic = (props) => {
  const suhbeader = useSubheader();
  const { user, authToken, accessTokenPrivilege } = useSelector((state) => state.auth);
  suhbeader.setTitle("Edit Tag Topic");
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const history = useHistory();
  const [tags, setTags] = useState([{ tag_name: "", action: "create" }]);
  const [campaigns_id_headder, setCampaigns_id_headder] = useState();
  const [users, setUsers] = useState(null);
  const [token, setToken] = useState(null);
  const path = window.location.pathname
  const AlertError = (message) => {
    Swal.fire("Tag Topic!", message, "error");
  };

  useEffect(() => {
    setId(props.location.state.row.id);
    setName(props.location.state.row.name);
    setDescription(props.location.state.row.description);

    if (props.location.state.row.tags.length > 0) {
      const tags_data = []
      props.location.state.row.tags.map((data) => {
        tags_data.push({ id: data.id, tag_name: data.tag_name, action: "edit" })
      })
      setTags(tags_data);
    }
  }, []);

  const handleSend = async () => {
    var check_null = false;
    if (name === undefined || name === "") {
      return AlertError("Please insert name !!");
    }
    if (tags.length < 0) {
      return AlertError("Please insert voucher details !!");
    }

    tags.map((data, index) => {
      if (!data.tag_name) {
        check_null = true;
        return AlertError(
          "Please insert Name Tag. !!"
        );
      }
    });

    var events = {
      campaign_id: Number(campaigns_id_privilage),
      tag_topic_name: name,
      tag_topic_description: description,
      tags: tags,
    };
    if (!check_null) {
      axios.put(`${privilegeURLV2}/tag-topic/${id}`, events, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessTokenPrivilege}`,
        }
      })
        .then(async (res) => {
          Swal.fire("Save !", "Save Tag Topic success", "success");
          history.push(path !== '/topicviewedit' ? "/DHLtoyou/Topic" : "/viewtopic");

        })
        .catch((err) => {
          Swal.fire(
            "Edit !",
            "Your can not Edit Topic. !! " + err,
            "error"
          );
        });
    }
  };

  const handleCostsChange = (event) => {
    const _tempCosts = [...tags];
    _tempCosts[event.target.dataset.id][event.target.name] = event.target.value;

    setTags(_tempCosts);
  };

  const addNewCost = () => {
    setTags((prevCosts) => [...prevCosts, { tag_name: "", action: "create" }]);
  };

  const RemoveFields = (index) => {
    const values = [...tags];
    values.splice(index, 1);
    setTags(values);
  };

  return (
    <Card>
      <CardHeader title="Edit Tag Topic"></CardHeader>
      <CardBody>
        <div className="container">
          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Name:{" "}
            </p>
            <input
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={name}
              onChange={(name) => setName(name.target.value)}
            />
          </div>
          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Description:{" "}
            </p>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              style={{
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={description}
              onChange={(description) =>
                setDescription(description.target.value)
              }
            />
          </div>

          <div
            className="form-inline"
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              <p>
                Tag :{" "}
              </p>
            </div>
            <div>
              <div
                className="table"
                style={{ width: "650px" }}
              >
                <div className="table-content">
                  {tags
                    ? tags.map((item, index) => (
                      <div className="table-row mt-1" key={index}>
                        <span className="mt-2">Name Tag {index + 1} :</span>
                        <input
                          className="m-1"
                          name="tag_name"
                          data-id={index}
                          value={item.tag_name}
                          onChange={handleCostsChange}
                          style={{
                            backgroundColor: "#F4F6F9",
                            borderRadius: "5px",
                            borderWidth: "0",
                            width: "50%",
                            height: "30px"
                          }}
                        />
                        {
                          item.action === "edit" ?
                            null :
                            < button
                              className="btn btn-danger"
                              type="button"
                              onClick={() => RemoveFields(index)}
                            >
                              -
                          </button>
                        }

                      </div>
                    ))
                    : null}
                  <div className="table-row">
                    <div className="table-data text-center">
                      <button className="btn btn-primary" onClick={addNewCost}>
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>


          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to={path !== '/topicviewedit' ? "/DHLtoyou/Topic" : "/viewtopic"}>
              <button type="button" className="btn btn-secondary wbt">
                Cancel
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => handleSend()}>
              Save
            </button>
          </div>
        </div>
      </CardBody>
    </Card >
  );
};

