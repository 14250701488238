import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import JoditEditor from "./JoditEditor";
import { Link } from "react-router-dom";
import "./component.css";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

export const EditYearLimit = (props) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Edit Year Limit");

  const [state, setState] = useState({
    is_active: false,
  });
  const [id, setId] = useState();
  const [year, setYear] = useState();
  const [limit, setLimit] = useState();

  const history = useHistory();

  useEffect(() => {

    setId(props.location.state.row.id);
    setYear(props.location.state.row.year);
    setLimit(props.location.state.row.limit);

  }, []);

  const AlertError = (message) => {
    Swal.fire("Edit Year Limit!", message, "error");
  };

  const handleSend = async () => {
    if (year === undefined || year === "") {
      return AlertError("Please insert year !!");
    }
    else if (limit === undefined || limit === "") {
      return AlertError("Please insert limit !!");
    } else {
      var events = {
        year: year,
        limit: limit,
      };
      axios
        .put(`${baseURL}/limitPoint/${id}`, events)
        .then(async (res) => {
          Swal.fire("Save !", "Save Edit Year Limit success", "success");
          history.goBack();
        })
        .catch((err) => {
          Swal.fire(
            "Created !",
            "Your can not Edit Year Limit. !! " + err,
            "error"
          );
        });
    }

  };

  const handleChangeSwitch = (year) => (event) => {
    setState({ ...state, [year]: event.target.checked });
  };

  return (
    <Card>
      <CardHeader title="Edit Year Limit"></CardHeader>
      <CardBody>
        <div className="container">
          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              year:{" "}
            </p>
            <input
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={year}
              onChange={(year) => setYear(year.target.value)}
            />
          </div>
          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              limit:{" "}
            </p>
            <input
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={limit}
              onChange={(limit) => setLimit(limit.target.value)}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/DHLtoyou/YearLimit">
              <button type="button" className="btn btn-secondary wbt">
                Cancel
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => handleSend()}>
              Save
            </button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
