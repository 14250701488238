import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import Swal from 'sweetalert2';
import { baseURL } from "../service/API.js";
import Pagination from "@material-ui/lab/Pagination";
import { useSelector } from "react-redux";

export const SpecialCampaign = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Special Campaign");
  const { user } = useSelector((state) => state.auth);
  const [specialCampaignData, setSpecialCampaignData] = useState([]);
  const allData = [];
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    getSpecialCampaign(1, 10);
  }, []);

  const getSpecialCampaign = async (page, limit, search) => {
    axios({
      method: "get",
      url: `${baseURL}/specialCampaign?page=${page}&limit=${limit}${search !== undefined && search !== '' ? "&search=" + search : ''}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
    }).then((res) => {
      res.data.data.map((data, index) => {
        data = {
          id: data.id,
          name: data.name,
          description: data.description,
          apply_to: data.apply_to,
          rate_baht: data.rate_baht,
          on_top_value: data.on_top_value,
          start_month: data.start_month,
          end_month: data.end_month,
          start_year: data.start_year,
          is_on_top: data.is_on_top,
          is_one_time_use: data.is_one_time_use,
          is_active: data.is_active,
          is_default: data.is_default,
          active: data.is_active,
          cal_id: data.id
        };

        allData.push(data);
      });
      var totalpage = Math.ceil(res.data.total / res.data.limit);
      setTotalPage(totalpage);
      setLimit(res.data.limit);
      setSpecialCampaignData(allData);
    })
      .catch((err) => {
        console.log(err);
      })
  };
  const columns = [
    {
      dataField: "cal_id",
      text: "id",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },

    {
      dataField: "apply_to",
      text: "Grouping By",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "rate_baht",
      text: "Rate baht : 1 Point",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "on_top_value",
      text: "On Top Value",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "start_month",
      text: "Start month",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "end_month",
      text: "End month",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "start_year",
      text: "Year",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "active",
      text: "Active",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Action",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button type="button" className="btn btn-primary mr-2" onClick={() => editItem({ id, row, rowIndex })}>
              Edit
            </button>

            <button type="button" className="btn btn-primary" onClick={() => deleteItem({ row, rowIndex })}>
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  const editItem = (data) => {
    history.push("/DHLtoyou/EditSpecialCampaign", data);
  };

  const deleteItem = (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        axios.delete(`${baseURL}/specialCampaign/${data.row.id}`)
          .then((res) => {
            Swal.fire(
              'Deleted!',
              'Your data has been deleted.',
              'success'
            )
            getSpecialCampaign(1, 10);
          })
          .catch((err) => {
            Swal.fire(
              'Deleted!',
              'Your data has been fail to deleted.',
              'error'
            )
          })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  };
  const [search, setSearch] = useState();

  const handleSearch = () => {
    setPage(1);
    setLimit(10);
    getSpecialCampaign(1, 10, search);
  }

  const handlePageChange = (event, value) => {
    setPage(value);
    getSpecialCampaign(value, limit);
  };

  const handlePageSizeChange = (event) => {
    setLimit(event.target.value);
    setPage(1);
    getSpecialCampaign(1, event.target.value);
  };

  return (
    <Card>
      <CardHeader title="Special Campaign">
        <CardHeaderToolbar>
          <Link to="/DHLtoyou/CreateSpecialCampaign">
            <button type="button" className="btn btn-primary">
              + Create
            </button>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <BootstrapTable
            keyField="id"
            data={specialCampaignData}
            columns={columns}
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
          />
          <div className=" col-lg-12">
            <div className="row">
              <div className="col-lg-6">
                <div className="mt-3">
                  {"Items per Page: "}
                  <select onChange={handlePageSizeChange} value={limit}>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <Pagination
                  className="mt-3 "
                  count={totalPage}
                  page={page}
                  siblingCount={1}
                  boundaryCount={1}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
