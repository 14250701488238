import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import { Link, useHistory } from "react-router-dom";
import { privilegeURL, privilegeURLV2, campaigns_id_privilage } from "../service/API.js";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import Pagination2 from "@material-ui/lab/Pagination";
import { useSelector } from "react-redux";
const queryString = require('query-string');
export const Topic = () => {
  const suhbeader = useSubheader();
  const allData = [];
  const [topic, setTopicData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalSize, setTotalSize] = useState(100);
  suhbeader.setTitle("Tag Topic");
  const [campaigns_id_headder, setCampaigns_id_headder] = useState();
  const history = useHistory();
  const { user, authToken, accessTokenPrivilege } = useSelector((state) => state.auth);

  useEffect(() => {
    setCampaigns_id_headder(campaigns_id_privilage);
    getTopic(1, 10, search, campaigns_id_privilage);

  }, []);

  const getTopic = async (page, limit, search, campaigns_id_privilage) => {
    setPage(page);
    axios({
      method: "get",
      url: `${privilegeURL}/tag-topic?campaign_id=${campaigns_id_privilage}&page=${page}&limit=${limit}${search !== undefined && search !== "" ? "&search=" + search : ""
        }`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessTokenPrivilege}`,
      },
    }).then((res) => {
      if (res.message) {
        history.push("/logout");
      } else {
        res.data.data.map((data, index) => {
          data = {
            id: data.id,
            description: data.tag_topic_description ? data.tag_topic_description : "",
            name: data.tag_topic_name ? data.tag_topic_name : "",
            tags: data.tags,
            created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
          };
          allData.push(data);
        });
      }

      var totalpage = Math.ceil(res.data.total / res.data.perPage);
      setTotalPage(totalpage);
      setTotalSize(res.data.total);
      setLimit(res.data.perPage);
      setTopicData(allData);
    });

  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Tags",
      dataField: "tags",
      formatter: (id, row, rowIndex) => {
        return (
          <>
            {row.tags.map((data, i) => {
              return (
                <>
                  {Number(row.tags.length - 1) === Number(i) ?
                    <p>{data.tag_name}</p>
                    :
                    <p>{data.tag_name},</p>
                  }
                </>
              )
            })}
          </>
        );
      },
    },
    {
      dataField: "created_at",
      text: "Create Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Action",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => editItem({ id, row, rowIndex })}
            >
              Edit
            </button>

            {/* <button
              type="button"
              className="btn btn-primary"
              onClick={() => deleteItem({ row, rowIndex })}
            >
              Delete
            </button> */}
          </div>
        );
      },
    },
  ];
  const editItem = (data) => {
    history.push("/DHLtoyou/EditTopic", data);
  };

  const deleteItem = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this template !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${privilegeURLV2}/tag-topic/${data.row.id}`)
          .then((res) => {
            Swal.fire("Deleted!", "Your template has been deleted.", "success");
            getTopic(1, limit, search, campaigns_id_headder);
          })
          .catch((err) => {
            Swal.fire(
              "Deleted!",
              "Your template has been fail to deleted.",
              "error"
            );
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your template is safe :)", "error");
      }
    });
  };
  const [search, setSearch] = useState();
  const handleSearch = () => {
    setPage(1);
    getTopic(1, limit, search, campaigns_id_headder);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    getTopic(value, limit, search, campaigns_id_headder);
  };

  const handlePageSizeChange = (event) => {
    setLimit(event.target.value);
    getTopic(1, event.target.value, search, campaigns_id_headder);
  };
  const path = window.location.pathname
  return (
    <Card>
      <CardHeader title="Tag Topic">
        <CardHeaderToolbar>
          <Link to={"/DHLtoyou/CreateTopic"}>
            <button type="button" className="btn btn-primary">
              + Create
            </button>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <BootstrapTable
          keyField="id"
          data={topic}
          columns={columns}
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
        />
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="mt-3">
                {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={limit}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <Pagination2
                className="mt-3 "
                count={totalPage}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
