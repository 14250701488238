import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import { baseURL } from "../service/API.js";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination from "@material-ui/lab/Pagination";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export const EmailList = () => {
  const suhbeader = useSubheader();
  const allData = [];
  const itemData = [];
  const [EmailData, setEmailData] = useState([]);
  const [openData, setopenData] = useState([]);

  suhbeader.setTitle("Email List");

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const history = useHistory();
  useEffect(() => {
    getEmail(1, 10);
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getEmail = async (page, limit, search) => {
    fetch(
      `${baseURL}/customer/getSendEmailList?page=${page}&limit=${limit}&is_delete=false${search !== undefined && search !== "" ? "&search=" + search : ""
      }`
    )
      .then((response) => response.json())
      .then((res) => {
        res.data.map((data, index) => {
          // console.log(data.schedule.lastRunAt)
          var send_text = "";
          if (Number(data.send_type) === 1) {
            send_text = "Once";
          }
          if (Number(data.send_type) === 2) {
            send_text = "Daily";
          }
          if (Number(data.send_type) === 3) {
            send_text = "Weekly";
          }
          if (Number(data.send_type) === 4) {
            send_text = "Monthly";
          }

          data = {
            id: data.id,
            subject: data.subject,
            group_id: data.group_id,
            template_id: data.template_id,
            message: data.message,
            send_type: data.send_type,
            send_time: data.send_time,
            monthly_date: data.monthly_date,
            is_mon: data.is_mon,
            is_tue: data.is_tue,
            is_wed: data.is_wed,
            is_thu: data.is_thu,
            is_fri: data.is_fri,
            is_sat: data.is_sat,
            is_sun: data.is_sun,
            is_resend: data.is_resend,
            day_after: data.day_after,
            created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
            start_date:
              data.start_date !== null
                ? moment(data.start_date).format("YYYY-MM-DD")
                : null,
            end_date:
              data.end_date !== null
                ? moment(data.end_date).format("YYYY-MM-DD")
                : null,
            status: data.is_resend ? true : false,
            send_type_text: send_text,
            last_run_at: data.schedule !== null ? data.schedule.lastRunAt : "",
            next_run_at: data.schedule !== null ? data.schedule.nextRunAt : "",
            template_name:
              data.mailTemplate !== null ? data.mailTemplate.template_name : "",
            group_name:
              data.targetGroup !== null ? data.targetGroup.group_name : "",
            email_id: data.id,
          };

          allData.push(data);
        });
        var totalpage = Math.ceil(res.total / res.limit);
        setTotalPage(totalpage);
        setLimit(res.limit);
        setEmailData(allData);
      });
  };

  const columns = [
    {
      dataField: "email_id",
      text: "Email Id",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "subject",
      text: "Subject",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    ,
    {
      dataField: "template_name",
      text: "template",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    ,
    {
      dataField: "group_name",
      text: "target group",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "send_type_text",
      text: "Send Type",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "start_date",
      text: "Start Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "last_run_at",
      text: "Last run at",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "next_run_at",
      text: "Next run at",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Batch",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => batchItem({ row })}
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      text: "Action",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary m-2"
              onClick={() => editItem({ id, row, rowIndex })}
            >
              Edit
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={() => deleteItem({ row, rowIndex })}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  const editItem = (data) => {
    history.push("/iExpress/EditSendEmail", data);
  };

  const batchItem = (data) => {
    axios
      .get(`${baseURL}/customer/sendmail/${data.row.id}`)
      .then((res) => {
        setopenData(res.data.batchEmails);
        setOpen({ open: true });
      })
      .catch((err) => { });
  };

  const deleteItem = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this email !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${baseURL}/customer/deleteSendEmail/${data.row.id}`)
          .then((res) => {
            Swal.fire("Deleted!", "Your email has been deleted.", "success");

            getEmail();
          })
          .catch((err) => {
            Swal.fire(
              "Deleted!",
              "Your email has been fail to deleted.",
              "error"
            );
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your target group is safe :)", "error");
      }
    });
  };
  const [search, setSearch] = useState();
  const handleSearch = () => {
    setPage(1);
    setLimit(10);
    getEmail(1, 10, search);
  }

  const handlePageChange = (event, value) => {
    setPage(value);
    getEmail(value, limit);
  };

  const handlePageSizeChange = (event) => {
    setLimit(event.target.value);
    setPage(1);
    getEmail(1, event.target.value);
  };

  return (
    <Card>
      <CardHeader title="Email List">
        <CardHeaderToolbar>
          <Link to="/iExpress/CreateSendEmail">
            <button type="button" className="btn btn-primary">
              + Create
            </button>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <BootstrapTable
            keyField="id"
            data={EmailData}
            columns={columns}
            wrapperClasses="table-responsive"
            bordered={false}
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bootstrap4
          />
          <div className=" col-lg-12">
            <div className="row">
              <div className="col-lg-6">
                <div className="mt-3">
                  {"Items per Page: "}
                  <select onChange={handlePageSizeChange} value={limit}>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <Pagination
                  className="mt-3 "
                  count={totalPage}
                  page={page}
                  siblingCount={1}
                  boundaryCount={1}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>

        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <BootstrapTable
              bordered={false}
              classes="table table-head table-vertical-center"
              bootstrap4
              keyField="id"
              pagination={paginationFactory()}
              data={openData === null ? [] : openData}
              columns={[
                {
                  text: "Sent At",
                  sort: true,
                  sortCaret: sortCaret,
                  headerSortingClasses,
                  formatter: (id, row, rowIndex) => {
                    return (
                      <div>
                        <p>
                          {row.created_at
                            ? row.created_at
                            : null}
                        </p>
                      </div>
                    );
                  },
                },
                {
                  text: "Subject",
                  sort: true,
                  sortCaret: sortCaret,
                  headerSortingClasses,
                  formatter: (id, row, rowIndex) => {
                    return (
                      <div>
                        <p>
                          {row.mailTemplate
                            ? row.mailTemplate.template_subject
                            : null}
                        </p>
                      </div>
                    );
                  },
                },
                {
                  text: "Template Name",
                  sort: true,
                  sortCaret: sortCaret,
                  headerSortingClasses,
                  formatter: (id, row, rowIndex) => {
                    return (
                      <div>
                        <p>
                          {row.mailTemplate
                            ? row.mailTemplate.template_name
                            : null}
                        </p>
                      </div>
                    );
                  },
                },
                ,
                {
                  text: "Targer Group Name",
                  sort: true,
                  sortCaret: sortCaret,
                  headerSortingClasses,
                  formatter: (id, row, rowIndex) => {
                    return (
                      <div>
                        <p>
                          {row.targetGroup
                            ? row.targetGroup.group_name
                            : null}
                        </p>
                      </div>
                    );
                  },
                },
                ,
                {
                  text: "Total Send",
                  sort: true,
                  sortCaret: sortCaret,
                  headerSortingClasses,
                  formatter: (id, row, rowIndex) => {
                    return (
                      <div>
                        <p>
                          {row.total_send
                            ? row.total_send
                            : null}
                        </p>
                      </div>
                    );
                  },
                },
                {
                  text: "Total Read",
                  sort: true,
                  sortCaret: sortCaret,
                  headerSortingClasses,
                  formatter: (id, row, rowIndex) => {
                    return (
                      <div>
                        <p>
                          {row.total_read
                            ? row.total_read
                            : null}
                        </p>
                      </div>
                    );
                  },
                },
              ]}
            >
              <PleaseWaitMessage entities={openData} />
              <NoRecordsFoundMessage entities={openData} />
            </BootstrapTable>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </CardBody>
    </Card>
  );
};

