import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import Switch from "@material-ui/core/Switch";
import { useHistory } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import { baseURL } from "../../service/API.js";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../_metronic/_helpers";
import paginationFactory from "react-bootstrap-table2-paginator";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker } from "@material-ui/pickers";

export const EditSpecialCampaign = (props) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Edit Special Campaign");
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [onTopValue, setOnTopValue] = useState();
  const [rateBaht, setRateBaht] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [type, setType] = useState();
  const [segmentData, setSegmentData] = useState([]);
  const [segment, setSegment] = useState([]);
  const [siteId, setSiteId] = useState();

  const [selectedDateStart, handleDateChangeStart] = useState(null);
  const [selectedDateEnd, handleDateChangeEnd] = useState(null);
  const [selectedYear, handleDateChangeYear] = useState(null);

  const AlertError = (message) => {
    Swal.fire("Special Campaign!", message, "error");
  };

  useEffect(() => {
    getSegment();
    getView();
  }, []);

  const getView = async () => {
    axios({
      method: "get",
      url: `${baseURL}/specialCampaign/${props.location.state.row.id}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
    }).then(async (res) => {

      var str = ""
      if (res.data.data.sites) {
        if (res.data.data.sites.length > 0) {
          res.data.data.sites.map((item, key) => {
            if (res.data.data.sites.length - 1 === key) {
              str += item
            } else {
              str += item + "\n"
            }
          })
        }
      }
      var segments_arr = []
      if (res.data.data.segments) {

        await axios({
          method: "get",
          url: `${baseURL}/segment`,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.data.access_token}`,
          },
        }).then((ress) => {
          res.data.data.segments.map((item, key) => {
            var data_res = ress.data.find(d => Number(d.id) === Number(item.segment_id))
            segments_arr.push(data_res)
          })
        })
          .catch((err) => {
            console.log(err);
          })
      }
      console.log(res.data.data.start_month)
      console.log(res.data.data.end_month)
      console.log(res.data.data.start_year)

      var start_cover = moment(`01/${res.data.data.start_month}`).format('DD-MM-YYYY HH:mm')
      var end_cover = moment(`01/${res.data.data.end_month}`).format('DD-MM-YYYY HH:mm')
      var year_cover = moment(`01/${res.data.data.end_month}/${res.data.data.start_year}`).format('DD-MM-YYYY HH:mm')


      setId(props.location.state.row.id)
      setName(res.data.data.name)
      setDescription(res.data.data.description)
      setOnTopValue(res.data.data.on_top_value ? res.data.data.on_top_value : 0)
      setRateBaht(res.data.data.rate_baht)
      handleDateChangeStart(start_cover);
      handleDateChangeEnd(end_cover);
      handleDateChangeYear(year_cover)
      setType(res.data.data.apply_to)
      setSegment(segments_arr)
      setSiteId(str)
      setState({
        isActive: res.data.data.is_active === 1 ? true : false,
        isOnTop: res.data.data.is_on_top === 1 ? true : false,
        isDefault: res.data.data.is_default === 1 ? true : false,
        isOneTime: res.data.data.is_one_time_use === 1 ? true : false,
      });

    })
      .catch((err) => {
        console.log(err);
      })
  };


  const getSegment = async () => {
    var allData = []
    axios({
      method: "get",
      url: `${baseURL}/segment`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
    }).then((res) => {
      res.data.map((data, index) => {
        data = {
          id: data.id,
          name: data.name,
        };
        allData.push(data);
      });
      setSegmentData(allData);
    })
      .catch((err) => {
        console.log(err);
      })
  };

  const [state, setState] = React.useState({
    isActive: false,
    isOnTop: false,
    isDefault: false,
    isOneTime: false,
  });

  const handleChangeSwitch = (name) => (event) => {
    if (name === "isOnTop") {
      setRateBaht(0);
      setOnTopValue(0);
    }
    setState({ ...state, [name]: event.target.checked });
  };

  const onSubmit = async () => {

    if (name === undefined) {
      return AlertError("please insert name !!");
    } else if (description === undefined) {
      return AlertError("please insert description !!");
    } else if (type === "site" && !siteId) {
      return AlertError("please insert site id !!");
    } else if (type === "segment" && segment.length === 0) {
      return AlertError("please select segment !!");
    } else if (selectedDateStart === undefined || selectedDateStart === null || selectedDateStart === "") {
      return AlertError("Please insert start !!");
    }
    else if (selectedDateEnd === undefined || selectedDateEnd === null || selectedDateStart === "") {
      return AlertError("Please insert end !!");
    } else if (selectedYear === undefined || selectedYear === null || selectedYear === "") {
      return AlertError("Please insert year !!");
    }
    else if (Number(moment(selectedDateStart._d ? selectedDateStart._d : selectedDateStart).format("MM")) > Number(moment(selectedDateEnd._d ? selectedDateEnd._d : selectedDateEnd).format("MM"))) {
      return AlertError('End date must more than start date !!')
    } else if (!state.isOnTop && (rateBaht === undefined || rateBaht === "")) {
      return AlertError("please insert rate value !!");
    } else if (state.isOnTop && (onTopValue === undefined || onTopValue === "")) {
      return AlertError("please insert  on top value !!");
    }

    var str = ""
    if (siteId !== "" || siteId !== null || siteId !== undefined) {
      str = siteId.split(new RegExp('\n', 'g'))
    }

    var segment_array = []
    if (segment.length > 0) {
      segment.map((s_arr) => {
        segment_array.push(s_arr.id)
      })
    }

    var events = {
      name: name,
      description: description,
      apply_to: type,
      sites: type === "site" ? str : [],
      segments: segment_array,
      is_on_top: state.isOnTop,
      on_top_value: onTopValue,
      rate_baht: rateBaht,
      is_one_time_use: state.isOneTime,
      start_month: moment(selectedDateStart._d ? selectedDateStart._d : selectedDateStart).format("MM"),
      start_year: moment(selectedYear._d ? selectedYear._d : selectedYear).format("YYYY"),
      end_month: moment(selectedDateEnd._d ? selectedDateEnd._d : selectedDateEnd).format("MM"),
      end_year: moment(selectedYear._d ? selectedYear._d : selectedYear).format("YYYY"),
      is_active: state.isActive,
    };

    let apiUrls = `${baseURL}/specialCampaign/${id}`;
    axios({
      method: "put",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
      data: events,
    })
      .then(async (res) => {
        Swal.fire("Save !", "Save Edit special campaign success", "success");
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Created !",
          "Your can not Edit special campaign. !! " + err,
          "error"
        );
      });
  };


  const handleChangeType = (type_name) => {
    setSiteId("")
    setSegment([])
    if (type_name === "site") {
      setType("site");
    } else if (type_name === "segment") {
      setType("segment");
    } else if (type_name === "all") {
      setType("all");
    } else {
      setType(null);
    }
  };


  return (
    <>
      <Card>
        <CardHeader title="Edit Special Campaign"></CardHeader>
        <CardBody>
          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Name:{" "}
            </p>
            <input
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={name}
              onChange={(name) => setName(name.target.value)}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Description:{" "}
            </p>

            <input
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={description}
              onChange={(name) => setDescription(name.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginTop: "20px", marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Grouping By :{" "}
            </p>
            <select
              as="select"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(type) => handleChangeType(type.target.value)}
              value={type}
              defaultValue={type}
            >
              <option value={"site"}>{"Site ID"}</option>
              <option value={"segment"}>{"Segment"}</option>
              <option value={"all"}>{"All"}</option>
            </select>
          </div>

          {type === "site" ?
            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                Site ID:{" "}
              </p>

              <textarea
                rows="3"
                className="form-control"
                style={{
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={siteId}
                onChange={(siteId) => setSiteId(siteId.target.value)}
              />
            </div>
            : null}

          {type === "segment" ?

            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <span
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                Segment:{" "}
              </span>

              <Autocomplete
                multiple
                id="grouped-demo"
                options={segmentData}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                value={segment}
                onChange={(event, newValue) => {
                  setSegment(newValue);
                }}
                style={{
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                renderInput={(params) =>
                  <TextField {...params} variant="outlined" />
                }
              />
            </div>
            : null}

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              On top:{" "}
            </p>
            <Switch
              checked={state.isOnTop}
              value="state"
              onChange={handleChangeSwitch("isOnTop")}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>

          {!state.isOnTop ? (
            <>
              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Rate baht : 1 point{" "}
                </p>

                <input
                  type="number"
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  step="any"
                  value={rateBaht}
                  onChange={(name) => setRateBaht(name.target.value)}
                />
              </div>
            </>
          ) : (
            <>
              <div
                className="form-inline"
                style={{
                  marginBottom: "[25px]",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  On top value:{" "}
                </p>
                <input
                  type="number"
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={onTopValue}
                  onChange={(name) => setOnTopValue(name.target.value)}
                />
              </div>
              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Is One Time:{" "}
                </p>
                <Switch
                  checked={state.isOneTime}
                  value="state"
                  onChange={handleChangeSwitch("isOneTime")}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>
            </>
          )}

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Start :{" "}
            </p>
            <DatePicker
              autoOk
              clearable
              variant="inline"
              views={["month"]}
              label="Month"
              value={selectedDateStart}
              onChange={handleDateChangeStart}
              format="MM"
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              End :{" "}
            </p>
            <DatePicker
              autoOk
              clearable
              variant="inline"
              views={["month"]}
              label="Month"
              value={selectedDateEnd}
              onChange={handleDateChangeEnd}
              format="MM"
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Year :{" "}
            </p>

            <DatePicker
              autoOk
              clearable
              variant="inline"
              views={["year"]}
              label="Year only"
              value={selectedYear}
              onChange={handleDateChangeYear}
              format="YYYY"
            />
          </div>

          <hr></hr>
          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Active Special Campaign:{" "}
            </p>
            <Switch
              checked={state.isActive}
              value="state"
              onChange={handleChangeSwitch("isActive")}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/DHLtoyou/SpecialCampaign">
              <button type="button" className="btn btn-secondary wbt">
                Cancel
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>Save</button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
