import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader } from "../../_metronic/_partials/controls";
import { baseURL } from "../service/API.js";
import { useSubheader } from "../../_metronic/layout";
import Swal from "sweetalert2";
import axios from "axios";
import { useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination2 from "@material-ui/lab/Pagination";
import "react-pagination-library/build/css/index.css";
import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import moment from "moment";
import "./page.css";
import { useHistory } from "react-router-dom";

export const UploadDataNewAccount = () => {
  const history = useHistory();
  const suhbeader = useSubheader();
  suhbeader.setTitle("Upload Data New Account");
  const { user } = useSelector((state) => state.auth);
  const [id, setId] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const [progress, setProgress] = useState(0);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [openDetailModalTable, setOpenDetailModalTable] = useState(false);
  const [openDetailModalTableData, setOpenDetailModalTableData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [lastPage, setLastPage] = useState(0);
  const [page, setPage] = useState(1);
  const [dataNew, setDataNew] = useState([]);
  const [openDetailModalData, setOpenDetailModalData] = useState([]);
  const [openDetailModalImport, setOpenDetailModalImport] = useState({});
  const [listData, setListData] = useState([]);
  const [dataDetail, setDataDetail] = useState({});

  const [page2, setPage2] = useState(1);
  const [totalPage2, setTotalPage2] = useState(1);
  const [limit2, setLimit2] = useState(10);
  const [totalSize2, setTotalSize2] = useState(100);
  const [saved, setSaved] = useState();

  const [page3, setPage3] = useState(1);
  const [totalPage3, setTotalPage3] = useState(1);
  const [limit3, setLimit3] = useState(10);
  const [totalSize3, setTotalSize3] = useState(100);

  const [type, setType] = useState("new_coming");
  const [type1, setType1] = useState(true);
  const [type2, setType2] = useState(false);

  const [file, setFile] = useState();
  const [namefile, setNameFile] = useState();

  const [success, setSuccess] = useState([]);
  const [error, setError] = useState([]);
  useEffect(() => {
    getlist(page2, limit2, search, type1);
  }, []);

  const AlertError = (message) => {
    Swal.fire("Upload Data New Account!", message, "error");
  };

  const getlist = async (page2, limit2, search, type1) => {
    setPage(page2);

    const allData = [];

    axios({
      method: "get",
      url: `${baseURL}/import?type=${type1 ? 0 : 1
        }&page=${page2}&limit=${limit2}${search !== undefined && search !== "" ? "&search=" + search : ""
        }`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
    })
      .then((res) => {
        if (res.message) {
          history.push("/logout");
        } else {
          res.data.data.map((data, index) => {
            data = {
              id: data.id,
              originalname: data.originalname,
              saved: data.saved,
              created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
              confirmed_date: data.confirmed_date
                ? moment(data.confirmed_date).format("YYYY-MM-DD HH:mm:ss")
                : "",
              confirmed_by: data.confirmed_by,
              uploaded_by: data.uploaded_by,
              validateFile: data.validateFile ? "Success" : "Fail"
            };
            allData.push(data);
          });
        }

        var totalpage = Math.ceil(res.data.total / res.data.limit);
        setTotalPage2(totalpage);
        setTotalSize2(res.data.total);
        setLimit2(res.data.limit);

        setListData(allData);
        setProgress(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (event, value) => {
    setPage2(value);
    getlist(value, limit2, search, type1);
  };

  const handlePageSizeChange = (event) => {
    setLimit2(event.target.value);
    getlist(1, event.target.value, search, type1);
  };

  const handlePageChange3 = (event, value) => {
    setPage3(value);
    getlist3(value, limit3);
  };

  const handlePageSizeChange3 = (event) => {
    setLimit3(event.target.value);
    getlist3(1, event.target.value);
  };

  const getlist3 = (page3, limit3) => {
    setPage(page3);

    if (type === "new_coming") {
      axios
        .get(`${baseURL}/import/${id}?page=${page3}&limit=${limit3}`)
        .then(async (res) => {
          let allData = [];
          const errorData = [];
          const successData = [];

          res.data.data.map((data, index) => {
            data = {
              no: index + 1,
              accounts_no: data.account_no,
              campaign_period: data.campaign_period,
              customer_email: data.customer_email,
              customer_name: data.customer_name,
              segment: data.segment,
              site_id: data.site_id,
              site_name: data.site_name,
              created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
              id: data.id,
              target_revenue: data.target_revenue,
              transactionNo: data.transactionNo,
              year: data.year,
              status: data.status ? data.status : "",
              errors: data.errors.length > 0 ? data.errors : [],
            };
            if (data.errors.length > 0) {
              errorData.push(data.errors);
            } else {
              successData.push([{ success: 1 }]);
            }
            allData.push(data);
          });

          var totalpage = Math.ceil(res.data.total / res.data.limit);
          setTotalPage3(totalpage);
          setTotalSize3(res.data.total);
          setLimit3(res.data.limit);
          setError(errorData);
          setSuccess(successData);
          setOpenDetailModalTableData(allData);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(`${baseURL}/import/${id}?page=${page3}&limit=${limit3}`)
        .then(async (res) => {
          const allData = [];
          const errorData = [];
          const successData = [];

          res.data.data.map((data, index) => {
            data = {
              no: index + 1,
              amount: data.amount,
              expired_month: data.expired_month,
              transactionNo: data.transactionNo,
              qty: data.qty,
              site_id: data.site.site_id,
              site_name: data.site.site_name,
              created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
              id: data.id,
              status: data.status ? data.status : "",
              errors: data.errors.length > 0 ? data.errors : [],
            };
            if (data.errors.length > 0) {
              errorData.push(data.errors);
            } else {
              successData.push([{ success: 1 }]);
            }
            allData.push(data);
          });
          var totalpage = Math.ceil(res.data.total / res.data.limit);
          setTotalPage3(totalpage);
          setTotalSize3(res.data.total);
          setLimit3(res.data.limit);
          setError(errorData);
          setSuccess(successData);
          setOpenDetailModalTableData(allData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChangeFile = async (e) => {
    setFile(null);
    setNameFile(null);
    setIsUpload(true);
    if (e.target.files.length) {
      e.preventDefault();
      let file = e.target.files[0];
      setFile(file);
      setNameFile(file.name);
    }
  };

  const save = async () => {
    setTimeout(() => {
      setProgress(25);
    }, 1000);
    const formData = new FormData();

    // Update the formData object
    formData.append("file", file, file.name);
    if (type === "new_coming") {
      setTimeout(() => {
        setProgress(70);
      }, 1500);

      axios({
        method: "post",
        url: `${baseURL}/newComing/import`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.data.access_token}`,
        },
        data: formData,
      })
        .then((res) => {
          const allData = [];
          const errorData = [];
          const successData = [];

          res.data.data.map((data, index) => {
            data = {
              no: index + 1,
              accounts_no: data.account_no ? data.account_no : "",
              campaign_period: data.campaign_period ? data.campaign_period : "",
              customer_email: data.customer_email ? data.customer_email : "",
              customer_name: data.customer_name ? data.customer_name : "",
              segment: data.segment ? data.segment : "",
              site_id: data.site_id ? data.site_id : "",
              site_name: data.site_name ? data.site_name : "",
              created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
              id: data.id,
              target_revenue: data.target_revenue ? data.target_revenue : "",
              transactionNo: data.transactionNo ? data.transactionNo : "",
              year: data.year ? data.year : "",
              status: data.status ? data.status : "",
              errors: data.errors.length > 0 ? data.errors : [],
            };
            if (data.errors.length > 0) {
              errorData.push(data.errors);
            } else {
              successData.push([{ success: 1 }]);
            }
            allData.push(data);
          });

          let lastPage = allData.length / perPage;
          setLastPage(lastPage);
          setOpenDetailModalData(allData);
          setOpenDetailModalImport(res.data.import);
          setError(errorData);
          setSuccess(successData);
          setTimeout(() => {
            setProgress(100);
            setOpenDetailModal(true);
          }, 2000);
        })
        .catch((err) => {
          Swal.fire("Error", "Your can't upload file.", "error");
          setProgress(0);
          getlist(page2, limit2, search, type1);
        });
    } else {
      setTimeout(() => {
        setProgress(70);
      }, 1500);

      axios({
        method: "post",
        url: `${baseURL}/additional/import`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.data.access_token}`,
        },
        data: formData,
      })
        .then((res) => {
          const allData = [];
          const errorData = [];
          const successData = [];

          res.data.data.map((data, index) => {
            data = {
              no: index + 1,
              amount: data.amount ? data.amount : "",
              expired_month: data.expired_month ? data.expired_month : "",
              transactionNo: data.transactionNo ? data.transactionNo : "",
              qty: data.qty ? data.qty : "",
              site_id: data.site ? data.site.site_id : "",
              site_name: data.site ? data.site.site_name : "",
              created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
              id: data.id,
              status: data.status ? data.status : "",
              errors: data.errors.length > 0 ? data.errors : [],
            };
            if (data.errors.length > 0) {
              errorData.push(data.errors);
            } else {
              successData.push([{ success: 1 }]);
            }
            allData.push(data);
          });

          let lastPage = allData.length / perPage;
          setLastPage(lastPage);
          setOpenDetailModalData(allData);
          setOpenDetailModalImport(res.data.import);
          setSuccess(successData);
          setError(errorData);
          setTimeout(() => {
            setProgress(100);
            setOpenDetailModal(true);
          }, 2000);
        })
        .catch((err) => {
          Swal.fire("Error", "Your can't upload file.", "error");
          setProgress(0);
          getlist(page2, limit2, search, type1);
        });
    }
  };

  const setOpenDetail = (name, id, row) => {
    setOpenDetailModal(true);
    setOpenDetailModalData(row);
  };

  var columns = [
    {
      dataField: "no",
      text: "No.",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "site_id",
      text: "Site Id",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "accounts_no",
      text: "Account No",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "site_name",
      text: "Site Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "customer_name",
      text: "Customer Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "customer_email",
      text: "Customer Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "segment",
      text: "Segment",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "target_revenue",
      text: "Target revenue",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "campaign_period",
      text: "Campaign Period",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "year",
      text: "Year",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ];

  const columns_detail = [
    {
      dataField: "no",
      text: "No.",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "site_id",
      text: "Site Id",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "accounts_no",
      text: "Account No",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "site_name",
      text: "Site Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "customer_name",
      text: "Customer Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "customer_email",
      text: "Customer Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "segment",
      text: "Segment",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "target_revenue",
      text: "Target revenue",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "campaign_period",
      text: "Campaign Period",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "year",
      text: "Year",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return <span>{row.errors.length > 0 ? "Fail" : "Success"}</span>;
      },
    },
  ];

  const handleClose = () => {
    setId("");
    setOpenDetailModal(false);
    setOpenDetailModalData([]);
    setOpenDetailModalTable(false);
    setOpenDetailModalTableData([]);
    setProgress(0);
    getlist(page2, limit2, search, type1);
  };

  const confirm = () => {
    let event = {
      transactionNo: openDetailModalImport.transactionNo,
    };
    if (type === "new_coming") {
      axios({
        method: "post",
        url: `${baseURL}/newComing`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.data.access_token}`,
        },
        data: event,
      })
        .then(async (res) => {
          await setProgress(0);
          await handleClose();
          await Swal.fire("Save!", "Your Save new coming.", "success");
          await getlist(page2, limit2, search, type1);
        })
        .catch((err) => {
          Swal.fire(
            "Error !",
            "Your can't save new coming. !! " + err,
            "error"
          );
          setProgress(0);
        });
    } else {
      axios({
        method: "post",
        url: `${baseURL}/additional`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.data.access_token}`,
        },
        data: event,
      })
        .then(async (res) => {
          await setProgress(0);
          await handleClose();
          await Swal.fire("Save!", "Your Save additional.", "success");
          await getlist(page2, limit2, search, type1);
        })
        .catch((err) => {
          Swal.fire(
            "Error !",
            "Your can't save additional. !! " + err,
            "error"
          );
          setProgress(0);
        });
    }
  };

  const confirm_detail = () => {
    let event = {
      transactionNo: openDetailModalImport.transactionNo,
    };
    if (type === "new_coming") {
      axios({
        method: "post",
        url: `${baseURL}/newComing`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.data.access_token}`,
        },
        data: event,
      })
        .then(async (res) => {
          await setProgress(0);
          await handleClose();
          await Swal.fire("Save!", "Your Save new coming.", "success");
          await getlist(page2, limit2, search, type1);
        })
        .catch(async (err) => {
          await detailToOpen(dataDetail);
          await setSaved(true);
          await setProgress(0);
        });
    } else {
      axios({
        method: "post",
        url: `${baseURL}/additional`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.data.access_token}`,
        },
        data: event,
      })
        .then(async (res) => {
          await setProgress(0);
          await handleClose();
          await Swal.fire("Save!", "Your Save additional.", "success");
          await getlist(page2, limit2, search, type1);
        })
        .catch(async (err) => {
          await detailToOpen(dataDetail);
          await setSaved(true);
          await setProgress(0);
        });
    }
  };

  const [search, setSearch] = useState();
  const handleSearch = () => {
    setPage(1);
    getlist(1, limit2, search, type1);
  };

  const handleChangeType = (name) => (event) => {
    if (name === "new_coming") {
      setType(name);
      setType1(true);
      setType2(false);
      setPage(1);
      getlist(1, limit2, search, true);
    } else if (name === "additional") {
      setType(name);
      setType1(false);
      setType2(true);
      setPage(1);
      getlist(1, limit2, search, false);
    } else {
      setType(null);
    }
  };

  var columns2 = [
    {
      dataField: "originalname",
      text: "File Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "created_at",
      text: "Uploaded Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "uploaded_by",
      text: "Uploaded By",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "saved",
      text: "Confirmed",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "confirmed_date",
      text: "Confirmed Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "confirmed_by",
      text: "Confirmed By",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "validateFile",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "detail",
      text: "Detail",
      sort: true,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => {
                setDataDetail({ id, row, rowIndex });
                detailToOpen({ id, row, rowIndex });
              }}
            >
              Detail
            </button>
          </div>
        );
      },
    },
  ];

  const detailToOpen = (data) => {
    setId(data.row.id);
    setSaved(data.row.saved);
    if (type === "new_coming") {
      axios
        .get(`${baseURL}/import/${data.row.id}?page=1&limit=15`)
        .then(async (res) => {
          let allData = [];
          const errorData = [];
          const successData = [];

          res.data.data.map((data, index) => {
            data = {
              no: index + 1,
              accounts_no: data.account_no,
              campaign_period: data.campaign_period,
              customer_email: data.customer_email,
              customer_name: data.customer_name,
              segment: data.segment,
              site_id: data.site_id,
              site_name: data.site_name,
              created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
              id: data.id,
              target_revenue: data.target_revenue,
              transactionNo: data.transactionNo,
              year: data.year,
              status: data.status ? data.status : "",
              errors: data.errors.length > 0 ? data.errors : [],
            };
            if (data.errors.length > 0) {
              errorData.push(data.errors);
            } else {
              successData.push([{ success: 1 }]);
            }
            allData.push(data);
          });

          var totalpage = Math.ceil(res.data.total / res.data.limit);
          setTotalPage3(totalpage);
          setTotalSize3(res.data.total);
          setLimit3(res.data.limit);
          setError(errorData);
          setSuccess(successData);
          setOpenDetailModalTableData(allData);
          setOpenDetailModalTable(true);
          setOpenDetailModalImport(res.data.import);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(`${baseURL}/import/${data.row.id}?page=1&limit=15`)
        .then(async (res) => {
          const allData = [];
          const errorData = [];
          const successData = [];

          res.data.data.map((data, index) => {
            data = {
              no: index + 1,
              amount: data.amount,
              expired_month: data.expired_month,
              transactionNo: data.transactionNo,
              qty: data.qty,
              site_id: data.site_id,
              site_name: data.site.site_name,
              created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
              id: data.id,
              status: data.status ? data.status : "",
              errors: data.errors.length > 0 ? data.errors : [],
            };
            if (data.errors.length > 0) {
              errorData.push(data.errors);
            } else {
              successData.push([{ success: 1 }]);
            }
            allData.push(data);
          });
          var totalpage = Math.ceil(res.data.total / res.data.limit);
          setTotalPage3(totalpage);
          setTotalSize3(res.data.total);
          setLimit3(res.data.limit);
          setError(errorData);
          setSuccess(successData);
          setOpenDetailModalTableData(allData);
          setOpenDetailModalTable(true);
          setOpenDetailModalImport(res.data.import);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const columns_detail2 = [
    {
      dataField: "no",
      text: "No.",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "site_id",
      text: "Site Id",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "site_name",
      text: "Site Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "qty",
      text: "Quantity",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "expired_month",
      text: "Expired month",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "created_at",
      text: "upload date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return <span>{row.errors.length > 0 ? "Fail" : "Success"}</span>;
      },
    },
  ];

  return (
    <Card>
      <CardHeader title="Upload Data New Account"></CardHeader>
      <CardBody>
        {type === "new_coming" ? (
          <Link to={`/files/new_coming.xlsx`} target="_blank" download>
            <button className="btn btn-primary mt-3">
              Export Template Excel
              </button>
          </Link>
        ) : (
          <Link to={`/files/additional.xlsx`} target="_blank" download>
            <button className="btn btn-primary mt-3">
              Export Template Excel
              </button>
          </Link>
        )}
        <div className="form-inline" style={{ marginTop: "25px" }}>
          <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            File type: <br></br>
          </p>
          <div className="form-group">
            <div className="radio-inline">
              <label className="radio">
                <input
                  type="radio"
                  name="radios3"
                  checked={type1}
                  onChange={handleChangeType("new_coming")}
                />
                <span></span>
                New Coming
              </label>
              <label className="radio">
                <input
                  type="radio"
                  name="radios3"
                  checked={type2}
                  onChange={handleChangeType("additional")}
                />
                <span></span>
                Additional
              </label>
            </div>
          </div>
        </div>

        <div className="form-inline">
          <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            Upload file: <br />
          </p>
          <input
            name="name"
            style={{
              height: "38px",
              width: "330px",
              backgroundColor: "#F4F6F9",
              borderRadius: "5px",
              borderWidth: "0",
            }}
            disabled
            value={namefile}
          />

          <span className="btn btn-primary btn-file wbt">
            เลือก / แก้ไข
            <input type="file" onChange={handleChangeFile} />
          </span>
        </div>
        {isUpload ? (
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ float: "left", width: "20%" }}> </p>
            {progress > 0 ? (
              <button className="btn btn-primary" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {"  "}Loading...
              </button>
            ) : (
              <button className="btn btn-primary mb-5" onClick={() => save()}>
                Upload
              </button>
            )}
          </div>
        ) : null}

        {progress > 0 ? (
          <div>
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped progress-bar-animatedprogress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                style={{ width: `${progress}%` }}
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {progress === 100 ? progress : `${progress} %`}
              </div>
            </div>
          </div>
        ) : null}

        <div className="row form-inline mb-2 mt-20">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <BootstrapTable
          keyField="id"
          data={listData === undefined ? [] : listData}
          columns={columns2}
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
        />
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="mt-3">
                {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={limit2}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <Pagination2
                className="mt-3 "
                count={totalPage2}
                page={page2}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
        <Modal
          open={openDetailModal}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
        >
          <div className="modal-dialog-full-width modal-dialog momodel modal-fluid">
            <div className="modal-content">
              <div className="m-2">
                <span style={{ fontSize: "30px", textAlign: "center",margin:"20px" }}>
                  Upload Data
                </span>
              </div>
              <div className="modal-body my_scroll_divs">
                {/* <Form className="col"> */}
                <BootstrapTable
                  hover
                  keyField="id"
                  pagination={paginationFactory()}
                  data={
                    openDetailModalData === undefined ? [] : openDetailModalData
                  }
                  columns={
                    type === "new_coming" ? columns_detail : columns_detail2
                  }
                />

                <div className="card my_scroll_div" style={{ width: "100%" }}>
                  <div className="card-body">
                    <h5 className="card-title">Log</h5>

                    {openDetailModalData.length > 0 ? (
                      openDetailModalData.map((data, index) => {
                        return data.errors ? (
                          data.errors.length > 0 ? (
                            data.errors.map((datas, indexs) => {
                              return (
                                <p className="card-text">
                                  Row {index + 1} : {data.site_id} :{" "}
                                  {datas.field} : {datas.message}
                                </p>
                              );
                            })
                          ) : (
                            <p className="card-text">
                              Row {index + 1} : {data.site_id} : Success
                            </p>
                          )
                        ) : null;
                      })
                    ) : (
                      <p className="card-text"></p>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-2">
                  <div className="mt-2">
                    {" "}
                    <span>Success: {success.length}</span>{" "}
                    <span>Error: {error.length}</span>{" "}
                    <span>Total: {openDetailModalData.length}</span>
                  </div>
                  <div
                    style={{
                      color: "#3b4fbc",
                      fontSize: "16px",
                      textAlign: "right",
                    }}
                  >
                    {error.length > 0 ? (
                      <button
                        disabled
                        type="button"
                        className="btn btn-primary"
                      >
                        Confirm
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => confirm()}
                      >
                        Confirm
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => handleClose()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                {/* </Form> */}
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          open={openDetailModalTable}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
        >
          <div className="modal-dialog-full-width modal-dialog momodel modal-fluid">
            <div className="modal-content">
              <div className="justify-content-center d-flex align-items-center mt-1">
                <span style={{ fontSize: "30px" }}></span>
              </div>
              <div className="modal-body my_scroll_divs">
                {/* <Form className="col"> */}
                <BootstrapTable
                  keyField="id"
                  data={openDetailModalTableData}
                  columns={
                    type === "new_coming" ? columns_detail : columns_detail2
                  }
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                />
                <div className=" col-lg-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mt-3">
                        {"Items per Page: "}
                        <select onChange={handlePageSizeChange3} value={limit3}>
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <Pagination2
                        className="mt-3 "
                        count={totalPage3}
                        page={page3}
                        siblingCount={1}
                        boundaryCount={1}
                        variant="outlined"
                        shape="rounded"
                        onChange={handlePageChange3}
                      />
                    </div>
                  </div>
                </div>

                <div className="card my_scroll_div" style={{ width: "100%" }}>
                  <div className="card-body">
                    <h5 className="card-title">Log</h5>

                    {openDetailModalTableData.length > 0 ? (
                      openDetailModalTableData.map((data, index) => {
                        return data.errors ? (
                          data.errors.length > 0 ? (
                            data.errors.map((datas, indexs) => {
                              return (
                                <p className="card-text">
                                  Row {index + 1} : {data.site_id} :{" "}
                                  {datas.field} : {datas.message}
                                </p>
                              );
                            })
                          ) : (
                            <p className="card-text">
                              Row {index + 1} : {data.site_id} : Success
                            </p>
                          )
                        ) : null;
                      })
                    ) : (
                      <p className="card-text"></p>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-between mt-2">
                  <div className="mt-2">
                    {" "}
                    <span>Success: {success.length}</span>{" "}
                    <span>Error: {error.length}</span>{" "}
                    <span>Total: {openDetailModalTableData.length}</span>
                  </div>
                  <div
                    style={{
                      color: "#3b4fbc",
                      fontSize: "16px",
                      textAlign: "right",
                    }}
                  >
                    {saved ? (
                      <button
                        disabled
                        type="button"
                        className="btn btn-primary"
                      >
                        Confirm
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => confirm_detail()}
                      >
                        Confirm
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => handleClose()}
                    >
                      Close
                    </button>
                  </div>
                </div>
                {/* </Form> */}
              </div>
            </div>
          </div>
        </Modal>
      </CardBody>
    </Card>
  );
};
