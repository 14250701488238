import React, { useEffect, useState, useCallback } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader
} from "../../../_metronic/_partials/controls";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { baseURL } from "../../service/API.js";
import { useHistory } from "react-router-dom";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from "react-select";
import Swal from 'sweetalert2';
import Switch from "@material-ui/core/Switch";
import moment from 'moment';
import { Link } from "react-router-dom";

export const EditSendEmail = (props) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Edit Send Email");
  const [targetData, setTargetData] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [state, setState] = React.useState({
    checked_monday: false,
    checked_tuesday: false,
    checked_wednesday: false,
    checked_thursday: false,
    checked_friday: false,
    checked_saturday: false,
    checked_sunday: false,
    is_resend: false
  });

  const [message, setMessage] = useState();
  const [messageShow, setMessageShow] = useState();
  const [sendType, setSendType] = useState();
  const [sendTime, setSendTime] = useState('08:00:00');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [group, setGroup] = useState();
  const [subject, setSubject] = useState();
  const [day, setDay] = useState();
  const [dayAfter, setDayAfter] = useState();

  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [template, setTemplate] = useState();

  const handleClickOpen = () => {
    try {
      axios.get(`${baseURL}/customer/sendmail/preview?template_id=${template.value}&target_geoup_id=${group.value}`)
        .then((res) => {
          setMessageShow(res.data.message)
        })
        .catch((err) => {
          setMessageShow(message)
        })
      setOpen(true);
    } catch (error) {

    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const AlertError = (message) => {
    Swal.fire(
      'Send Email!',
      message,
      'error'
    )
  }
  useEffect(() => {
    // console.log(props.location.state.row);
    getMailTemplatesList(props.location.state.row.template_id);
    getTargetGroupList(props.location.state.row.group_id);
    setStartDate(props.location.state.row.start_date)
    setEndDate(props.location.state.row.end_date)
    setSendTime(props.location.state.row.send_time)
    setDay(props.location.state.row.monthly_date)
    setState({
      checked_monday: props.location.state.row.is_mon,
      checked_tuesday: props.location.state.row.is_tue,
      checked_wednesday: props.location.state.row.is_wed,
      checked_thursday: props.location.state.row.is_thu,
      checked_friday: props.location.state.row.is_fri,
      checked_saturday: props.location.state.row.is_sat,
      checked_sunday: props.location.state.row.is_sun,
      is_resend: props.location.state.row.is_resend
    })
    setDayAfter(props.location.state.row.day_after)
    setSendType(props.location.state.row.send_type)
  }, []);

  const getMailTemplatesList = async (id) => {
    axios.get(`${baseURL}/emailTemplate?page=1&limit=10000&is_active=true&is_delete=false`)
      .then((res) => {
        var newState = []
        var label = ""
        var text = ""
        var subject = ""
        res.data.data.map((item) => {
          if (item.id === id) {
            label = item.template_name
            text = item.template_message
            subject = item.template_subject
          }
          newState.push({
            value: item.id, label: item.template_name, text: item.template_message, subject: item.template_subject
          })
        })
        const newValue = { value: id, label: label, text: text, subject: subject };
        setTemplateList(newState)
        setTemplate(newValue)
        setMessage(text)
        setSubject(subject)
      })
      .catch((err) => {
        console.log(err)
      })
  };
  const getTargetGroupList = async (id) => {
    axios.get(`${baseURL}/targetGroup?page=1&limit=10000&is_active=true&is_delete=false`)
      .then((res) => {
        var newState = []
        var label = ""
        res.data.data.map((item) => {
          if (item.id === id) {
            label = item.group_name
          }
          newState.push({
            value: item.id, label: item.group_name
          })
        })
        const newValue = { value: id, label: label };
        setTargetData(newState)
        setGroup(newValue)
      })
      .catch((err) => {
        console.log(err)
      })
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleSend = async () => {
    if (group === undefined) {
      return AlertError('Please select group id !!')
    }
    if (template === undefined || template === "") {
      return AlertError('Please select template !!')
    }
    if (sendType === undefined) {
      return AlertError('Please select send type !!')
    }
    if (Number(sendType) === 1 && (startDate === undefined || sendTime === undefined)) {
      return AlertError('Please select start date,send time !!')
    }
    if (Number(sendType) === 2 && (startDate === undefined || endDate === undefined || sendTime === undefined)) {
      return AlertError('Please select start date, end date, send time !!')
    }
    if (Number(sendType) === 3 && (startDate === undefined || endDate === undefined || sendTime === undefined)) {
      return AlertError('Please select start date, end date, send time !!')
    }
    if (Number(sendType) === 4 && (startDate === undefined || endDate === undefined || day === undefined || sendTime === undefined)) {
      return AlertError('Please select start date, end date, send time !!')
    }

    if (Number(sendType) > 1) {
      var d_start = moment(startDate).format('YYYY-MM-DD')
      var d_end = moment(endDate).format('YYYY-MM-DD')
      if (d_end < d_start) {
        return AlertError('Please select  end date > start date !!')
      }
    }
    var endDateData = endDate
    if (endDate === undefined || endDate === 'Invalid date') { endDateData = null }

    var time = sendTime;
    var res = time.substring(0, 5);
    var events = {
      group_id: group.value,
      subject: subject,
      template_id: template.value,
      message: message,
      send_type: sendType,
      send_time: res,
      start_date: startDate,
      is_mon: state.checked_monday,
      is_tue: state.checked_tuesday,
      is_wed: state.checked_wednesday,
      is_thu: state.checked_thursday,
      is_fri: state.checked_friday,
      is_sat: state.checked_saturday,
      is_sun: state.checked_sunday,
      monthly_date: day !== undefined ? Number(day) : null,
      end_date: endDateData,
      is_resend: state.is_resend ? true : false,
      day_after: dayAfter !== undefined ? Number(dayAfter) : null,
      "created_by_id": 1
    };

    axios.put(`${baseURL}/customer/updeteSendEmail/${props.location.state.row.id}`, events)
      .then((res) => {
        history.goBack()
      })
      .catch((err) => {
        console.log(err)
        Swal.fire(
          'Updated !',
          'Your can not update send email. !! ' + err,
          'error'
        )
      })
  }
  const addConditions = () => {
    return (
      <>
        {sendType != null ?
          <>
            {Number(sendType) === 4 ?
              <div className="form-inline" style={{ marginBottom: "25px" }} >
                <p style={{ marginTop: "15px", float: "left", width: "20%" }}> Day:{" "}</p>
                <select
                  as="select"
                  style={{ height: "38px", width: "530px", backgroundColor: "#F4F6F9", borderRadius: "5px", borderWidth: "0" }}
                  onChange={(day) =>
                    setDay(day.target.value === "NULL" ? null : day.target.value)
                  }
                  defaultValue={day}
                >
                  <option disabled value="NULL"> Please Select ...</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                  <option value={13}>13</option>
                  <option value={14}>14</option>
                  <option value={15}>15</option>
                  <option value={16}>16</option>
                  <option value={17}>17</option>
                  <option value={18}>18</option>
                  <option value={19}>19</option>
                  <option value={20}>20</option>
                  <option value={21}>21</option>
                  <option value={22}>22</option>
                  <option value={23}>23</option>
                  <option value={24}>24</option>
                  <option value={25}>25</option>
                  <option value={26}>26</option>
                  <option value={27}>27</option>
                  <option value={28}>28</option>
                  <option value={29}>29</option>
                  <option value={30}>30</option>
                  <option value={31}>31</option>
                </select>
              </div>
              : ''
            }

            {Number(sendType) === 3 ?
              <div className="form-inline" style={{ marginBottom: "25px" }} >
                <p style={{ marginTop: "15px", float: "left", width: "20%" }}> Date:{" "}</p>
                <FormGroup row>
                  <FormControlLabel
                    control={<Checkbox checked={state.checked_monday} onChange={handleChange} name="checked_monday" color="primary" />}
                    label="Monday"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={state.checked_tuesday} onChange={handleChange} name="checked_tuesday" color="primary" />}
                    label="Tuesday"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={state.checked_wednesday} onChange={handleChange} name="checked_wednesday" color="primary" />}
                    label="Wednesday"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={state.checked_thursday} onChange={handleChange} name="checked_thursday" color="primary" />}
                    label="Thursday"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={state.checked_friday} onChange={handleChange} name="checked_friday" color="primary" />}
                    label="Friday"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={state.checked_saturday} onChange={handleChange} name="checked_saturday" color="primary" />}
                    label="Saturday"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={state.checked_sunday} onChange={handleChange} name="checked_sunday" color="primary" />}
                    label="Sunday"
                  />
                </FormGroup>
              </div>
              : ''
            }

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }} >Start Date:{" "} </p>
              <TextField id="datestart" type="date" style={{ height: "38px", width: "185px", backgroundColor: "#F4F6F9", borderRadius: "5px", borderWidth: "0" }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(data) =>
                  setStartDate(data.target.value === "NULL" ? null : data.target.value)
                }
                value={startDate}
              />
            </div>


            {Number(sendType) !== 1 ?
              <div className="form-inline" style={{ marginBottom: "25px" }}>
                <p style={{ marginTop: "15px", float: "left", width: "20%" }} >End Date:{" "}
                </p>
                <TextField id="dateend" type="date" style={{
                  height: "38px", width: "185px", backgroundColor: "#F4F6F9", borderRadius: "5px", borderWidth: "0"
                }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(data) =>
                    setEndDate(data.target.value === "NULL" ? null : data.target.value)
                  }
                  value={endDate}
                />
              </div>
              : ''}
            <div className="form-inline" style={{ marginBottom: "25px" }} >
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>Time:{" "}</p>
              <TextField id="time" type="time" defaultValue="08:00" InputLabelProps={{ shrink: true }} inputProps={{ step: 300 }}
                style={{ height: "38px", width: "185px", backgroundColor: "#F4F6F9", borderRadius: "5px", borderWidth: "0", }}
                value={sendTime}
                onChange={(data) =>
                  setSendTime(data.target.value === "NULL" ? null : data.target.value)
                } />

            </div>
          </>
          : ''
        }

      </>
    )
  }
  const handleChangeSwitch = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };
  const createMarkup = () => {
    return { __html: messageShow };
  }

  return (
    <Card>
      <CardHeader title="Edit Send Email"></CardHeader>
      <CardBody>

        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <p
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          >
            Target Group:{" "}
          </p>
          <div style={{
            height: "38px",
            width: "530px",
          }}>
            <Select
              options={targetData}
              onChange={(group) =>
                setGroup(group)
              }
              value={group}
            />
          </div>

        </div>

        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <p
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          >
            Template:{" "}
          </p>
          <div style={{
            height: "38px",
            width: "530px",
          }}>
            <Select
              value={template}
              options={templateList}
              onChange={(template) => {
                setTemplate(template)
                setSubject(template.subject)
                setMessage(template.text)
              }
              }
            />
          </div>
        </div>

        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <div
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          ></div>
          <button className="btn btn-secondary" type="button" onClick={handleClickOpen}>Preview</button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Preview Message"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {(template !== undefined && group !== undefined) ?
                  <div dangerouslySetInnerHTML={createMarkup()} className='ck-content'></div>
                  : ''}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button className="btn btn-secondary" type="button" onClick={handleClose}>Close</button>
            </DialogActions>
          </Dialog>
        </div>

        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <p
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          >
            Send Type:{" "}
          </p>

          <select
            as="select"
            style={{
              height: "38px",
              width: "530px",
              backgroundColor: "#F4F6F9",
              borderRadius: "5px",
              borderWidth: "0",
            }}
            value={sendType}
            onChange={(sendType) =>
              setSendType(sendType.target.value)
            }
          >
            <option disabled value="NULL"> Please Select ...</option>
            <option value={1}>Once</option>
            <option value={2}>Daily</option>
            <option value={3}>Weekly</option>
            <option value={4}>Monthly</option>
          </select>
        </div>

        {addConditions()}
        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <p
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          >
            Resend Email:{" "}
          </p>
          <Switch
            checked={state.is_resend}
            onChange={handleChangeSwitch("is_resend")}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </div>
        {state.is_resend ?
          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Day After (1 = one day):{" "}
            </p>
            <select
              as="select"
              style={{ height: "38px", width: "530px", backgroundColor: "#F4F6F9", borderRadius: "5px", borderWidth: "0" }}
              onChange={(day) =>
                setDayAfter(day.target.value === "NULL" ? null : day.target.value)
              }
              defaultValue={dayAfter}
            >
              <option disabled value="NULL"> Please Select ...</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={12}>12</option>
              <option value={13}>13</option>
              <option value={14}>14</option>
              <option value={15}>15</option>
            </select>

          </div>
          : ''
        }
        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <div
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          ></div>
          <Link to="/iExpress/EmailList">
              <button
                type="button"
                className="btn btn-secondary wbt"
              >
                Cancel
              </button>
            </Link>
          <button className="btn btn-primary" onClick={() => handleSend()}>Save</button>
        </div>
      </CardBody>
    </Card>
  );
};
