import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader } from "../../_metronic/_partials/controls";
import { baseURL } from "../service/API.js";
import { useSubheader } from "../../_metronic/layout";
import Swal from "sweetalert2";
import axios from "axios";
import { useSelector } from "react-redux";
import xlsxFile from "read-excel-file";

import { Link } from "react-router-dom";
export const UpdateRewardsStatusToYou = () => {
  const { user, authToken } = useSelector((state) => state.auth);
  const suhbeader = useSubheader();
  suhbeader.setTitle("Update Rewards Status");

  const [state, setState] = useState({
    imageId: null,
    imageName: "",
    imageURL: "",
  });
  const [isUpload, setIsUpload] = useState(false);
  const [progress, setProgress] = useState(0);

  const AlertError = (message) => {
    Swal.fire("Update Rewards Status!", message, "error");
  };

  const dhlURL = baseURL.split("/")[2];
  const handleChangeFile = async (e) => {
    let check_error = false;
    setIsUpload(true);
    setProgress(25);
    if (e.target.files.length) {
      e.preventDefault();
      let file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      let data_all = [];

      await xlsxFile(file).then(async (rows) => {
        setProgress(100);

        if (rows[0][0] !== "redemption_number") {
          setProgress(0);
          setIsUpload(false);
          check_error = true
          return AlertError("Error file type!!");
        }

        if (rows[0][1] !== "status_en") {
          setProgress(0);
          setIsUpload(false);
          check_error = true
          return AlertError("Error file type!!");

        }

        if (rows[0][2] !== "status_th") {
          setProgress(0);
          setIsUpload(false);
          check_error = true
          return AlertError("Error file type!!");
        }
        await rows.map((data, i) => {
          if (i !== 0) {
            let da = {
              order_number: data[0],
              status_en: data[1],
              status_th: data[2],
            };
            data_all.push(da)
          }
        })

      });


      await data_all.map((data, i) => {
        if (!data.order_number) {
          setProgress(0);
          setIsUpload(false);
          check_error = true
          return AlertError("Redemption number not null !!");
        }

        if (!data.status_en) {
          setProgress(0);
          setIsUpload(false);
          check_error = true
          return AlertError("Status en not null !!");

        }

        if (!data.status_th) {
          setProgress(0);
          setIsUpload(false);
          check_error = true
          return AlertError("Status th not null !!");
        }
      })

      if (!check_error) {
        await axios.post(`${baseURL}/toyou/redeemHistory/updateStatus`, { updateStatus: data_all }, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          }
        })
          .then((res) => {
            Swal.fire("Updated !", "Your can update status. !! ");
          })
          .catch((err) => {
            if (err.response) {
              Swal.fire(
                "Updated !",
                "Your can not update status. !! " + err.response.data.message,
                "error"
              );
            }
          });
      }

    }
  };

  const save = async () => {
    var events = {
      image: state.imageName,
      link: state.imageURL,
      weight: 2,
    };
  };

  const toFile = async () => { };

  return (
    <Card>
      <CardHeader title="Update Rewards Status"></CardHeader>
      <CardBody>
        <>
          <form>
            <div
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
              }}
            ></div>
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Upload file: <br></br>
              </p>
              <input
                name="ImageURL"
                style={{
                  height: "38px",
                  width: "330px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                disabled
                value={state.imageURL}
              />

              <span className="btn btn-primary btn-file wbt">
                เลือก / แก้ไข
                <input type="file" onChange={handleChangeFile} />
              </span>
            </div>
            {isUpload ? (
              <div>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${progress}%` }}
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {progress}
                  </div>
                </div>
              </div>
            ) : null}
            {/* <div
            className="form-inline"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <button type="button" className="btn btn-primary " onClick={save}>
              Save
            </button>
          </div> */}
          </form>
          <Link to="/files/delivery_status.xlsx" target="_blank" download>
            <button className="btn btn-primary mt-3">
              Export Template Excel
            </button>
          </Link>
        </>
      </CardBody>
    </Card>
  );
};
