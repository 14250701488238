import React, { useEffect, useState, useCallback } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader
} from "../../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import Switch from "@material-ui/core/Switch";
import JoditEditor from './JoditEditor'
import { Link } from "react-router-dom";

const EditEmailTemplate = (props) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Edit Email Template");
  const [state, setState] = React.useState({
    is_active: false
  });
  const [params, setParams] = useState();
  const [templateName, setTemplateName] = useState();
  const [templateSubject, setTemplateSubject] = useState();
  const [templateMessage, setTemplateMessage] = useState();
  const [editorInit, setEditorInit] = useState();

  const history = useHistory();

  useEffect(() => {
    setTemplateMessage(props.location.state.row.template_message);
    setTemplateName(props.location.state.row.template_name);
    setTemplateSubject(props.location.state.row.template_subject);

    setState({
      id: props.location.state.row.id
      , is_active: props.location.state.row.is_active
    });
  }, []);
  const AlertError = (message) => {
    Swal.fire(
      'Email Template!',
      message,
      'error'
    )
  }

  const handleSend = async () => {
    if (templateName === undefined || templateName === "") {
      return AlertError('Please insert name email template !!')
    }
    if (templateSubject === undefined || templateSubject === "") {
      return AlertError('Please insert subject email template !!')
    }
    if (templateMessage === undefined || templateMessage === "") {
      return AlertError('Please insert message email template !!')
    }

    var events = {
      template_name: templateName,
      template_subject: templateSubject,
      template_message: templateMessage,
      is_active: state.is_active ? true : false,
      "created_by_id": 1
    };

    axios.put(`${baseURL}/emailTemplate/${props.location.state.row.id}`, events)
      .then((res) => {
        Swal.fire("Save !", "Save email template success", "success");
        history.goBack()
      })
      .catch((err) => {
        Swal.fire(
          'Edit !',
          'Your can not edit email template. !! ' + err,
          'error'
        )
      })
  }
  const handleChangeSwitch = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };
  const handleMessageChange = (message) => {
    setTemplateMessage(message)
  }
  return (
    <Card>
      <CardHeader title="Create Email Template"></CardHeader>
      <CardBody>
        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <p
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          >
            Template Name:{" "}
          </p>
          <input
            style={{
              height: "38px",
              width: "530px",
              backgroundColor: "#F4F6F9",
              borderRadius: "5px",
              borderWidth: "0",
            }}
            value={templateName}
            onChange={(name) => setTemplateName(name.target.value)}
          />
        </div>

        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <p
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          >
            Template Subject:{" "}
          </p>
          <input
            style={{
              height: "38px",
              width: "530px",
              backgroundColor: "#F4F6F9",
              borderRadius: "5px",
              borderWidth: "0",
            }}
            value={templateSubject}
            onChange={(name) => setTemplateSubject(name.target.value)}
          />
        </div>

        <div>
          {templateMessage !== undefined ?
            <JoditEditor messageChange={handleMessageChange} messageValue={templateMessage} />
            : ''}
        </div>


        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >

        </div>
        <hr></hr>
        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <p
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          >
            Active Email Template:{" "}
          </p>
          <Switch
            checked={state.is_active}
            onChange={handleChangeSwitch("is_active")}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </div>

        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <div
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          ></div>
          <Link to="/iExpress/EmailTemplate">
              <button
                type="button"
                className="btn btn-secondary wbt"
              >
                Cancel
              </button>
            </Link>
          <button className="btn btn-primary" onClick={() => handleSend()}>Save</button>
        </div>
      </CardBody>
    </Card>
  );
};
export default EditEmailTemplate;