import React, { useState, useEffect, forwardRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import { Link, useHistory } from "react-router-dom";
import { baseURL, campaigns_id_privilage } from "../service/API.js";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination2 from "@material-ui/lab/Pagination";
import { useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import "./page.css";
import cellEditFactory from 'react-bootstrap-table2-editor';
import MaterialTable from "material-table";
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../_metronic/_helpers'
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Paper } from '@material-ui/core';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const Campaign = () => {
  const suhbeader = useSubheader();
  const allData = [];
  const [templateData, setTemplateData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalSize, setTotalSize] = useState(100);
  suhbeader.setTitle("Campaign");
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();


  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [openDetailModalData, setOpenDetailModalData] = useState([]);
  const [id, setId] = useState("");
  const [campaign_id_main, setcampaign_id_main] = useState();

  const [templateData2, setTemplateData2] = useState([]);
  const [dataBackUp, setDataBackUp] = useState([]);
  const [page2, setPage2] = useState(1);
  const [totalPage2, setTotalPage2] = useState(1);
  const [limit2, setLimit2] = useState(10);
  const [totalSize2, setTotalSize2] = useState(100);

  const [isUpload, setIsUpload] = useState(false);
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState();
  const [namefile, setNameFile] = useState();
  const [openDetailModalTable, setOpenDetailModalTable] = useState(false);
  const [openDetailModalTableData, setOpenDetailModalTableData] = useState([]);
  const [type, setType] = useState("new_coming");
  const [type1, setType1] = useState(true);

  const [saved, setSaved] = useState();

  const [page3, setPage3] = useState(1);
  const [totalPage3, setTotalPage3] = useState(1);
  const [limit3, setLimit3] = useState(10);
  const [totalSize3, setTotalSize3] = useState(100);

  const [success, setSuccess] = useState([]);
  const [error, setError] = useState([]);

  const [openDetailModalImport, setOpenDetailModalImport] = useState({});
  const [dataDetail, setDataDetail] = useState({});
  const [lastPage, setLastPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const save = async () => {
    setTimeout(() => {
      setProgress(25);
    }, 1000);
    const formData = new FormData();

    // Update the formData object
    formData.append("file", file, file.name);
    formData.append("campaign_id", campaign_id_main);

    setTimeout(() => {
      setProgress(70);
    }, 1500);

    axios({
      method: "post",
      url: `${baseURL}/campaign/site/import`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
      data: formData,
    })
      .then((res) => {
        const errorData = [];
        const successData = [];

        res.data.data.map((data, index) => {
          data = {
            no: index + 1,
            site_id: data.site_id,
            created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
            id: data.id,
            target_revenue: data.rev_target,
            transactionNo: data.transactionNo,
            status: data.status ? data.status : "",
            errors: data.errors.length > 0 ? data.errors : [],
          };
          if (data.errors.length > 0) {
            errorData.push(data.errors);
          } else {
            successData.push([{ success: 1 }]);
          }
          allData.push(data);
        });
        let lastPage = allData.length / perPage;
        setLastPage(lastPage);
        setError(errorData);
        setSuccess(successData);
        setOpenDetailModalTableData(allData);
        setOpenDetailModalTable(true);
        setOpenDetailModalImport(res.data.import);

      })
      .catch((err) => {
        Swal.fire("Error", "Your can't upload file.", "error");
        cancel()
      });
  };

  const handleChangeFile = async (e) => {
    setFile(null);
    setNameFile(null);
    setIsUpload(true);
    if (e.target.files.length) {
      e.preventDefault();
      let file = e.target.files[0];
      setFile(file);
      setNameFile(file.name);
    }
  };

  const cancel = () => {
    setFile(null);
    setNameFile(null);
    setIsUpload(false);
    setProgress(0);
  }

  const handleCloseCheck = () => {
    setFile(null);
    setNameFile(null);
    setIsUpload(false);
    setProgress(0);
    setTotalPage3(1);
    setTotalSize3(10);
    setLimit3(10);
    setError([]);
    setSuccess([]);
    setOpenDetailModalTableData([]);
    setOpenDetailModalTable(false);
    setOpenDetailModalImport([]);
  }

  const columns_detail = [
    {
      dataField: "no",
      text: "No.",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "site_id",
      text: "Site Id",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "target_revenue",
      text: "Target revenue",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return <span>{row.errors.length > 0 ? "Fail" : "Success"}</span>;
      },
    },
  ];


  const columns_detail2 = [
    {
      dataField: "no",
      text: "No.",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "site_id",
      text: "Site Id",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "target_revenue",
      text: "Target revenue",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return <span>{row.errors.length > 0 ? "Fail" : "Success"}</span>;
      },
    },
  ];

  const handlePageChange3 = (event, value) => {
    setPage3(value);
    // getlist3(value, limit3);
  };

  const handlePageSizeChange3 = (event) => {
    setLimit3(event.target.value);
    // getlist3(1, event.target.value);
  };

  const confirm_detail = () => {
    let event = {
      transactionNo: openDetailModalImport.transactionNo,
    };
    axios({
      method: "post",
      url: `${baseURL}/campaign/site/import/approve`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
      data: event,
    })
      .then((res) => {
        Swal.fire("Upload!", "You save upload success.", "success");
        getViewSite(campaign_id_main, 1, 10)
        cancel()
        handleCloseCheck()
      })
      .catch((err) => {
        cancel()
        handleCloseCheck()
        Swal.fire(
          "Upload!",
          "You save upload fail.",
          "error"
        );
      });
  };

  useEffect(() => {
    getTemplate(page, limit, search);
  }, []);

  const getTemplate = async (page, limit, search) => {
    setPage(page);
    axios({
      method: "get",
      url: `${baseURL}/campaign?page=${page}&limit=${limit}&is_delete=false${search !== undefined && search !== "" ? "&search=" + search : ""
        }`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
    }).then((res) => {
      if (res.message) {
        history.push("/logout");
      } else {
        res.data.data.map((data, index) => {
          data = {
            id: data.id,
            name: data.name,
            description: data.description,
            rev_target: data.rev_target,
            active: data.is_active,
            start_month: data.start_month,
            start_year: data.start_year,
            start: `${data.start_month}/${data.year}`,
            end_month: data.end_month,
            end_year: data.end_year,
            end: `${data.end_month}/${data.year}`,
            year: data.year,
            created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
          };
          allData.push(data);
        });
      }

      var totalpage = Math.ceil(res.data.total / res.data.limit);
      setTotalPage(totalpage);
      setTotalSize(res.data.total);
      setLimit(res.data.limit);
      setTemplateData(allData);
    });
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "start",
      text: "Start",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "end",
      text: "End",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "created_at",
      text: "Create Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "active",
      text: "Active",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Action",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => editItem({ id, row, rowIndex })}
            >
              Edit
            </button>

            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => deleteItem({ row, rowIndex })}
            >
              Delete
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setOpenDetail(id, row)}
            >
              View
            </button>

          </div>
        );
      },
    },
  ];

  const setOpenDetail = (id, row) => {
    setOpenDetailModal(true);
    setOpenDetailModalData(row);
    setId(row.id);
    getViewSite(row.id, 1, 10);
  };

  const handleClose = () => {
    setId("");
    setTemplateData2([]);
    setPage2(1);
    setTotalPage2(1);
    setLimit2(10);
    setTotalSize2(100);

    setOpenDetailModal(false);
    setOpenDetailModalData([]);
  };

  const getViewSite = async (id, page, limit, search2) => {
    const allData2 = [];
    setPage(page);
    axios({
      method: "get",
      url: `${baseURL}/campaign/${id}/sites?page=${page}&limit=${limit}${search2 !== undefined && search2 !== "" ? "&search=" + search2 : ""}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
    }).then((res) => {
      res.data.data.map((data, index) => {
        data = {
          id: data.id,
          siteId: data.site ? data.site.site_id : " ",
          siteIdNumber: data.siteId,
          rev_target: data.rev_target,
          check_edit: false
        };
        allData2.push(data);
      });
      var totalpage = Math.ceil(res.data.total / res.data.limit);
      setcampaign_id_main(id);

      setTotalPage2(totalpage);
      setTotalSize2(res.data.total);
      setLimit2(res.data.limit);
      setTemplateData2(allData2);
      setDataBackUp(allData2);
      localStorage.setItem("bkData", JSON.stringify(allData2))
    });
  };

  const columns2 = [
    { title: "id", field: "id", editable: 'never' },
    { title: "Site Id", field: "siteId", editable: 'never' },
    { title: "Rev Target", field: "rev_target" },
  ]

  // const columns2_BK = [
  //   {
  //     dataField: "id",
  //     text: "ID",
  //     sort: true,
  //     sortCaret: sortCaret,
  //     headerSortingClasses,
  //     editable: false

  //   },
  //   {
  //     dataField: "siteId",
  //     text: "Site id",
  //     sort: true,
  //     sortCaret: sortCaret,
  //     headerSortingClasses,
  //     editable: false

  //   },
  //   {
  //     dataField: "rev_target",
  //     text: "Rev target",
  //     sort: true,
  //     editable: false,
  //     sortCaret: sortCaret,
  //     headerSortingClasses,
  //     events: {
  //       onClick: (e, column, columnIndex, row, rowIndex) => {
  //         // console.log("Edit")
  //       },
  //     },
  //     formatter: (id, row, rowIndex) => {
  //       return (
  //         <div>
  //           {row.check_edit ?
  //             <input
  //               name="rev_target"
  //               data-id={rowIndex}
  //               type="number"
  //               value={row.rev_target}
  //               onChange={handleCostsChange}
  //               style={{
  //                 backgroundColor: "#F4F6F9",
  //                 borderRadius: "5px",
  //                 borderWidth: "0",
  //               }}
  //             />
  //             : row.rev_target}
  //           {row.check_edit ?
  //             <>
  //               <button
  //                 className="btn btn-light btn-sm ml-1"
  //                 type="button"
  //                 onClick={() => saveFields({ id, row, rowIndex })}
  //               >
  //                 <SVG
  //                   src={toAbsoluteUrl('/media/svg/icons/General/Save.svg')} />
  //               </button>
  //               <button
  //                 className="btn btn-danger btn-sm ml-1"
  //                 type="button"
  //                 onClick={() => closeFields({ id, row, rowIndex })}
  //               >
  //                 <SVG
  //                   src={toAbsoluteUrl('/media/svg/icons/Navigation/Close.svg')} />
  //               </button>
  //             </>
  //             :
  //             <button
  //               className="btn btn-light btn-sm ml-1"
  //               type="button"
  //               onClick={() => editFields({ id, row, rowIndex })}
  //             >
  //               <SVG
  //                 src={toAbsoluteUrl('/media/svg/icons/General/Edit.svg')} />
  //             </button>
  //           }
  //         </div>
  //       );
  //     },

  //   },
  // ];

  // const editFields = (data) => {
  //   const _tempCosts = [...templateData2];
  //   _tempCosts[data.rowIndex].check_edit = true;
  //   setTemplateData2(_tempCosts);
  // };

  // const closeFields = (data) => {
  //   const datas = localStorage.getItem("bkData")
  //   const cover_data = datas ? JSON.parse(datas) : []
  //   const _tempCosts = [...templateData2];
  //   _tempCosts[data.rowIndex].check_edit = false;
  //   setTemplateData2(cover_data);
  // };

  // const handleCostsChange = (event) => {
  //   const _tempCosts = [...templateData2];
  //   _tempCosts[event.target.dataset.id][event.target.name] = Number(event.target.value);
  //   setTemplateData2(_tempCosts);
  // };

  const AlertError = (message) => {
    Swal.fire("Site Campaign !", message, "error");
  };

  const saveFields = (data) => {

    if (Number(data.rev_target) < 0) {
      return AlertError("Please insert at rev target more than 0 or 0 !!");
    }

    let data_cover = {
      siteId: Number(data.siteIdNumber),
      campaign_id: Number(campaign_id_main),
      rev_target: Number(data.rev_target)
    }

    axios({
      method: "put",
      url: `${baseURL}/siteCampaign`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
      data: data_cover,
    })
      .then(async (res) => {
        await Swal.fire(
          "Save !",
          "Save site campaign success",
          "success"
        );
        await getViewSite(campaign_id_main, 1, 10)
      })
      .catch((err) => {
        Swal.fire(
          "Created !",
          "Your can not edit site campaign. !! " + err,
          "error"
        );
      });
  };

  const editItem = (data) => {
    history.push("/DHLtoyou/EditCampaign", data);
  };

  const deleteItem = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this template !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${baseURL}/campaign/${data.row.id}`)
          .then((res) => {
            Swal.fire("Deleted!", "Your template has been deleted.", "success");
            getTemplate(1, 10, search);
          })
          .catch((err) => {
            Swal.fire(
              "Deleted!",
              "Your template has been fail to deleted.",
              "error"
            );
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your template is safe :)", "error");
      }
    });
  };
  const [search, setSearch] = useState();
  const handleSearch = () => {
    setPage(1);
    setLimit(10);
    getTemplate(1, 10, search);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    setLimit(10);
    getTemplate(value, 19, search);
  };

  const handlePageSizeChange = (event) => {
    setPage(1);
    setLimit(event.target.value);
    getTemplate(1, event.target.value, search);
  };

  const handlePageChange2 = (event, value) => {
    setPage2(value);
    getViewSite(id, value, limit2);
  };

  const handlePageSizeChange2 = (event) => {
    setPage2(1);
    setLimit2(event.target.value);
    getViewSite(id, 1, event.target.value);
  };
  const [search2, setSearch2] = useState();
  const handleSearch2 = () => {
    setPage2(1);
    setLimit2(10);
    getViewSite(id, 1, 10, search2);
  };


  return (
    <Card>
      <CardHeader title="Campaign">
        <CardHeaderToolbar>
          <Link to="/DHLtoyou/CreateCampaign">
            <button type="button" className="btn btn-primary">
              + Create
            </button>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <BootstrapTable
          keyField="id"
          data={templateData}
          columns={columns}
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
        />
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="mt-3">
                {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={limit}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <Pagination2
                className="mt-3 "
                count={totalPage}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </CardBody>


      <Modal
        open={openDetailModal}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="justify-content-center d-flex align-items-center mt-1">
              <span style={{ fontSize: "30px" }}></span>
            </div>
            <div className="modal-body my_scroll_divs">
              {/* <Form className="col"> */}

              <div className="row mt-3">
                <div>
                  <Link to={`/files/TargetExportTemplateExcel.xlsx`} target="_blank" download>
                    <button className="btn btn-primary mr-1">
                      Export Template Excel
                    </button>
                  </Link>
                </div>
                <div>

                  {isUpload ? (
                    <div className="form-inline">
                      {progress > 0 ? (
                        <button className="btn btn-primary" type="button" disabled>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          {"  "}Loading...
                        </button>
                      ) : (
                        <div class="d-flex justify-content-end">
                          <button className="btn btn-secondary mr-1" onClick={() => cancel()}>
                            Cancel
                          </button>
                          <button className="btn btn-primary" onClick={() => save()}>
                            Upload
                          </button>
                        </div>
                      )}
                    </div>
                  ) : <div className="form-inline">
                    <input
                      name="name"
                      style={{
                        height: "38px",
                        width: "330px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      disabled
                      value={namefile}
                    />

                    <span className="btn btn-primary btn-file wbt">
                      เลือก / แก้ไข
                      <input type="file" onChange={handleChangeFile} />
                    </span>
                  </div>}
                </div>
              </div>

              <div className="row form-inline mb-2 mt-2">
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Text Search"
                      style={{ width: "100%" }}
                      onChange={(e) => setSearch2(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2">
                  <div className="form-group">
                    <button
                      type="button"
                      id="button"
                      className="btn btn-primary"
                      style={{ width: "100%" }}
                      onClick={() => handleSearch2()}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="row"> */}

              <MaterialTable
                columns={columns2}
                data={templateData2}
                icons={tableIcons}
                components={{
                  Container: props => <Paper {...props} elevation={0} />
                }}
                options={{
                  search: false,
                  actionsColumnIndex: -1,
                  paging: false,
                  isLoading: true,
                  toolbar: false
                }}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                      resolve();
                      saveFields(newData)
                    }),
                }}
                onSelectionChange={(rows) => this.getTotal(rows)}
              />

              {/* <BootstrapTable
                  keyField="id"
                  data={templateData2}
                  columns={columns2}
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                  cellEdit={cellEditFactory({ mode: 'click' })}
                /> */}
              <div className=" col-lg-12 mb-2">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mt-3">
                      {"Items per Page: "}
                      <select onChange={handlePageSizeChange2} value={limit2}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Pagination2
                      className="mt-3 "
                      count={totalPage2}
                      page={page2}
                      siblingCount={1}
                      boundaryCount={1}
                      variant="outlined"
                      shape="rounded"
                      onChange={handlePageChange2}
                    />
                  </div>
                </div>
              </div>
              {/* </div> */}

              <div className="modal-footer mt-2">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => handleClose()}
                >
                  Cancel
                </button>
              </div>
              {/* </Form> */}
            </div>
          </div>
        </div>
      </Modal>




      <Modal
        open={openDetailModalTable}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <div className="modal-dialog-full-width modal-dialog momodel modal-fluid">
          <div className="modal-content">
            <div className="justify-content-center d-flex align-items-center mt-1">
              <span style={{ fontSize: "30px" }}></span>
            </div>
            <div className="modal-body my_scroll_divs">
              {/* <Form className="col"> */}
              <BootstrapTable
                keyField="id"
                data={openDetailModalTableData}
                columns={
                  type === "new_coming" ? columns_detail : columns_detail2
                }
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
              />
              <div className=" col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mt-3">
                      {"Items per Page: "}
                      <select onChange={handlePageSizeChange3} value={limit3}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Pagination2
                      className="mt-3 "
                      count={totalPage3}
                      page={page3}
                      siblingCount={1}
                      boundaryCount={1}
                      variant="outlined"
                      shape="rounded"
                      onChange={handlePageChange3}
                    />
                  </div>
                </div>
              </div>

              <div className="card my_scroll_div" style={{ width: "100%" }}>
                <div className="card-body">
                  <h5 className="card-title">Log</h5>

                  {openDetailModalTableData.length > 0 ? (
                    openDetailModalTableData.map((data, index) => {
                      return data.errors ? (
                        data.errors.length > 0 ? (
                          data.errors.map((datas, indexs) => {
                            return (
                              <p className="card-text">
                                Row {index + 1} : {data.site_id} :{" "}
                                {datas.field} : {datas.message}
                              </p>
                            );
                          })
                        ) : (
                          <p className="card-text">
                            Row {index + 1} : {data.site_id} : Success
                          </p>
                        )
                      ) : null;
                    })
                  ) : (
                    <p className="card-text"></p>
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-between mt-2">
                <div className="mt-2">
                  {" "}
                  <span>Success: {success.length}</span>{" "}
                  <span>Error: {error.length}</span>{" "}
                  <span>Total: {openDetailModalTableData.length}</span>
                </div>
                <div
                  style={{
                    color: "#3b4fbc",
                    fontSize: "16px",
                    textAlign: "right",
                  }}
                >
                  {!openDetailModalImport.validateFile ? (
                    <button
                      disabled
                      type="button"
                      className="btn btn-primary"
                    >
                      Confirm
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => confirm_detail()}
                    >
                      Confirm
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => handleCloseCheck()}
                  >
                    Close
                  </button>
                </div>
              </div>
              {/* </Form> */}
            </div>
          </div>
        </div>
      </Modal>
    </Card >
  );
};
