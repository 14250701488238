import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import Swal from 'sweetalert2';
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import { baseURL } from "../service/API.js";
import moment from 'moment';
import paginationFactory from 'react-bootstrap-table2-paginator';

export const CustomerProfile = () => {
  const suhbeader = useSubheader();
  const allData = [];
  const [batchData, setBatchData] = useState([]);
  suhbeader.setTitle("Customer Profile");
  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    fetch(`${baseURL}/customer/listReport?page=1&limit=10000&is_delete=false${search !== undefined && search !== '' ? "&search=" + search : ''}`)
      .then((response) => response.json())
      .then((res) => {
        setBatchData(res.data);
      });
  };

  var columns = [{
    dataField: 'Customer Email',
    text: 'Customer Email',
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  }, {
    dataField: 'Customer Name',
    text: 'Customer Name',
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  }, {
    dataField: 'Customer Mobile',
    text: 'Customer Mobile',
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  }, {
    dataField: 'Current Point',
    text: 'Current Point',
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  }, {
    dataField: 'Total Earn Point',
    text: 'Total Earn Point',
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  }, {
    dataField: 'Total Burn Point',
    text: 'Total Burn Point',
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  }, {
    dataField: 'Status Active',
    text: 'Status Active',
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  }, {
    text: "Unsubscribe",
    dataField: "Unsubscribe Email",
    formatter: (id, row, rowIndex) => {
      return (
        <div>
          <Switch
            checked={row["Unsubscribe Email"] === "Subscribe" ? false : true}
            inputProps={{ "aria-label": "secondary checkbox" }}
            onChange={(e) => editItem({ id, row, rowIndex, e })}
          />
        </div>
      );
    },
  },
  ];
  const editItem = (data) => {
    let isCheck = data.e.target.checked
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this subscribe !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, change it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        axios.put(`${baseURL}/customer/email/${data.row.id}`, { flag_unsubscribe_email: isCheck })
          .then((res) => {
            Swal.fire(
              'Subscribe!',
              'Your subscribe has been change.',
              'success'
            )
            getProfile();
          })
          .catch((err) => {
            Swal.fire(
              'Subscribe!',
              'Your subscribe has been fail to change.',
              'error'
            )
          })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        
      }
    })
  };
  const [search, setSearch] = useState();
  const handleSearch = () => {
    getProfile();
  }
  return (
    <Card>
      <CardHeader title="Customer Profile">
      </CardHeader>
      <CardBody>

        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          pagination={paginationFactory()}
          keyField="id"
          data={batchData === null ? [] : batchData}
          columns={columns}
        >
          <PleaseWaitMessage entities={batchData} />
          <NoRecordsFoundMessage entities={batchData} />
        </BootstrapTable>
      </CardBody>
    </Card>
  );
};
