import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader } from "../../_metronic/_partials/controls";
import { baseURL } from "../service/API.js";
import { useSubheader } from "../../_metronic/layout";
import Swal from "sweetalert2";
import axios from "axios";
import xlsxFile from "read-excel-file";

import { Link } from "react-router-dom";
export const UpdateRewardsStatus = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Update Rewards Status");

  const [state, setState] = useState({
    imageId: null,
    imageName: "",
    imageURL: "",
  });
  const [isUpload, setIsUpload] = useState(false);
  const [progress, setProgress] = useState(0);

  const dhlURL = baseURL.split("/")[2];
  const handleChangeFile = async (e) => {
    setIsUpload(true);
    setProgress(25);
    if (e.target.files.length) {
      e.preventDefault();
      let file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      xlsxFile(file).then((rows) => {
        setProgress(100);
        for (let i = 0; i < rows.length; i++) {
          let type = typeof rows[i][0];

          let da = {
            id: rows[i][0],
            status_en: rows[i][1],
            status_th: rows[i][2],
          };
          if (type === "number") {
            axios
              .put(`${baseURL}/updateStatusExcel/${rows[i][0]}`, da)
              .then((res) => {
                Swal.fire("Updated !", "Your can update status. !! ");
              })
              .catch((err) => {
                Swal.fire(
                  "Updated !",
                  "Your can not update status. !! " + err,
                  "error"
                );
              });
          }
        }
      });
    }
  };

  const save = async () => {
    var events = {
      image: state.imageName,
      link: state.imageURL,
      weight: 2,
    };
  };

  const toFile = async () => {};

  return (
    <Card>
      <CardHeader title="Update Rewards Status"></CardHeader>
      <CardBody>
        <>
          <form>
            <div
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
              }}
            ></div>
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Upload file: <br></br>
              </p>
              <input
                name="ImageURL"
                style={{
                  height: "38px",
                  width: "330px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                disabled
                value={state.imageURL}
              />

              <span className="btn btn-primary btn-file wbt">
                เลือก / แก้ไข
                <input type="file" onChange={handleChangeFile} />
              </span>
            </div>
            {isUpload ? (
              <div>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${progress}%` }}
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {progress}
                  </div>
                </div>
              </div>
            ) : null}
            {/* <div
            className="form-inline"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <button type="button" className="btn btn-primary " onClick={save}>
              Save
            </button>
          </div> */}
          </form>
          <Link to="/files/delivery_status.xlsx" target="_blank" download>
            <button className="btn btn-primary mt-3">
              Export Template Excel
            </button>
          </Link>
        </>
      </CardBody>
    </Card>
  );
};
