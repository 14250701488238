import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { baseURL } from "../../service/API.js";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../_metronic/_helpers";
import paginationFactory from "react-bootstrap-table2-paginator";
import Select from "react-select";
import { Link } from "react-router-dom";

export const EditTargetedGroup = (props) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Edit Targeted Group");
  const history = useHistory();

  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [groupName, setGroupName] = useState("");
  const [groupDesc, setGroupDesc] = useState("");
  const [groupOperator, setGroupOperator] = useState();
  const [conditions, setConditions] = useState();
  const [previews, setPreviews] = useState();
  const [state, setState] = React.useState({
    id: null,
    checked_all_day: false,
    checked_is_active: false,
  });
  const [receiverCountry, setReceiverCountry] = useState();
  const [receiverCountryValue, setReceiverCountryValue] = useState();
  const [region, setRegion] = useState();
  const [regionValue, setRegionValue] = useState();
  const [shipmentType, setShipmentType] = useState();
  const [shipmentTypeValue, setShipmentTypeValue] = useState();
  const [sourceSystem, setSourceSystem] = useState();
  const [sourceSystemValue, setSourceSystemValue] = useState();

  const columns = [
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ];

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      conditionsItem: [
        {
          field_num: "",
          field_condition: "",
          field_value: "",
        },
      ],
    },
  });

  useEffect(() => {
    // console.log(props.location.state.row)
    setGroupName(props.location.state.row.group_name);
    setGroupDesc(props.location.state.row.group_desc);
    if (props.location.state.row.group_operator !== null) {
      setGroupOperator(props.location.state.row.group_operator);
    }
    setDateFrom(props.location.state.row.date_from);
    setDateTo(props.location.state.row.date_to);
    setState({
      id: props.location.state.row.id,
      checked_all_day: props.location.state.row.all_day ? true : false,
      checked_is_active: props.location.state.row.is_active ? true : false,
    });
    getCondition(props.location.state.row.id);
  }, []);

  const AlertError = (message) => {
    Swal.fire("Target Group!", message, "error");
  };

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "conditionsItem",
    }
  );

  const onSubmit = async (data) => {
    var n = data.conditions;
    if (n === undefined) {
      return AlertError("please insert condition value !!");
    }
    var conditionList = n.find((x) => x.field_value !== "");
    if (conditionList === undefined) {
      return AlertError("please insert condition value !!");
    }
    await setConditions(data.conditions);

    if (data.conditions.length !== 1) {
      if (groupOperator === undefined) {
        return AlertError("please select group operator !!");
      }
    }

    if (groupName === undefined || groupName === "") {
      return AlertError("please insert group name !!");
    }
    if (String(groupName).length < 4) {
      return AlertError("Name length must be at least 4 characters long !!");
    }
    if (
      !state.checked_all_day &&
      (dateFrom === undefined || dateTo === undefined)
    ) {
      return AlertError("please select date !!");
    }

    if (!state.checked_all_day) {
      var d_start = moment(dateFrom).format("YYYY-MM-DD");
      var d_end = moment(dateTo).format("YYYY-MM-DD");
      if (d_end < d_start) {
        return AlertError("Please select date to > date from !!");
      }
    }

    if (!state.checked_all_day) {
      var events = {
        group_name: groupName,
        group_desc: groupDesc ? groupDesc : " ",
        group_operator: data.conditions.length === 1 ? "AND" : groupOperator,
        date_from: dateFrom,
        date_to: dateTo,
        all_day: state.checked_all_day,
        is_active: state.checked_is_active,
        updated_by_id: 1,
        conditions: data.conditions,
      };

      events.conditions.forEach((element) => {
        if (element.field_condition === "in") {
          if (
            element.field_num === "16" ||
            element.field_num === "5" ||
            element.field_num === "6" ||
            element.field_num === "11"
          ) {
            var array = [];
            element.field_value.forEach((condition) => {
              array.push(condition.value);
            });
            element.field_value = array;
          } else {
            var array = [];
            var value = element.field_value.split(",");
            value.forEach((condition) => {
              array.push(condition);
            });
            element.field_value = array;
          }
        } else {
          if (
            element.field_num === "16" ||
            element.field_num === "5" ||
            element.field_num === "6" ||
            element.field_num === "11"
          ) {
            var object = "";
            if (Array.isArray(element.field_value)) {
              object = element.field_value[0].value;
            } else {
              object = element.field_value.value;
            }
            element.field_value = object;
          }
        }
      });
    } else {
      var events = {
        group_name: groupName,
        group_desc: groupDesc ? groupDesc : " ",
        group_operator: data.conditions.length === 1 ? "AND" : groupOperator,
        all_day: state.checked_all_day,
        is_active: state.checked_is_active,
        updated_by_id: 1,
        conditions: data.conditions,
      };

      events.conditions.forEach((element) => {
        if (element.field_condition === "in") {
          if (
            element.field_num === "16" ||
            element.field_num === "5" ||
            element.field_num === "6" ||
            element.field_num === "11"
          ) {
            var array = [];
            element.field_value.forEach((condition) => {
              array.push(condition.value);
            });
            element.field_value = array;
          } else {
            var array = [];
            var value = element.field_value.split(",");
            value.forEach((condition) => {
              array.push(condition);
            });
            element.field_value = array;
          }
        } else {
          if (
            element.field_num === "16" ||
            element.field_num === "5" ||
            element.field_num === "6" ||
            element.field_num === "11"
          ) {
            var object = "";
            if (Array.isArray(element.field_value)) {
              object = element.field_value[0].value;
            } else {
              object = element.field_value.value;
            }
            element.field_value = object;
          }
        }
      });
    }

    axios
      .put(`${baseURL}/targetGroup/${state.id}`, events)
      .then((res) => {
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Updated !",
          "Your can not update targetgoup. !! " + err,
          "error"
        );
      });
  };
  const handleChangeSwitch = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  const handlePreview = async (data) => {
    setConditions(data.conditions);
    setPreviews();
    if (data.conditions.length !== 1) {
      if (groupOperator === undefined || groupOperator === "NULL") {
        return AlertError("please select group operator !!");
      }
    }
    var n = data.conditions;
    if (n === undefined) {
      return AlertError("please insert condition value !!");
    }
    var conditionList = n.find((x) => x.field_value !== "");
    if (conditionList === undefined) {
      return AlertError("please insert condition value !!");
    }
    if (state.checked_all_day) {
      var datas = {
        operator: data.conditions.length === 1 ? "AND" : groupOperator,
        conditions: data.conditions,
      };

      datas.conditions.forEach((element) => {
        if (element.field_condition === "in") {
          if (
            element.field_num === "16" ||
            element.field_num === "5" ||
            element.field_num === "6" ||
            element.field_num === "11"
          ) {
            var array = [];
            element.field_value.forEach((condition) => {
              array.push(condition.value);
            });
            element.field_value = array;
          } else {
            var array = [];
            var value = element.field_value.split(",");
            value.forEach((condition) => {
              array.push(condition);
            });
            element.field_value = array;
          }
        } else {
          if (
            element.field_num === "16" ||
            element.field_num === "5" ||
            element.field_num === "6" ||
            element.field_num === "11"
          ) {
            var object = "";
            if (Array.isArray(element.field_value)) {
              object = element.field_value[0].value;
            } else {
              object = element.field_value.value;
            }
            element.field_value = object;
          }
        }
      });
    } else {
      if (dateFrom === undefined || dateTo === undefined) {
        return AlertError("please select date !!");
      }

      var d_start = moment(dateFrom).format("YYYY-MM-DD");
      var d_end = moment(dateTo).format("YYYY-MM-DD");
      if (d_end < d_start) {
        return AlertError("Please select date to > date from !!");
      }

      var datas = {
        operator: data.conditions.length === 1 ? "AND" : groupOperator,
        date_from: dateFrom,
        date_to: dateTo,
        conditions: data.conditions,
      };

      datas.conditions.forEach((element) => {
        if (element.field_condition === "in") {
          if (
            element.field_num === "16" ||
            element.field_num === "5" ||
            element.field_num === "6" ||
            element.field_num === "11"
          ) {
            var array = [];
            element.field_value.forEach((condition) => {
              array.push(condition.value);
            });
            element.field_value = array;
          } else {
            var array = [];
            var value = element.field_value.split(",");
            value.forEach((condition) => {
              array.push(condition);
            });
            element.field_value = array;
          }
        } else {
          if (
            element.field_num === "16" ||
            element.field_num === "5" ||
            element.field_num === "6" ||
            element.field_num === "11"
          ) {
            var object = "";
            if (Array.isArray(element.field_value)) {
              object = element.field_value[0].value;
            } else {
              object = element.field_value.value;
            }
            element.field_value = object;
          }
        }
      });
    }

    axios
      .post(`${baseURL}/targetGroup/preview`, datas)
      .then((res) => {
        if (res.data.length === 0) {
          return Swal.fire(
            "Preview !",
            "Your condition not found. !! ",
            "error"
          );
        }
        setPreviews(res.data);
      })
      .catch((err) => {
        Swal.fire(
          "Preview!",
          "Your can not preview target group. !! " + err,
          "error"
        );
      });
  };

  const getCondition = async (id) => {
    var newReceiverCountr = [];
    await axios
      .get(`${baseURL}/customer/getReceiverCountrycodeList?page=1&limit=10000`)
      .then((res) => {
        newReceiverCountr = [];
        res.data.data.map((item) => {
          newReceiverCountr.push({
            value: item.countrycode,
            label: item.descrption,
          });
        });
        setReceiverCountry(newReceiverCountr);
      })
      .catch((err) => {
        console.log(err);
      });

    var newShipmentType = [];
    await axios
      .get(`${baseURL}/customer/getShipmentTypeList?page=1&limit=10000`)
      .then((res) => {
        newShipmentType = [];
        res.data.data.map((item) => {
          newShipmentType.push({
            value: item.id,
            label: item.name,
          });
        });
        setShipmentType(newShipmentType);
      })
      .catch((err) => {
        console.log(err);
      });

    var newSourceSystem = [];
    await axios
      .get(`${baseURL}/customer/getSourceSystemList?page=1&limit=10000`)
      .then((res) => {
        newSourceSystem = [];
        res.data.data.map((item) => {
          newSourceSystem.push({
            value: item.id,
            label: item.name,
          });
        });
        setSourceSystem(newSourceSystem);
      })
      .catch((err) => {
        console.log(err);
      });
    var newRegion = [];
    await axios
      .get(`${baseURL}/customer/region?page=1&limit=10000`)
      .then((res) => {
        newRegion = [];
        res.data.map((item) => {
          newRegion.push({
            value: item.region,
            label: item.region,
          });
        });
        setRegion(newRegion);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${baseURL}/targetGroup/${id}`)
      .then((res) => {
        var newState = [];
        remove(0);
        res.data.conditions.map((data, index) => {
          if (Number(data.field_num === 5)) {
            var array = [];
            if (Array.isArray(data.field_value)) {
              data.field_value.forEach((element) => {
                array.push(newReceiverCountr.find((x) => x.value === element));
              });
            } else {
              array.push(
                newReceiverCountr.find((x) => x.value === data.field_value)
              );
            }
            setReceiverCountryValue(array);
          }

          if (Number(data.field_num === 6)) {
            var array = [];
            if (Array.isArray(data.field_value)) {
              data.field_value.forEach((element) => {
                array.push(newShipmentType.find((x) => x.value === element));
              });
            } else {
              array.push(
                newShipmentType.find((x) => x.value === data.field_value)
              );
            }
            setShipmentTypeValue(array);
          }

          if (Number(data.field_num === 11)) {
            var array = [];
            if (Array.isArray(data.field_value)) {
              data.field_value.forEach((element) => {
                array.push(newSourceSystem.find((x) => x.value === element));
              });
            } else {
              array.push(
                newSourceSystem.find((x) => x.value === data.field_value)
              );
            }
            setSourceSystemValue(array);
          }

          if (Number(data.field_num === 16)) {
            var array = [];
            if (Array.isArray(data.field_value)) {
              data.field_value.forEach((element) => {
                array.push(newRegion.find((x) => x.value === element));
              });
            } else {
              array.push(newRegion.find((x) => x.value === data.field_value));
            }
            setRegionValue(array);
          }

          insert(index, {
            field_condition: data.field_condition,
            field_num: data.field_num,
            field_value: data.field_value,
          });
          newState.push({
            field_condition: data.field_condition,
            field_num: data.field_num,
            field_value: data.field_value,
          });
        });

        setConditions(newState);
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const handleRemove = (index) => {
    if (fields.length !== 1) {
      remove(index);
    } else {
      Swal.fire(
        "Remove Condition !",
        "Your can not remove condition. !! ",
        "error"
      );
    }
  };

  const watchFieldName = watch();
  const addConditions = () => {
    return (
      <>
        {fields.map((item, index) => {
          var option = [];
          var field_num = "";
          var field_condition = "";
          if (watchFieldName.conditions !== undefined) {
            try {
              field_num = watchFieldName.conditions[index].field_num;
              field_condition =
                watchFieldName.conditions[index].field_condition;
              if (
                field_num === "4" ||
                field_num === "14" ||
                field_num === "15"
              ) {
                option = ["=", "in", "<", ">", "<=", ">="];
              } else {
                option = ["=", "in", "like", "not like"];
              }
            } catch (error) {}
          }
          return (
            <div key={item.id}>
              {index === 0 ? <hr className="mt-1 mb-1"></hr> : null}
              <div className="form-inline" style={{ marginTop: "20px" }}>
                <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                  <span>
                    <b>
                      {"Condition "}
                      {index + 1}
                    </b>
                  </span>
                </p>

                {fields.length > 1 ? (
                  <>
                    <button
                      className="btn btn-success "
                      type="button"
                      onClick={() => {
                        handleRemove(index);
                      }}
                    >
                      Remove Condition
                    </button>
                    <br></br>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="form-inline mt-2 mb-2">
                <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                  Field:{" "}
                </p>
                <select
                  as="select"
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  name={`conditions[${index}].field_num`}
                  ref={register()}
                  defaultValue={item.field_num}
                >
                  <option value={1}>{"Customer Email"}</option>
                  <option value={2}>{"Customer Name"}</option>
                  <option value={3}>{"Customer Phone"}</option>

                  <option value={4}>{"Amount"}</option>
                  <option value={5}>{"Receiver Country"}</option>
                  <option value={6}>{"Shipment Type"}</option>

                  <option value={7}>{"Transaction Date"}</option>

                  <option value={8}>{"Shipper Name"}</option>
                  <option value={9}>{"Shipper Mobile"}</option>
                  <option value={10}>{"Shipper Company Name"}</option>

                  <option value={11}>{"Source System"}</option>
                  <option value={12}>{"Airwaybill"}</option>

                  <option value={14}>{"Sum Amount"}</option>
                  <option value={15}>{"Count Transaction"}</option>
                  <option value={16}>{"Region"}</option>
                </select>
              </div>
              <div
                className="form-inline"
                style={{
                  marginTop: "20px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Condition{" "}
                </p>
                <select
                  as="select"
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  name={`conditions[${index}].field_condition`}
                  ref={register()}
                >
                  {option.map((data, i) => {
                    return (
                      <option
                        selected={item.field_condition === data ? true : false}
                        key={i}
                        value={data}
                      >
                        {data}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div
                className="form-inline"
                style={{
                  marginTop: "20px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Value:{" "}
                </p>
                {field_num === "16" ||
                field_num === "5" ||
                field_num === "6" ||
                field_num === "11" ? (
                  <div
                    style={{
                      height: "auto",
                      width: "530px",
                    }}
                  >
                    {field_num === "5" ? (
                      <Controller
                        as={
                          <Select
                            isMulti={field_condition === "in" ? true : false}
                            options={receiverCountry}
                          />
                        }
                        control={control}
                        name={`conditions[${index}].field_value`}
                        defaultValue={
                          receiverCountryValue !== undefined
                            ? receiverCountryValue
                            : ""
                        }
                      />
                    ) : (
                      ""
                    )}
                    {field_num === "6" ? (
                      <Controller
                        as={
                          <Select
                            isMulti={field_condition === "in" ? true : false}
                            options={shipmentType}
                          />
                        }
                        control={control}
                        name={`conditions[${index}].field_value`}
                        defaultValue={
                          shipmentTypeValue !== undefined
                            ? shipmentTypeValue
                            : ""
                        }
                      />
                    ) : (
                      ""
                    )}
                    {field_num === "11" ? (
                      <Controller
                        as={
                          <Select
                            isMulti={field_condition === "in" ? true : false}
                            options={sourceSystem}
                          />
                        }
                        control={control}
                        name={`conditions[${index}].field_value`}
                        defaultValue={
                          sourceSystemValue !== undefined
                            ? sourceSystemValue
                            : ""
                        }
                      />
                    ) : (
                      ""
                    )}
                    {field_num === "16" ? (
                      <Controller
                        as={
                          <Select
                            isMulti={field_condition === "in" ? true : false}
                            options={region}
                          />
                        }
                        control={control}
                        name={`conditions[${index}].field_value`}
                        defaultValue={
                          regionValue !== undefined ? regionValue : ""
                        }
                      />
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <input
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    name={`conditions[${index}].field_value`}
                    ref={register()}
                    defaultValue={item.field_value}
                  />
                )}
              </div>
              <hr className="mt-2 mb-2"></hr>
            </div>
          );
        })}
        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <div
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
              marginBottom: "80px",
            }}
          ></div>
          <button
            className="btn btn-secondary"
            type="button"
            onClick={handleSubmit(handlePreview)}
          >
            Preview
          </button>
          <button
            className="btn btn-success"
            type="button"
            style={{ marginLeft: "11px" }}
            onClick={() => {
              append();
            }}
          >
            Add Condition
          </button>

          {previews !== undefined ? (
            <>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                keyField="id"
                pagination={paginationFactory()}
                data={previews === undefined ? [] : previews}
                columns={columns}
              >
                <PleaseWaitMessage entities={previews} />
                <NoRecordsFoundMessage entities={previews} />
              </BootstrapTable>
            </>
          ) : null}
        </div>
      </>
    );
  };
  return (
    <>
      <Card>
        <CardHeader title="Edit Targeted Group"></CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Name:{" "}
              </p>
              <input
                name="group_name"
                type="text"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={groupName ? groupName : " "}
                onChange={(gname) => setGroupName(gname.target.value)}
              />
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Description:{" "}
              </p>
              <input
                name="group_desc"
                type="text"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={groupDesc ? groupDesc : " "}
                onChange={(name) => setGroupDesc(name.target.value)}
              />
            </div>

            {fields.length > 1 ? (
              <div className="form-inline" style={{ marginBottom: "25px" }}>
                <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                  Operator:{" "}
                </p>

                <select
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  onChange={(operator) =>
                    setGroupOperator(operator.target.value)
                  }
                  defaultValue={groupOperator}
                >
                  <option disabled value="NULL">
                    Please Select ...
                  </option>
                  <option value={"AND"}>AND</option>
                  <option value={"OR"}>OR</option>
                </select>
              </div>
            ) : (
              ""
            )}

            {addConditions()}

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                {/* Name:{" "} */}
              </p>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.checked_all_day}
                    onChange={handleChangeSwitch("checked_all_day")}
                    name="checked_all_day"
                    color="primary"
                  />
                }
                label="All Customer Transaction"
              />
            </div>

            {!state.checked_all_day ? (
              <>
                <div className="form-inline" style={{ marginBottom: "25px" }}>
                  <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                    Date From:{" "}
                  </p>
                  <TextField
                    id="dateFrom"
                    type="date"
                    style={{
                      height: "38px",
                      width: "185px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={dateFrom}
                    onChange={(date) => setDateFrom(date.target.value)}
                  />
                </div>

                <div className="form-inline" style={{ marginBottom: "25px" }}>
                  <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                    Date To:{" "}
                  </p>
                  <TextField
                    id="dateTo"
                    type="date"
                    value={dateTo}
                    style={{
                      height: "38px",
                      width: "185px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    onChange={(date) => setDateTo(date.target.value)}
                  />
                </div>
              </>
            ) : (
              ""
            )}
            <hr></hr>
            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                Active Target Group:{" "}
              </p>

              <Switch
                checked={state.checked_is_active}
                onChange={handleChangeSwitch("checked_is_active")}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>

            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <div
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              ></div>
              <Link to="/iExpress/TargetedGroup">
              <button
                type="button"
                className="btn btn-secondary wbt"
              >
                Cancel
              </button>
            </Link>
              <button className="btn btn-primary">Save</button>
            </div>
          </form>
        </CardBody>
      </Card>
    </>
  );
};
