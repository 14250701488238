import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { TargetedGroup } from "./pages/TargetedGroup";
import { EmailList } from "./pages/EmailList";
import { CreateTargetedGroup } from "./pages/component/CreateTargetedGroup";
import { EditTargetedGroup } from "./pages/component/EditTargetedGroup";
import { CreateSendEmail } from "./pages/component/CreateSendEmail";
import { EditSendEmail } from "./pages/component/EditSendEmail";
import { PointCal } from "./pages/PointCal";
import { CreatePointCal } from "./pages/component/CreatePointCal";
import { EditPointCal } from "./pages/component/EditPointCal";
import { CustomerProfile } from "./pages/CustomerProfile";
import { Report } from "./pages/Report";
import { Banner } from "./pages/Banner";
import { BannerToYou } from "./pages/BannerToYou";
import { UpdateRewardsStatus } from "./pages/UpdateRewardsStatus";
import { UploadDataNewAccount } from "./pages/UploadDataNewAccount";
import { SendEmail } from "./pages/SendEmail";

import { DashboardPage } from "./pages/DashboardPage";
import { PrivilegeSetting } from "./pages/PrivilegeSetting";
import { EditPrivilegeSetting } from "./pages/component/EditPrivilegeSetting";
import { CreatePrivilegeSetting } from "./pages/component/CreatePrivilegeSetting";
import { UserProfile } from "./pages/UserProfile";
import { CreateUserProfile } from "./pages/component/CreateUserProfile";
import { EditUserProfile } from "./pages/component/EditUserProfile";

import { EmailTemplate } from "./pages/EmailTemplate";
import CreateEmailTemplate from "./pages/component/CreateEmailTemplate";
import EditEmailTemplate from "./pages/component/EditEmailTemplate";

import { EmailTemplateNewAccount } from "./pages/EmailTemplateNewAccount";
import CreateEmailTemplateNewAccount from "./pages/component/CreateEmailTemplateNewAccount";
import EditEmailTemplateNewAccount from "./pages/component/EditEmailTemplateNewAccount";

import { SendEmailHistory } from "./pages/SendEmailHistory";
import { VoucherTemplate } from "./pages/VoucherTemplate";
import { EditVoucherTemplate } from "./pages/component/EditVoucherTemplate";
import { CreateVoucherTemplate } from "./pages/component/CreateVoucherTemplate";

import { CustomerProfileSite } from "./pages/CustomerProfileSite";
import { CreateCustomerProfileSite } from "./pages/component/CreateCustomerProfileSite";
import { EditCustomerProfileSite } from "./pages/component/EditCustomerProfileSite";

import { AccountNo } from "./pages/AccountNo";
import { EditAccountNo } from "./pages/component/EditAccountNo";
import { CreateAccountNo } from "./pages/component/CreateAccountNo";

import { SpecialCampaign } from "./pages/SpecialCampaign";
import { EditSpecialCampaign } from "./pages/component/EditSpecialCampaign";
import { CreateSpecialCampaign } from "./pages/component/CreateSpecialCampaign";

import { Campaign } from "./pages/Campaign";
import { EditCampaign } from "./pages/component/EditCampaign";
import { CreateCampaign } from "./pages/component/CreateCampaign";

import { UploadTransaction } from "./pages/UploadTransaction";

import { PrivilegeSettingToYou } from "./pages/PrivilegeSettingToYou";
import { EditPrivilegeSettingToYou } from "./pages/component/EditPrivilegeSettingToYou";
import { CreatePrivilegeSettingToYou } from "./pages/component/CreatePrivilegeSettingToYou";
import { ViewPrivilegeSettingToYou } from "./pages/component/ViewPrivilegeSettingToYou";

import { YearLimit } from "./pages/YearLimit";
import { EditYearLimit } from "./pages/component/EditYearLimit";
import { CreateYearLimit } from "./pages/component/CreateYearLimit";

import { ReportToYou } from "./pages/ReportToYou";

import { Topic } from "./pages/Topic";
import { CreateTopic } from "./pages/component/CreateTopic";
import { EditTopic } from "./pages/component/EditTopic";

import { DealGroup } from "./pages/DealGroup";
import { CreateDealGroup } from "./pages/component/CreateDealGroup";
import { EditDealGroup } from "./pages/component/EditDealGroup";

import { UpdateRewardsStatusToYou } from "./pages/UpdateRewardsStatusToYou";

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>

        <Redirect exact from="/" to="/dashboard" />
        <Redirect exact from="/iExpress" to="iExpress/TargetedGroup" />
        <Redirect exact from="/DHLtoyou" to="DHLtoyou/UploadDataNewAccount" />

        <ContentRoute path="/Dashboard" component={DashboardPage} />

        <ContentRoute path="/iExpress/TargetedGroup" component={TargetedGroup} />
        <ContentRoute
          path="/iExpress/CreateTargetedGroup"
          component={CreateTargetedGroup}
        />
        <ContentRoute path="/iExpress/EditTargetedGroup" component={EditTargetedGroup} />

        <ContentRoute path="/iExpress/EmailTemplate" component={EmailTemplate} />
        <ContentRoute
          path="/iExpress/CreateEmailTemplate"
          component={CreateEmailTemplate}
        />
        <ContentRoute path="/iExpress/EditEmailTemplate" component={EditEmailTemplate} />

        <ContentRoute path="/iExpress/EmailList" component={EmailList} />
        <ContentRoute path="/iExpress/CreateSendEmail" component={CreateSendEmail} />
        <ContentRoute path="/iExpress/EditSendEmail" component={EditSendEmail} />

        <ContentRoute path="/iExpress/PointCal" component={PointCal} />
        <ContentRoute path="/iExpress/CreatePointCal" component={CreatePointCal} />
        <ContentRoute path="/iExpress/EditPointCal" component={EditPointCal} />

        <ContentRoute path="/iExpress/PrivilegeSetting" component={PrivilegeSetting} />
        <ContentRoute
          path="/iExpress/CreatePrivilegeSetting"
          component={CreatePrivilegeSetting}
        />
        <ContentRoute
          path="/iExpress/EditPrivilegeSetting"
          component={EditPrivilegeSetting}
        />

        <ContentRoute path="/iExpress/UserProfile" component={UserProfile} />
        <ContentRoute path="/iExpress/CreateUserProfile" component={CreateUserProfile} />
        <ContentRoute path="/iExpress/EditUserProfile" component={EditUserProfile} />

        <ContentRoute path="/iExpress/CustomerProfile" component={CustomerProfile} />

        <ContentRoute path="/iExpress/Banner" component={Banner} />

        <ContentRoute
          path="/iExpress/UpdateRewardsStatus"
          component={UpdateRewardsStatus}
        />

        <ContentRoute path="/iExpress/Report" component={Report} />

        <ContentRoute
          path="/DHLtoyou/UploadDataNewAccount"
          component={UploadDataNewAccount}
        />

        <ContentRoute path="/DHLtoyou/Banner" component={BannerToYou} />

        <ContentRoute path="/DHLtoyou/SendEmail" component={SendEmail} />

        <ContentRoute path="/DHLtoyou/SendEmailHistory" component={SendEmailHistory} />

        <ContentRoute
          path="/DHLtoyou/EmailTemplateNewAccount"
          component={EmailTemplateNewAccount}
        />
        <ContentRoute
          path="/DHLtoyou/CreateEmailTemplateNewAccount"
          component={CreateEmailTemplateNewAccount}
        />
        <ContentRoute
          path="/DHLtoyou/EditEmailTemplateNewAccount"
          component={EditEmailTemplateNewAccount}
        />


        <ContentRoute path="/DHLtoyou/VoucherTemplate" component={VoucherTemplate} />
        <ContentRoute
          path="/DHLtoyou/CreateVoucherTemplate"
          component={CreateVoucherTemplate}
        />
        <ContentRoute
          path="/DHLtoyou/EditVoucherTemplate"
          component={EditVoucherTemplate}
        />

        <ContentRoute
          path="/DHLtoyou/CustomerProfileSite"
          component={CustomerProfileSite}
        />
        <ContentRoute
          path="/DHLtoyou/CreateCustomerProfileSite"
          component={CreateCustomerProfileSite}
        />
        <ContentRoute
          path="/DHLtoyou/EditCustomerProfileSite"
          component={EditCustomerProfileSite}
        />

        <ContentRoute
          path="/DHLtoyou/SpecialCampaign"
          component={SpecialCampaign}
        />
        <ContentRoute path="/DHLtoyou/CreateSpecialCampaign" component={CreateSpecialCampaign} />
        <ContentRoute path="/DHLtoyou/EditSpecialCampaign" component={EditSpecialCampaign} />

        <ContentRoute
          path="/DHLtoyou/Campaign"
          component={Campaign}
        />
        <ContentRoute path="/DHLtoyou/CreateCampaign" component={CreateCampaign} />
        <ContentRoute path="/DHLtoyou/EditCampaign" component={EditCampaign} />

        <ContentRoute path="/DHLtoyou/UploadTransaction" component={UploadTransaction} />

        <ContentRoute path="/DHLtoyou/PrivilegeSettingToYou" component={PrivilegeSettingToYou} />
        <ContentRoute path="/DHLtoyou/EditPrivilegeSettingToYou" component={EditPrivilegeSettingToYou} />
        <ContentRoute path="/DHLtoyou/CreatePrivilegeSettingToYou" component={CreatePrivilegeSettingToYou} />
        <ContentRoute path="/DHLtoyou/ViewPrivilegeSettingToYou" component={ViewPrivilegeSettingToYou} />

        <ContentRoute path="/DHLtoyou/Topic" component={Topic} />
        <ContentRoute path="/DHLtoyou/CreateTopic" component={CreateTopic} />
        <ContentRoute path="/DHLtoyou/EditTopic" component={EditTopic} />

        <ContentRoute path="/DHLtoyou/YearLimit" component={YearLimit} />
        <ContentRoute path="/DHLtoyou/EditYearLimit" component={EditYearLimit} />
        <ContentRoute path="/DHLtoyou/CreateYearLimit" component={CreateYearLimit} />

        <ContentRoute path="/DHLtoyou/PrivilegeGroup" component={DealGroup} />
        <ContentRoute path="/DHLtoyou/CreatePrivilegeGroup" component={CreateDealGroup} />
        <ContentRoute path="/DHLtoyou/EditPrivilegeGroup" component={EditDealGroup} />

        <ContentRoute path="/DHLtoyou/Report" component={ReportToYou} />

        <ContentRoute path="/DHLtoyou/AccountNo" component={AccountNo} />
        <ContentRoute
          path="/DHLtoyou/CreateAccountNo"
          component={CreateAccountNo}
        />
        <ContentRoute
          path="/DHLtoyou/EditAccountNo"
          component={EditAccountNo}
        />

        <ContentRoute path="/DHLtoyou/UpdateRewardsStatus" component={UpdateRewardsStatusToYou} />

        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
