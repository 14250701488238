import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { imageURL, privilegeURL, campaign_id } from "../../service/API.js";
import axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import JoditEditor from './JoditEditorPrivilage'

import "./component.css";
import Swal from "sweetalert2";
import CropperImage from "./CropperImage";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "530px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, category, theme) {
  return {
    fontWeight:
      category.findIndex((t) => t.tag_name === name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const CreatePrivilegeSetting = (props) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Privilege Create");
  const history = useHistory();

  const [dataDeal, setDataDeal] = useState({});
  const [dealTags, setDealTags] = useState([]);

  const classes = useStyles();
  const theme = useTheme();
  const [categoryData, setCategoryData] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryTemp, setCategoryTemp] = useState([]);
  const [privilegeNameTH, setPrivilegeNameTH] = useState("");
  const [privilegeName, setPrivilegeName] = useState("");
  const [privilegeQuota, setPrivilegeQuota] = useState("");
  const [privilegePoint, setPrivilegePoint] = useState("");
  const [quotaMember, setQuotaMember] = useState("");
  const [privilegeDetail, setPrivilegeDetail] = useState("");
  const [privilegeDetailTH, setPrivilegeDetailTH] = useState("");
  const [privilegeImage, setPrivilegeImage] = useState("");
  const [nameImage, setNameImage] = useState("");
  const [active, setActive] = useState(true);
  const [isDelivery, setIsDelivery] = useState(true);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [state, setState] = useState({
    preview: null,
    raw: null,
    imageURL: null,
  });

  const handleChange = (event) => {
    setCategory(event.target.value);
  };
  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    fetch(`${privilegeURL}/tag-topic?campaign_id=1`)
      .then((response) => response.json())
      .then(async (res) => {
        var newState = [];
        res.data.map((data) => {
          newState.push(...data.tags);
        });
        const newColumns = newState.map((item) => {
          const { id: tag_id, ...rest } = item;
          return { tag_id, ...rest };
        });
        setCategoryData(newColumns);
      });
  };

  const handleChangeImage = async (e) => {
    if (e.target.files.length) {
      e.preventDefault();
      let file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (height < 512 || width < 512) {
            Swal.fire(
              "Upload !",
              "At least you can upload a 512*512 photo size. !! ",
              "error"
            );
            e.target.value = null;
            return false;
          } else {
            setState({
              raw: file,
              imageURL: e.target.result,
            });
            e.target.value = null;
            return true;
          }
        };
      };
    }
  };

  const handleImageUpload = async (file) => {
    var formdata = new FormData();
    formdata.append("image", file, URL.createObjectURL(file));
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${privilegeURL}/upload/`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        setState({
          preview: null,
          raw: null,
          imageURL: null,
        });
        setPrivilegeImage(`${imageURL}/upload/${res.filename}`);
        setNameImage(res.filename);
      })
      .catch((error) => console.log("error", error));
  };
  const handleCancelUpload = () => {
    setState({
      preview: null,
      raw: null,
      imageURL: null,
    });
  };
  const removeImage = async () => {
    setState({
      preview: null,
      raw: null,
      imageURL: null,
    });
    setPrivilegeImage("");
  };

  const save = async () => {
    let n = categoryData;
    var index = n.findIndex((x) => Number(x.tag_id) === Number(category));
    let array = new Array(categoryData[index]);

    if (
      category &&
      privilegeName &&
      privilegeDetail &&
      nameImage &&
      privilegeQuota &&
      privilegePoint &&
      startDate &&
      endDate &&
      array.length > 0
    ) {
      var events = {
        deal_tags: array,
        deal_name_th: privilegeNameTH,
        deal_name: privilegeName,
        deal_detail_short_th: privilegeDetailTH,
        deal_detail_short: privilegeDetail,
        deal_image: nameImage,
        deal_active: active,
        created_by: "admin",
        created_by_id: 1,
        campaign_id: campaign_id,
        quota_campaign: privilegeQuota,
        point: privilegePoint,
        quota_member:
          quotaMember === undefined || quotaMember === "" || quotaMember === "0"
            ? null
            : quotaMember,
        is_delivery: isDelivery,
        start_date: startDate,
        end_date: endDate,
      };

      axios
        .post(`${privilegeURL}/dhl/deal`, events)
        .then((res) => {
          history.goBack();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Swal.fire(
        "Created !",
        "Please insert pvivilege information. !! ",
        "error"
      );
    }
  };

  const handleMessageChangeTH = (message) => {
    setPrivilegeDetailTH(message)
  }

  const handleMessageChangeEN = (message) => {
    setPrivilegeDetail(message)
  }

  return (
    <>
      <Card>
        <CardHeader title="Privilege Create"></CardHeader>
        <CardBody>
          <form>
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Category:
              </p>

              <FormControl className={classes.formControl}>
                <Select
                  native
                  value={category}
                  onChange={handleChange}
                  inputProps={{
                    name: "age",
                    id: "age-native-simple",
                  }}
                >
                  <option value={" "}>Please select ...</option>
                  {categoryData.length > 0
                    ? categoryData.map((data, index) => {
                      return (
                        <option key={index} value={data.tag_id}>
                          {" "}
                          {data.tag_name}
                        </option>
                      );
                    })
                    : null}
                </Select>
              </FormControl>
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Privilege Name (TH):{" "}
              </p>
              <input
                name="privilegeName"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={privilegeNameTH}
                onChange={(name) => setPrivilegeNameTH(name.target.value)}
              />
            </div>
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Privilege Name (EN):{" "}
              </p>
              <input
                name="privilegeName"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={privilegeName}
                onChange={(name) => setPrivilegeName(name.target.value)}
              />
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Privilege Detail (TH):{" "}
              </p>
              <div>
                <JoditEditor messageChange={handleMessageChangeTH} />

              </div>
              {/* <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                style={{
                  height: "108px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={privilegeDetailTH}
                onChange={(name) => setPrivilegeDetailTH(name.target.value)}
              ></textarea> */}
            </div>
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Privilege Detail (EN):{" "}
              </p>
              <div>
                <JoditEditor messageChange={handleMessageChangeEN} />

              </div>
              {/* <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                style={{
                  height: "108px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={privilegeDetail}
                onChange={(name) => setPrivilegeDetail(name.target.value)}
              ></textarea> */}
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Start Date:{" "}
              </p>
              <TextField
                id="datetime-locals"
                type="datetime-local"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                value={startDate}
                onChange={(name) => setStartDate(name.target.value)}
              />
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                End Date:{" "}
              </p>
              <TextField
                id="datetime-local"
                type="datetime-local"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                value={endDate}
                onChange={(name) => setEndDate(name.target.value)}
              />
            </div>

            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                Active:{" "}
              </p>
              <Switch
                checked={active}
                value="active"
                inputProps={{ "aria-label": "secondary checkbox" }}
                onChange={(event) => {
                  setActive(event.target.checked);
                }}
              />
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Privilege Image : <br></br>
                (512*512)
              </p>
              <input
                name="group_desc"
                style={{
                  height: "38px",
                  width: "330px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                disabled
                value={privilegeImage}
              />

              <span className="btn btn-primary btn-file wbt">
                เลือก / แก้ไข
                <input type="file" onChange={handleChangeImage} />
              </span>

              <button
                type="button"
                className="btn btn-danger wbt"
                onClick={removeImage}
              >
                ลบ
              </button>
            </div>

            <div
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "50%",
              }}
            >
              {state.preview ? (
                <img
                  style={{
                    width: "300px",
                    height: "300px",
                  }}
                  src={state.preview}
                />
              ) : privilegeImage ? (
                <img
                  style={{
                    width: "300px",
                    height: "300px",
                  }}
                  src={privilegeImage}
                />
              ) : null}
            </div>
            {state.imageURL ? (
              <CropperImage
                image={state.imageURL}
                handleCancel={handleCancelUpload}
                handleUpload={handleImageUpload}
                scale={1}
              />
            ) : (
              ""
            )}
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Privilege Quota:{" "}
              </p>
              <input
                type="number"
                name="privilegeQuota"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={privilegeQuota}
                onChange={(Quota) => setPrivilegeQuota(Quota.target.value)}
              />
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Customer Quota:{" "}
              </p>
              <input
                type="number"
                name="quotaMember"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={quotaMember}
                onChange={(quotaMember) =>
                  setQuotaMember(quotaMember.target.value)
                }
              />
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Privilege Point:{" "}
              </p>
              <input
                type="number"
                name="privilegePoint"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={privilegePoint}
                onChange={(Point) => setPrivilegePoint(Point.target.value)}
              />
            </div>

            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                Delivery:{" "}
              </p>
              <Switch
                checked={isDelivery}
                value="isDelivery"
                inputProps={{ "aria-label": "secondary checkbox" }}
                onChange={(event) => {
                  setIsDelivery(event.target.checked);
                }}
              />
            </div>
          </form>
          <CardHeaderToolbar>
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/iExpress/PrivilegeSetting">
              <button
                type="button"
                className="btn btn-secondary wbt"
                onClick={removeImage}
              >
                Cancel
              </button>
            </Link>
            <button
              type="button"
              className="btn btn-primary wbt"
              onClick={save}
            >
              Save
            </button>
          </CardHeaderToolbar>
        </CardBody>
      </Card>
    </>
  );
};
