import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { imageURL, privilegeURL, campaign_id } from "../../service/API.js";
import axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import "./component.css";
import CropperImage from "./CropperImage";
import JoditEditorPrivilage from './JoditEditorPrivilage'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "530px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const EditPrivilegeSetting = (props) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Privilege Edit");
  const history = useHistory();

  const [dataDeal, setDataDeal] = useState({});
  const [dealTags, setDealTags] = useState([]);

  const classes = useStyles();
  const theme = useTheme();
  const [categoryData, setCategoryData] = useState([]);
  const [category, setCategory] = useState([]);
  const [privilegeNameTH, setPrivilegeNameTH] = useState("");
  const [privilegeName, setPrivilegeName] = useState("");
  const [privilegeQuota, setPrivilegeQuota] = useState("");
  const [privilegePoint, setPrivilegePoint] = useState("");
  const [quotaMember, setQuotaMember] = useState("");
  const [privilegeDetail, setPrivilegeDetail] = useState();
  const [privilegeDetailTH, setPrivilegeDetailTH] = useState();
  const [privilegeImage, setPrivilegeImage] = useState("");
  const [active, setActive] = useState(true);
  const [isDelivery, setIsDelivery] = useState(true);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [nameImage, setNameImage] = useState("");

  const [state, setState] = useState({
    preview: null,
    raw: null,
    imageURL: null,
  });
  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  useEffect(() => {
    setDataDeal(props.location.state.row);
    setPrivilegeNameTH(props.location.state.row.deal_name_th);
    setPrivilegeName(props.location.state.row.deal_name);
    setPrivilegeDetailTH(props.location.state.row.deal_detail_short_th);
    setPrivilegeDetail(props.location.state.row.deal_detail_short);
    setPrivilegeImage(props.location.state.row.deal_image);
    setActive(props.location.state.row.deal_active);
    setPrivilegePoint(props.location.state.row.point);
    setIsDelivery(props.location.state.row.is_delivery);
    getCategory();
    getPrivilege();
  }, []);

  const getPrivilege = async () => {
    props.location.state.row.dealRules.map((data, index) => {
      if (data.type === "quota_campaign") {
        setPrivilegeQuota(data.period_limit);
      } else if (data.type === "quota_member") {
        setQuotaMember(data.period_limit);
      } else if (data.type === "display") {
        let start = moment(data.start_date).format("YYYY-MM-DDTHH:mm");
        let end = moment(data.end_date).format("YYYY-MM-DDTHH:mm");

        setStartDate(start);
        setEndDate(end);
      }
    });
    props.location.state.row.deal_image
      ? setNameImage(
        props.location.state.row.deal_image.replace(
          "https://privilege-api.dev.fysvc.com/upload/",
          ""
        )
      )
      : setNameImage("");
  };

  const getCategory = async () => {
    fetch(`${privilegeURL}/tag-topic?campaign_id=1`)
      .then((response) => response.json())
      .then(async (res) => {
        var newState = [];
        res.data.map((data) => {
          newState.push(...data.tags);
        });

        let data = [];
        if (props.location.state.row.dealTags.length > 0) {
          props.location.state.row.dealTags.map((item) => {
            newState.map((items) => {
              if (items.id === item.tag_id) {
                data.push({
                  tag_id: items.id,
                  tag_name: items.tag_name,
                  tag_weight: items.tag_weight,
                });

                setCategory(item.tag_id);
                setDealTags(data);
              }
            });
          });
        } else {
          setCategory([]);
          setDealTags([]);
        }
        const newColumns = newState.map((item) => {
          const { id: tag_id, ...rest } = item;
          return { tag_id, ...rest };
        });
        setCategoryData(newColumns);
      });
  };

  const handleChangeImage = async (e) => {
    if (e.target.files.length) {
      e.preventDefault();
      let file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (height < 512 || width < 512) {
            Swal.fire(
              "Upload !",
              "At least you can upload a 512*512 photo size. !! ",
              "error"
            );
            e.target.value = null;
            return false;
          } else {
            setState({
              raw: file,
              imageURL: e.target.result,
            });
            e.target.value = null;
            return true;
          }
        };
      };
    }
  };

  const handleImageUpload = async (file) => {
    var formdata = new FormData();
    formdata.append("image", file);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${privilegeURL}/upload/`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        setState({
          preview: null,
          raw: null,
          imageURL: null,
        });
        setPrivilegeImage(`${imageURL}/upload/${res.filename}`);
        setNameImage(res.filename);
      })
      .catch((error) => console.log("error", error));
  };
  const handleCancelUpload = () => {
    setState({
      preview: null,
      raw: null,
      imageURL: null,
    });
  };
  const removeImage = async () => {
    setState({
      preview: null,
      raw: null,
      imageURL: null,
    });
    setPrivilegeImage("");
  };
  const save = async () => {
    let n = categoryData;
    var index = n.findIndex((x) => Number(x.tag_id) === Number(category));
    let array = new Array(categoryData[index]);
    if (
      category &&
      privilegeName &&
      privilegeDetail &&
      nameImage &&
      privilegeQuota &&
      privilegePoint &&
      startDate &&
      endDate &&
      array.length > 0
    ) {
      var events = {
        deal_tags: array,
        deal_name_th: privilegeNameTH,
        deal_name: privilegeName,
        deal_detail_short_th: privilegeDetailTH,
        deal_detail_short: privilegeDetail,
        deal_image: nameImage,
        deal_active: active,
        updated_by: "admin",
        updated_by_id: 1,
        campaign_id: campaign_id,
        quota_campaign: privilegeQuota,
        point: privilegePoint,
        quota_member:
          quotaMember === undefined || quotaMember === "" || quotaMember === "0"
            ? null
            : quotaMember,
        is_delivery: isDelivery,
        start_date: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
      };

      axios
        .put(`${privilegeURL}/dhl/deal/${props.location.state.id}`, events)
        .then((res) => {
          history.goBack();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Swal.fire(
        "Updated !",
        "Please insert pvivilege information. !! ",
        "error"
      );
    }
  };

  const handleMessageChangeTH = (message) => {
    setPrivilegeDetailTH(message)
  }

  const handleMessageChangeEN = (message) => {
    setPrivilegeDetail(message)
  }

  return (
    <>
      <Card>
        <CardHeader title="Privilege Edit"></CardHeader>
        <CardBody>
          <form>
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Category:
              </p>

              <FormControl className={classes.formControl}>
                <Select
                  native
                  value={category}
                  onChange={handleChange}
                  inputProps={{
                    name: "age",
                    id: "age-native-simple",
                  }}
                >
                  <option value={" "}>Please select ...</option>

                  {categoryData.length > 0
                    ? categoryData.map((data, index) => {
                      return (
                        <option key={index} value={data.tag_id}>
                          {" "}
                          {data.tag_name}
                        </option>
                      );
                    })
                    : null}
                </Select>
              </FormControl>
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Privilege Name (TH):{" "}
              </p>
              <input
                name="privilegeName"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={privilegeNameTH}
                onChange={(name) => setPrivilegeNameTH(name.target.value)}
              />
            </div>
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Privilege Name (EN):{" "}
              </p>
              <input
                name="privilegeName"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={privilegeName}
                onChange={(name) => setPrivilegeName(name.target.value)}
              />
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Privilege Detail (TH):{" "}
              </p>

              {privilegeDetailTH !== undefined ?
                <JoditEditorPrivilage
                  messageChange={handleMessageChangeTH}
                  messageValue={privilegeDetailTH} />
                : null}

            </div>
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Privilege Detail (EN):{" "}
              </p>

              {privilegeDetail !== undefined ?
                <JoditEditorPrivilage
                  messageChange={handleMessageChangeEN}
                  messageValue={privilegeDetail} />
                : null}

            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Start Date:{" "}
              </p>
              <TextField
                id="datetime-locals"
                type="datetime-local"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                value={startDate}
                onChange={(name) => setStartDate(name.target.value)}
              />
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                End Date:{" "}
              </p>
              <TextField
                id="datetime-local"
                type="datetime-local"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                value={endDate}
                onChange={(name) => setEndDate(name.target.value)}
              />
            </div>

            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                Active:{" "}
              </p>
              <Switch
                checked={active}
                value="active"
                inputProps={{ "aria-label": "secondary checkbox" }}
                onChange={(event) => {
                  setActive(event.target.checked);
                }}
              />
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Privilege Image : <br></br>
                (512*512)
              </p>

              <input
                name="group_desc"
                style={{
                  height: "38px",
                  width: "330px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                disabled
                value={privilegeImage}
                onChange={(name) => setPrivilegeImage(name.target.value)}
              />

              <span className="btn btn-primary btn-file wbt">
                เลือก / แก้ไข
                <input type="file" onChange={handleChangeImage} />
              </span>

              <button
                type="button"
                className="btn btn-danger wbt"
                onClick={removeImage}
              >
                ลบ
              </button>
            </div>

            <div
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "50%",
              }}
            >
              {state.preview ? (
                <img
                  style={{
                    width: "300px",
                    height: "300px",
                  }}
                  src={state.preview}
                />
              ) : privilegeImage ? (
                <img
                  style={{
                    width: "300px",
                    height: "300px",
                  }}
                  src={privilegeImage}
                />
              ) : null}
            </div>
            {state.imageURL ? (
              <CropperImage
                image={state.imageURL}
                handleCancel={handleCancelUpload}
                handleUpload={handleImageUpload}
                scale={1}
              />
            ) : (
              ""
            )}
            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Privilege Quota:{" "}
              </p>
              <input
                type="number"
                name="privilegeQuota"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={privilegeQuota}
                onChange={(Quota) => setPrivilegeQuota(Quota.target.value)}
              />
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Customer Quota:{" "}
              </p>
              <input
                type="number"
                name="quotaMember"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={quotaMember}
                onChange={(quotaMember) =>
                  setQuotaMember(quotaMember.target.value)
                }
              />
            </div>

            <div className="form-inline" style={{ marginBottom: "25px" }}>
              <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                Privilege Point:{" "}
              </p>
              <input
                type="number"
                name="privilegePoint"
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={privilegePoint}
                onChange={(Point) => setPrivilegePoint(Point.target.value)}
              />
            </div>

            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                Delivery:{" "}
              </p>
              <Switch
                checked={isDelivery}
                value="isDelivery"
                inputProps={{ "aria-label": "secondary checkbox" }}
                onChange={(event) => {
                  setIsDelivery(event.target.checked);
                }}
              />
            </div>
          </form>
          <CardHeaderToolbar>
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/iExpress/PrivilegeSetting">
              <button
                type="button"
                className="btn btn-secondary wbt"
                onClick={removeImage}
              >
                Cancel
              </button>
            </Link>
            <button
              type="button"
              className="btn btn-primary wbt"
              onClick={save}
            >
              Save
            </button>
          </CardHeaderToolbar>
        </CardBody>
      </Card>
    </>
  );
};
