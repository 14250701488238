import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import axios from "axios";
import { privilegeURL, privilegeURLV2, imageURL, campaigns_id_privilage } from "../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import JoditEditor from "./JoditEditor";
import { Link } from "react-router-dom";
import "./component.css";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import CropperImage from './CropperImage'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Modal from "@material-ui/core/Modal";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import { useSelector } from "react-redux";

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import Chip from "@material-ui/core/Chip";
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
}));


export const ViewPrivilegeSettingToYou = (props) => {
  const suhbeader = useSubheader();
  const { user, authToken, accessTokenPrivilege } = useSelector((state) => state.auth);
  const classes = useStyles();
  suhbeader.setTitle("View Privilege");
  const history = useHistory();

  const [name, setName] = useState();
  const [name_th, setNameTh] = useState();

  const [detail, setDetail] = useState();
  const [detail_th, setDetailTh] = useState();
  const [state, setState] = useState({
    is_active: false,
    is_delivery: false,
    is_lucky: false,
  });

  const [stateItem_pic_path, setStateItem_pic_path] = useState({
    previewItem_pic_path: null,
    rawItem_pic_path: null,
    picURLItem_pic_path: null
  })
  const [namepicItem_pic_path, setNamepicItem_pic_path] = useState()
  const [item_pic_path, setItem_pic_path] = useState()
  const [stateItem_logo_path, setStateItem_logo_path] = useState({
    previewItem_logo_path: null,
    rawItem_logo_path: null,
    logoURLItem_logo_path: null
  })
  const [namelogoItem_logo_path, setNamelogoItem_logo_path] = useState()
  const [item_logo_path, setItem_logo_path] = useState()

  const [costs, setCosts] = useState([{ amount: 0, qty: 0, expire: 0 }]);
  const [tagData, setTagData] = useState([]);
  const [tagItem, setTagItem] = useState([]);

  const [point, setPoint] = useState();
  const [expirelyMinute, setExpirelyMinute] = useState();


  // const [prevActiveStep, setPrevActiveStep] = useState(0);

  const [openDialogModalTable, setOpenDialogModalTable] = useState(false);
  const [openDialogModalReTable, setOpenDialogModalReTable] = useState(false);

  const [startDateRedeem_check, setStartDateRedeem_check] = useState();
  const [startDateDisplay_check, setStartDateDisplay_check] = useState();
  const [startDateDisplay, setStartDateDisplay] = useState();
  const [endDateDisplay, setEndDateDisplay] = useState();
  const [startDateRedeem, setStartDateRedeem] = useState();
  const [endDateRedeem, setEndDateRedeem] = useState();

  const [dialogTable, setDialogTable] = useState([]);
  const [dialogTableRe, setDialogTableRe] = useState([]);

  const [id_res, setid_res] = useState(0);
  const [id_res_two, setid_res_two] = useState(0);
  const [length, setlength] = useState(0);
  const [amount, setamount] = useState(0);
  const [symbols, setsymbols] = useState(false);
  const [uppercase, setuppercase] = useState(false);
  const [numbers, setnumbers] = useState(false);
  const [lowercase, setlowercase] = useState(false);


  //mon
  const [mons, setMon] = useState([{ day_of_week: 1, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedMon, setCheckedMon] = useState(false);
  const handleChangeCheckMon = (event) => {
    setCheckedError(false)
    setCheckedMon(!checkedMon);
  };
  //tue
  const [tues, setTue] = useState([{ day_of_week: 2, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedTue, setCheckedTue] = useState(false);
  const handleChangeCheckTue = (event) => {
    setCheckedError(false)
    setCheckedTue(!checkedTue);
  };
  //wed
  const [weds, setWed] = useState([{ day_of_week: 3, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedWed, setCheckedWed] = useState(false);
  const handleChangeCheckWed = (event) => {
    setCheckedError(false)
    setCheckedWed(!checkedWed);
  };
  //Thu
  const [thus, setThu] = useState([{ day_of_week: 4, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedThu, setCheckedThu] = useState(false);
  const handleChangeCheckThu = (event) => {
    setCheckedError(false)
    setCheckedThu(!checkedThu);
  };
  //Fri
  const [fris, setFri] = useState([{ day_of_week: 5, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedFri, setCheckedFri] = useState(false);
  const handleChangeCheckFri = (event) => {
    setCheckedError(false)
    setCheckedFri(!checkedFri);
  };
  //Sat
  const [sats, setSat] = useState([{ day_of_week: 6, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedSat, setCheckedSat] = useState(false);
  const handleChangeCheckSat = (event) => {
    setCheckedError(false)
    setCheckedSat(!checkedSat);
  };
  //Sun
  const [suns, setSun] = useState([{ day_of_week: 7, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedSun, setCheckedSun] = useState(false);
  const handleChangeCheckSun = (event) => {
    setCheckedError(false)
    setCheckedSun(!checkedSun);
  };
  const [checkedError, setCheckedError] = useState(false);


  //monRe
  const [monRes, setMonRe] = useState([{ day_of_week: 1, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedMonRe, setCheckedMonRe] = useState(false);
  const handleChangeCheckMonRe = (event) => {
    setCheckedErrorRe(false)
    setCheckedMonRe(!checkedMonRe);
  };
  //tueRe
  const [tueRes, setTueRe] = useState([{ day_of_week: 2, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedTueRe, setCheckedTueRe] = useState(false);
  const handleChangeCheckTueRe = (event) => {
    setCheckedErrorRe(false)
    setCheckedTueRe(!checkedTueRe);
  };
  //wedRe
  const [wedRes, setWedRe] = useState([{ day_of_week: 3, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedWedRe, setCheckedWedRe] = useState(false);
  const handleChangeCheckWedRe = (event) => {
    setCheckedErrorRe(false)
    setCheckedWedRe(!checkedWedRe);
  };
  //ThuRe
  const [thuRes, setThuRe] = useState([{ day_of_week: 4, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedThuRe, setCheckedThuRe] = useState(false);
  const handleChangeCheckThuRe = (event) => {
    setCheckedErrorRe(false)
    setCheckedThuRe(!checkedThuRe);
  };
  //FriRe
  const [friRes, setFriRe] = useState([{ day_of_week: 5, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedFriRe, setCheckedFriRe] = useState(false);
  const handleChangeCheckFriRe = (event) => {
    setCheckedErrorRe(false)
    setCheckedFriRe(!checkedFriRe);
  };
  //SatRe
  const [satRes, setSatRe] = useState([{ day_of_week: 6, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedSatRe, setCheckedSatRe] = useState(false);
  const handleChangeCheckSatRe = (event) => {
    setCheckedSatRe(!checkedSatRe);
  };
  //SunRe
  const [sunRes, setSunRe] = useState([{ day_of_week: 7, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedSunRe, setCheckedSunRe] = useState(false);
  const handleChangeCheckSunRe = (event) => {
    setCheckedErrorRe(false)
    setCheckedSunRe(!checkedSunRe);
  };
  const [checkedErrorRe, setCheckedErrorRe] = useState(false);


  const [file, setFile] = useState();
  const [namefile, setNameFile] = useState();
  const [isUpload, setIsUpload] = useState(false);
  const [progress, setProgress] = useState(0);

  // const [id_q, setid_q] = useState(0);
  const [start_date_q, setstart_date_q] = useState();
  const [end_date_q, setend_date_q] = useState();
  const [period_limit_q, setperiod_limit_q] = useState();
  const [year_limit_q, setyear_limit_q] = useState();
  const [month_limit_q, setmonth_limit_q] = useState();
  const [week_limit_q, setweek_limit_q] = useState();
  const [day_limit_q, setday_limit_q] = useState();

  const [campaignData, setCampaignData] = useState([]);

  // const [id_qm, setid_qm] = useState(0);
  const [start_date_qm, setstart_date_qm] = useState();
  const [end_date_qm, setend_date_qm] = useState();
  const [period_limit_qm, setperiod_limit_qm] = useState();
  const [year_limit_qm, setyear_limit_qm] = useState();
  const [month_limit_qm, setmonth_limit_qm] = useState();
  const [week_limit_qm, setweek_limit_qm] = useState();
  const [day_limit_qm, setday_limit_qm] = useState();
  const [memberData, setMemberData] = useState([]);
  const [campaigns_id_headder, setCampaigns_id_headder] = useState();
  const [deal_detail_condition, setdeal_detail_condition] = useState();
  const [deal_detail_promote, setdeal_detail_promote] = useState();


  const handleConditionChange = (message) => {
    setdeal_detail_condition(message)
  }

  const [users, setUsers] = useState(null);
  const [token, setToken] = useState(null);
  const AlertError = (message) => {
    Swal.fire("Privilege!", message, "error");
  };
  const [tagItemdDefault, setTagItemdDefault] = useState([]);

  useEffect(() => {

    localStorage.setItem("quotaCampaignData", JSON.stringify([]))
    localStorage.setItem("quotaMemberData", JSON.stringify([]))
    setCampaigns_id_headder(campaigns_id_privilage);
    getTag(campaigns_id_privilage);
    setPoint(props.location.state.point)
    setdeal_detail_condition(props.location.state.deal_detail_condition)
    setid_res(props.location.state.id)
    getRule(props.location.state.id)
    stateItem_pic_path.previewItem_pic_path = props.location.state.deal_image
    stateItem_logo_path.previewItem_logo_path = props.location.state.deal_logo
    setName(props.location.state.deal_name)
    setNameTh(props.location.state.deal_name_th)
    setDetail(props.location.state.deal_detail_short)
    setDetailTh(props.location.state.deal_detail_short_th)
    state.is_active = props.location.state.deal_active
    state.is_delivery = props.location.state.is_delivery
    state.is_lucky = props.location.state.is_lucky
    const pic = props.location.state.deal_image.split("/")
    setItem_pic_path(`${pic[4]}`)
    setNamepicItem_pic_path(`${props.location.state.deal_image ? props.location.state.deal_image : " "}`)

    if (props.location.state.dealTags.length > 0) {
      const dealTags_data = []
      const dealTags_data2 = []
      props.location.state.dealTags.map((datas) => {
        dealTags_data.push({ tag_name: datas.tag.tag_name, tag_id: datas.tag.id, topic: datas.tag.topic.id, topic_name: datas.tag.topic.tag_topic_name, tag_and_data: `${datas.tag.topic.tag_topic_name} : ${datas.tag.tag_name}` })
        dealTags_data2.push({ tag_id: datas.tag.id })
      })
      setTagItem(dealTags_data)
      setTagItemdDefault(dealTags_data2)
    }
    setdeal_detail_promote(props.location.state.deal_detail_promote)
    setPoint(props.location.state.point)
    setExpirelyMinute(props.location.state.deal_expire_min)
    var data_all_campaign = []
    var data_all_member = []
    props.location.state.dealRules.map((deal) => {
      if (deal.type === "display") {
        setStartDateDisplay(moment(deal.start_date).format("YYYY-MM-DD HH:mm"))
        setStartDateDisplay_check(moment(deal.start_date).format("YYYY-MM-DD HH:mm"))
        setEndDateDisplay(moment(deal.end_date).format("YYYY-MM-DD HH:mm"))
      }
      if (deal.type === "redeem_period" || deal.type === "redeem") {
        setStartDateRedeem(moment(deal.start_date).format("YYYY-MM-DD HH:mm"))
        setStartDateRedeem_check(moment(deal.start_date).format("YYYY-MM-DD HH:mm"))
        setEndDateRedeem(moment(deal.end_date).format("YYYY-MM-DD HH:mm"))
      }
      if (deal.type === "quota_campaign") {
        const cover_Campaign = getCampaign ? JSON.parse(getCampaign) : []
        let data = {
          start_date: moment(deal.start_date).format("YYYY-MM-DD HH:mm"),
          end_date: moment(deal.end_date).format("YYYY-MM-DD HH:mm"),
          period_limit: deal.period_limit,
          yearly_limit: deal.yearly_limit,
          monthly_limit: deal.monthly_limit,
          weekly_limit: deal.weekly_limit,
          daily_limit: deal.daily_limit,
        }
        let getCampaign = localStorage.getItem("quotaCampaignData")
        data_all_campaign.push(...cover_Campaign, data)
        setCampaignData(data_all_campaign);
        localStorage.setItem("quotaCampaignData", JSON.stringify(data_all_campaign))
      }
      if (deal.type === "quota_member") {
        const cover_Member = getMember ? JSON.parse(getMember) : []
        let data = {
          start_date: moment(deal.start_date).format("YYYY-MM-DD HH:mm"),
          end_date: moment(deal.end_date).format("YYYY-MM-DD HH:mm"),
          period_limit: deal.period_limit,
          yearly_limit: deal.yearly_limit,
          monthly_limit: deal.monthly_limit,
          weekly_limit: deal.weekly_limit,
          daily_limit: deal.daily_limit,
        }
        let getMember = localStorage.getItem("quotaMemberData")
        data_all_member.push(...cover_Member, data)
        setMemberData(data_all_member);
        localStorage.setItem("quotaMemberData", JSON.stringify(data_all_member))
      }
    })

  }, []);

  const getRule = async (id) => {
    fetch(`${privilegeURLV2}/deal-rule/bff/${id}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessTokenPrivilege}`,
      }
    })
      .then((response) => response.json())
      .then(async (res) => {
        setCheckedError(true)
        setCheckedErrorRe(true)

        var data_all = []
        var data_mon = []
        var data_tue = []
        var data_wed = []
        var data_thu = []
        var data_fri = []
        var data_sat = []
        var data_sun = []

        var data_allRes = []
        var data_monRes = []
        var data_tueRes = []
        var data_wedRes = []
        var data_thuRes = []
        var data_friRes = []
        var data_satRes = []
        var data_sunRes = []

        await res.display.map((data, key) => {
          data.dialog_datetime.map((datas, keys) => {

            if (datas.day_of_week === 1) {
              data_mon.push({ day_of_week: 1, start_time: datas.start_time, end_time: datas.end_time, check_validate_time: true })
              setCheckedMon(true)
              data_all.push(datas)
            }
            if (datas.day_of_week === 2) {
              data_tue.push({ day_of_week: 2, start_time: datas.start_time, end_time: datas.end_time, check_validate_time: true });
              setCheckedTue(true)
              data_all.push(datas)
            }
            if (datas.day_of_week === 3) {
              data_wed.push({ day_of_week: 3, start_time: datas.start_time, end_time: datas.end_time, check_validate_time: true });
              setCheckedWed(true)
              data_all.push(datas)
            }
            if (datas.day_of_week === 4) {
              data_thu.push({ day_of_week: 4, start_time: datas.start_time, end_time: datas.end_time, check_validate_time: true });
              setCheckedThu(true)
              data_all.push(datas)
            }
            if (datas.day_of_week === 5) {
              data_fri.push({ day_of_week: 5, start_time: datas.start_time, end_time: datas.end_time, check_validate_time: true });
              setCheckedFri(true)
              data_all.push(datas)
            }
            if (datas.day_of_week === 6) {
              data_sat.push({ day_of_week: 6, start_time: datas.start_time, end_time: datas.end_time, check_validate_time: true });
              setCheckedSat(true)
              data_all.push(datas)
            }
            if (datas.day_of_week === 7) {
              data_sun.push({ day_of_week: 7, start_time: datas.start_time, end_time: datas.end_time, check_validate_time: true });
              setCheckedSun(true)
              data_all.push(datas)
            }
          })
        })

        if (data_all.length > 0) {
          await setDialogTable(data_all)

          if (data_mon.length > 0) {
            setMon(data_mon)
          }
          if (data_tue.length > 0) {
            setTue(data_tue)
          }
          if (data_wed.length > 0) {
            setWed(data_wed)
          }
          if (data_thu.length > 0) {
            setThu(data_thu)
          }
          if (data_fri.length > 0) {
            setFri(data_fri)
          }
          if (data_sat.length > 0) {
            setSat(data_sat)
          }
          if (data_sun.length > 0) {
            setSun(data_sun)
          }

        }

        await res.redeem.map((data, key) => {
          data.dialog_datetime.map((datas, keys) => {
            if (datas.day_of_week === 1) {
              data_monRes.push({ day_of_week: 1, start_time: datas.start_time, end_time: datas.end_time, check_validate_time: true })
              setCheckedMonRe(true)
              data_allRes.push(datas)
            }
            if (datas.day_of_week === 2) {
              data_tueRes.push({ day_of_week: 2, start_time: datas.start_time, end_time: datas.end_time, check_validate_time: true });
              setCheckedTueRe(true)
              data_allRes.push(datas)
            }
            if (datas.day_of_week === 3) {
              data_wedRes.push({ day_of_week: 3, start_time: datas.start_time, end_time: datas.end_time, check_validate_time: true });
              setCheckedWedRe(true)
              data_allRes.push(datas)
            }
            if (datas.day_of_week === 4) {
              data_thuRes.push({ day_of_week: 4, start_time: datas.start_time, end_time: datas.end_time, check_validate_time: true });
              setCheckedThuRe(true)
              data_allRes.push(datas)
            }
            if (datas.day_of_week === 5) {
              data_friRes.push({ day_of_week: 5, start_time: datas.start_time, end_time: datas.end_time, check_validate_time: true });
              setCheckedFriRe(true)
              data_allRes.push(datas)
            }
            if (datas.day_of_week === 6) {
              data_satRes.push({ day_of_week: 6, start_time: datas.start_time, end_time: datas.end_time, check_validate_time: true });
              setCheckedSatRe(true)
              data_allRes.push(datas)
            }
            if (datas.day_of_week === 7) {
              data_sunRes.push({ day_of_week: 7, start_time: datas.start_time, end_time: datas.end_time, check_validate_time: true });
              setCheckedSunRe(true)
              data_allRes.push(datas)
            }
          })
        })

        if (data_allRes.length > 0) {
          await setDialogTableRe(data_allRes)

          if (data_monRes.length > 0) {
            setMonRe(data_monRes)
          }
          if (data_tueRes.length > 0) {
            setTueRe(data_tueRes)
          }
          if (data_wedRes.length > 0) {
            setWedRe(data_wedRes)
          }
          if (data_thuRes.length > 0) {
            setThuRe(data_thuRes)
          }
          if (data_friRes.length > 0) {
            setFriRe(data_friRes)
          }
          if (data_satRes.length > 0) {
            setSatRe(data_satRes)
          }
          if (data_sunRes.length > 0) {
            setSunRe(data_sunRes)
          }

        }
      })

  };
  const getTag = async (campaigns_id_privilage) => {
    fetch(`${privilegeURLV2}/tag?campaign_id=${campaigns_id_privilage}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessTokenPrivilege}`,
      }
    })
      .then((response) => response.json())
      .then(async (res) => {
        var newState = [];
        if (res.data) {
          res.data.map((data) => {
            newState.push({ tag_name: data.tag_name, tag_id: data.id, topic: data.topic.id, topic_name: data.topic.tag_topic_name });
          });
          setTagData(newState);
        } else {
          setTagData({ tag_name: "No data", tag_id: 0, topic: "No data", topic_name: "No data" });
        }
      });
  };

  const columns_d = [
    {
      dataField: "day_of_week",
      text: "Day",
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, index) => {
        return (
          <>
            <span>
              {row.day_of_week === 1 ? "Monday" : ""}
              {row.day_of_week === 2 ? "Tuesday" : ""}
              {row.day_of_week === 3 ? "Wednesday" : ""}
              {row.day_of_week === 4 ? "Thursday" : ""}
              {row.day_of_week === 5 ? "Friday" : ""}
              {row.day_of_week === 6 ? "Saturday" : ""}
              {row.day_of_week === 7 ? "Sunday" : ""}
            </span>

          </>
        );
      },
    },
    {
      dataField: "start_time",
      text: "Time Start",
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "end_time",
      text: "Time End",
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ]


  //Mon
  const handleMonsChange = (event) => {
    const _tempMons = [...mons];
    _tempMons[event.target.dataset.id][event.target.name] = event.target.value;
    setMon(_tempMons);
  };
  const addNewMons = () => {
    setMon((prevMons) => [...prevMons, { day_of_week: "", time_start: "", time_end: "" }]);
  };
  const RemoveMonsFields = (index) => {
    const values = [...mons];
    values.splice(index, 1);
    setMon(values);
  };

  //Tue
  const handleTuesChange = (event) => {
    const _tempTues = [...tues];
    _tempTues[event.target.dataset.id][event.target.name] = event.target.value;

    setTue(_tempTues);
  };
  const addNewTues = () => {
    setTue((prevTues) => [...prevTues, { day_of_week: "", time_start: "", time_end: "" }]);
  };
  const RemoveTuesFields = (index) => {
    const values = [...tues];
    values.splice(index, 1);
    setTue(values);
  };

  //Wed
  const handleWedsChange = (event) => {
    const _tempWeds = [...weds];
    _tempWeds[event.target.dataset.id][event.target.name] = event.target.value;

    setWed(_tempWeds);
  };
  const addNewWeds = () => {
    setWed((prevWeds) => [...prevWeds, { day_of_week: "", time_start: "", time_end: "" }]);
  };
  const RemoveWedsFields = (index) => {
    const values = [...weds];
    values.splice(index, 1);
    setWed(values);
  };

  //Thu
  const handleThusChange = (event) => {
    const _tempThus = [...thus];
    _tempThus[event.target.dataset.id][event.target.name] = event.target.value;
    setThu(_tempThus);
  };
  const addNewThus = () => {
    setThu((prevThus) => [...prevThus, { day_of_week: "", time_start: "", time_end: "" }]);
  };
  const RemoveThusFields = (index) => {
    const values = [...thus];
    values.splice(index, 1);
    setThu(values);
  };

  //Fir
  const handlefrisChange = (event) => {
    const _tempfris = [...fris];
    _tempfris[event.target.dataset.id][event.target.name] = event.target.value;
    setFri(_tempfris);
  };
  const addNewfris = () => {
    setFri((prevfris) => [...prevfris, { day_of_week: "", time_start: "", time_end: "" }]);
  };
  const RemovefrisFields = (index) => {
    const values = [...fris];
    values.splice(index, 1);
    setFri(values);
  };

  //Sat
  const handleSatsChange = (event) => {
    const _tempSats = [...sats];
    _tempSats[event.target.dataset.id][event.target.name] = event.target.value;
    setSat(_tempSats);
  };
  const addNewSats = () => {
    setSat((prevSats) => [...prevSats, { day_of_week: "", time_start: "", time_end: "" }]);
  };
  const RemoveSatsFields = (index) => {
    const values = [...sats];
    values.splice(index, 1);
    setSat(values);
  };

  //Sun
  const handleSunsChange = (event) => {
    const _tempSuns = [...suns];
    _tempSuns[event.target.dataset.id][event.target.name] = event.target.value;
    setSun(_tempSuns);
  };
  const addNewSuns = () => {
    setSun((prevSuns) => [...prevSuns, { day_of_week: "", time_start: "", time_end: "" }]);
  };
  const RemoveSunsFields = (index) => {
    const values = [...suns];
    values.splice(index, 1);
    setSun(values);
  };


  //MonRe
  const handleMonResChange = (event) => {
    const _tempMonRes = [...monRes];
    _tempMonRes[event.target.dataset.id][event.target.name] = event.target.value;
    setMonRe(_tempMonRes);
  };
  const addNewMonRes = () => {
    setMonRe((prevMonRes) => [...prevMonRes, { day_of_week: "", time_start: "", time_end: "" }]);
  };
  const RemoveMonResFields = (index) => {
    const values = [...monRes];
    values.splice(index, 1);
    setMonRe(values);
  };

  //TueRe
  const handleTueResChange = (event) => {
    const _tempTueRes = [...tueRes];
    _tempTueRes[event.target.dataset.id][event.target.name] = event.target.value;
    setTueRe(_tempTueRes);
  };
  const addNewTueRes = () => {
    setTueRe((prevTueRes) => [...prevTueRes, { day_of_week: "", time_start: "", time_end: "" }]);
  };
  const RemoveTueResFields = (index) => {
    const values = [...tueRes];
    values.splice(index, 1);
    setTueRe(values);
  };

  //WedRe
  const handleWedResChange = (event) => {
    const _tempWedRes = [...wedRes];
    _tempWedRes[event.target.dataset.id][event.target.name] = event.target.value;

    setWedRe(_tempWedRes);
  };
  const addNewWedRes = () => {
    setWedRe((prevWedRes) => [...prevWedRes, { day_of_week: "", time_start: "", time_end: "" }]);
  };
  const RemoveWedResFields = (index) => {
    const values = [...wedRes];
    values.splice(index, 1);
    setWedRe(values);
  };

  //ThuRe
  const handleThuResChange = (event) => {
    const _tempThuRes = [...thuRes];
    _tempThuRes[event.target.dataset.id][event.target.name] = event.target.value;
    setThuRe(_tempThuRes);
  };
  const addNewThuRes = () => {
    setThuRe((prevThuRes) => [...prevThuRes, { day_of_week: "", time_start: "", time_end: "" }]);
  };
  const RemoveThuResFields = (index) => {
    const values = [...thuRes];
    values.splice(index, 1);
    setThuRe(values);
  };

  //FirRe
  const handlefriResChange = (event) => {
    const _tempfriRes = [...friRes];
    _tempfriRes[event.target.dataset.id][event.target.name] = event.target.value;
    setFriRe(_tempfriRes);
  };
  const addNewfriRes = () => {
    setFriRe((prevfriRes) => [...prevfriRes, { day_of_week: "", time_start: "", time_end: "" }]);
  };
  const RemovefriResFields = (index) => {
    const values = [...friRes];
    values.splice(index, 1);
    setFriRe(values);
  };

  //SatRe
  const handleSatResChange = (event) => {
    const _tempSatRes = [...satRes];
    _tempSatRes[event.target.dataset.id][event.target.name] = event.target.value;
    setSatRe(_tempSatRes);
  };
  const addNewSatRes = () => {
    setSatRe((prevSatRes) => [...prevSatRes, { day_of_week: "", time_start: "", time_end: "" }]);
  };
  const RemoveSatResFields = (index) => {
    const values = [...satRes];
    values.splice(index, 1);
    setSatRe(values);
  };

  //SunRe
  const handleSunResChange = (event) => {
    const _tempSunRes = [...sunRes];
    _tempSunRes[event.target.dataset.id][event.target.name] = event.target.value;
    setSunRe(_tempSunRes);
  };
  const addNewSunRes = () => {
    setSunRe((prevSunRes) => [...prevSunRes, { day_of_week: "", time_start: "", time_end: "" }]);
  };
  const RemoveSunResFields = (index) => {
    const values = [...sunRes];
    values.splice(index, 1);
    setSunRe(values);
  };


  const addCampaingn = () => {
    var data_all = [];
    // setid_q(id_q + 1)
    let data = {
      // id: id_q + 1,
      start_date: start_date_q,
      end_date: end_date_q,
      period_limit: period_limit_q,
      year_limit: year_limit_q,
      month_limit: month_limit_q,
      week_limit: week_limit_q,
      day_limit: day_limit_q
    }
    data_all.push(...campaignData, data)

    setCampaignData(data_all);
    localStorage.setItem("quotaCampaignData", JSON.stringify(data_all))
    setstart_date_q("")
    setend_date_q("")
    setperiod_limit_q("")
    setyear_limit_q("")
    setmonth_limit_q("")
    setweek_limit_q("")
    setday_limit_q("")
  };

  const RemoveCampaignNewFields = (items) => {
    let get = localStorage.getItem("quotaCampaignData")
    const cover_cam = JSON.parse(get)
    for (var i = 0; i < cover_cam.length; i++) {
      var memidx = cover_cam.map(
        function (mem) {
          return mem.id;
        }).indexOf(items.row.id);
      if (memidx != "-1") {
        cover_cam.splice(memidx, 1);
      }
    }

    localStorage.setItem("quotaCampaignData", JSON.stringify(cover_cam))
    setCampaignData(cover_cam);
  };
  const columns = [
    {
      dataField: "start_date",
      text: "Start Date",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <p>
              {moment(row.start_date).format('YYYY-MM-DD HH:mm:ss')}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "end_date",
      text: "End Date",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <p>
              {moment(row.end_date).format('YYYY-MM-DD HH:mm:ss')}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "period_limit",
      text: "Period Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "yearly_limit",
      text: "Year Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "monthly_limit",
      text: "Month Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "weekly_limit",
      text: "Week Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "daily_limit",
      text: "Day Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ]


  const addMember = () => {
    var data_all = [];
    // setid_qm(id_q + 1)
    let data = {
      // id: id_qm + 1,
      start_date: start_date_qm,
      end_date: end_date_qm,
      period_limit: period_limit_qm,
      year_limit: year_limit_qm,
      month_limit: month_limit_qm,
      week_limit: week_limit_qm,
      day_limit: day_limit_qm
    }
    data_all.push(...memberData, data)

    setMemberData(data_all);
    localStorage.setItem("quotaMemberData", JSON.stringify(data_all))
    setstart_date_qm("")
    setend_date_qm("")
    setperiod_limit_qm("")
    setyear_limit_qm("")
    setmonth_limit_qm("")
    setweek_limit_qm("")
    setday_limit_qm("")
  };

  const RemoveMemberNewFields = (items) => {
    let get = localStorage.getItem("quotaMemberData")
    const cover_cam = JSON.parse(get)
    for (var i = 0; i < cover_cam.length; i++) {
      var memidx = cover_cam.map(
        function (mem) {
          return mem.id;
        }).indexOf(items.row.id);
      if (memidx != "-1") {
        cover_cam.splice(memidx, 1);
      }
    }

    localStorage.setItem("quotaMemberData", JSON.stringify(cover_cam))
    setMemberData(cover_cam);
  };
  const columns_m = [
    {
      dataField: "start_date",
      text: "Start Date",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <p>
              {moment(row.start_date).format('YYYY-MM-DD HH:mm:ss')}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "end_date",
      text: "End Date",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <p>
              {moment(row.end_date).format('YYYY-MM-DD HH:mm:ss')}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "period_limit",
      text: "Period Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "yearly_limit",
      text: "Year Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "monthly_limit",
      text: "Month Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "weekly_limit",
      text: "Week Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "daily_limit",
      text: "Day Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ]


  const handleChangeSwitch = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  const handleChangeFile = async (e) => {
    setIsUpload(true);
    if (e.target.files.length) {
      e.preventDefault();
      let file = e.target.files[0];
      setFile(file);
      setNameFile(file.name);
    }
  };

  const getSteps = () => {
    return ['Step 1', 'Step 2',
      // 'Step 3'
    ];
  }
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    if (activeStep === 2) {
      history.goBack();
    } else if (activeStep === 1) {
      history.goBack();
    } else if (activeStep === 0) {
      setActiveStep(activeStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (activeStep === 0) {
        history.goBack();
      } else {
        setActiveStep(activeStep - 1)
      }
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const top100Films = [
    { tag_name: 'Not data', group: "No data" },
  ];

  const handleChangeItem_pic_path = async e => {
    if (e.target.files.length) {
      e.preventDefault()
      let file = e.target.files[0]
      handlepicUploadItem_pic_path(file)
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function (e) {
        var pic = new Image()
        pic.src = e.target.result
        pic.onload = function () {
          setStateItem_pic_path({
            rawItem_pic_path: file,
            picURLItem_pic_path: e.target.result
          })

          e.target.value = null
          return true
        }
      }
    }
  }
  const handlepicUploadItem_pic_path = async file => {
    var formdata = new FormData()
    formdata.append('image', file, file.name)
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    }
    fetch(`${privilegeURL}/upload/`, requestOptions)
      .then(response => response.text())
      .then(result => {
        let res = JSON.parse(result)
        setStateItem_pic_path({
          previewItem_pic_path: null,
          rawItem_pic_path: null,
          picURLItem_pic_path: null
        })
        setItem_pic_path(`${res.filename}`)
        setNamepicItem_pic_path(`${imageURL}/${res.path}`)
      })
      .catch(error => console.log('error', error))
  }

  const removepicItem_pic_path = async () => {
    setStateItem_pic_path({
      previewItem_pic_path: null,
      rawItem_pic_path: null,
      picURLItem_pic_path: null
    })
    setItem_pic_path('')
  }

  const handleCancelUploadItem_pic_path = () => {
    setStateItem_pic_path({
      previewItem_pic_path: null,
      rawItem_pic_path: null,
      picURLItem_pic_path: null
    })
  }


  const handleChangeItem_logo_path = async e => {
    if (e.target.files.length) {
      e.preventDefault()
      let file = e.target.files[0]
      handlelogoUploadItem_logo_path(file)
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function (e) {
        var logo = new Image()
        logo.src = e.target.result
        logo.onload = function () {
          setStateItem_logo_path({
            rawItem_logo_path: file,
            logoURLItem_logo_path: e.target.result
          })
          e.target.value = null
          return true

        }
      }
    }
  }
  const handlelogoUploadItem_logo_path = async file => {
    var formdata = new FormData()
    formdata.append('image', file)
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    }
    fetch(`${privilegeURL}/upload/`, requestOptions)
      .then(response => response.text())
      .then(result => {
        let res = JSON.parse(result)
        setStateItem_logo_path({
          previewItem_logo_path: null,
          rawItem_logo_path: null,
          logoURLItem_logo_path: null
        })
        setItem_logo_path(`${res.filename}`)
        setNamelogoItem_logo_path(`${imageURL}/${res.path}`)
      })
      .catch(error => console.log('error', error))
  }

  const removelogoItem_logo_path = async () => {
    setStateItem_logo_path({
      previewItem_logo_path: null,
      rawItem_logo_path: null,
      logoURLItem_logo_path: null
    })
    setItem_logo_path('')
  }

  const handleCancelUploadItem_logo_path = () => {
    setStateItem_logo_path({
      previewItem_logo_path: null,
      rawItem_logo_path: null,
      logoURLItem_logo_path: null
    })
  }


  const save = async () => {
    if (file) {
      setTimeout(() => {
        setProgress(25);
      }, 1000);
      const formData = new FormData();
      // Update the formData object
      formData.append("file", file, file.name);
      setTimeout(() => {
        setProgress(100);
      }, 1500);
      setTimeout(() => {
        setProgress(0);
      }, 2000);
    }
  };

  const deleteUpload = async () => {
    setIsUpload(false);
    setFile(null);
    setNameFile("");
  };
  const confirm = async () => {
    var data_all = []

    await mons.map((data, index) => {
      if (data.time_start) {
        data_all.push({ day_of_week: 1, time_start: data.time_start, time_end: data.time_end })
      }
    })
    await tues.map((data, index) => {
      if (data.time_start) {
        data_all.push({ day_of_week: 2, time_start: data.time_start, time_end: data.time_end })
      }
    })
    await weds.map((data, index) => {
      if (data.time_start) {
        data_all.push({ day_of_week: 3, time_start: data.time_start, time_end: data.time_end })
      }

    })
    await thus.map((data, index) => {
      if (data.time_start) {
        data_all.push({ day_of_week: 4, time_start: data.time_start, time_end: data.time_end })
      }

    })
    await fris.map((data, index) => {
      if (data.time_start) {
        data_all.push({ day_of_week: 5, time_start: data.time_start, time_end: data.time_end })
      }

    })
    await sats.map((data, index) => {
      if (data.time_start) {
        data_all.push({ day_of_week: 6, time_start: data.time_start, time_end: data.time_end })
      }

    })
    await suns.map((data, index) => {
      if (data.time_start) {
        data_all.push({ day_of_week: 7, time_start: data.time_start, time_end: data.time_end })
      }
    })

    setDialogTable(data_all)
    setOpenDialogModalTable(false);

  }
  const handleClose = () => {
    setOpenDialogModalTable(false);

  }

  const confirmRe = async () => {
    var data_all = []

    await monRes.map((data, index) => {
      if (data.time_start) {
        data_all.push({ day_of_week: 1, time_start: data.time_start, time_end: data.time_end })
      }
    })
    await tueRes.map((data, index) => {
      if (data.time_start) {
        data_all.push({ day_of_week: 2, time_start: data.time_start, time_end: data.time_end })
      }
    })
    await wedRes.map((data, index) => {
      if (data.time_start) {
        data_all.push({ day_of_week: 3, time_start: data.time_start, time_end: data.time_end })
      }

    })
    await thuRes.map((data, index) => {
      if (data.time_start) {
        data_all.push({ day_of_week: 4, time_start: data.time_start, time_end: data.time_end })
      }

    })
    await friRes.map((data, index) => {
      if (data.time_start) {
        data_all.push({ day_of_week: 5, time_start: data.time_start, time_end: data.time_end })
      }

    })
    await satRes.map((data, index) => {
      if (data.time_start) {
        data_all.push({ day_of_week: 6, time_start: data.time_start, time_end: data.time_end })
      }

    })
    await sunRes.map((data, index) => {
      if (data.time_start) {
        data_all.push({ day_of_week: 7, time_start: data.time_start, time_end: data.time_end })
      }
    })
    setDialogTableRe(data_all)
    setOpenDialogModalReTable(false);

  }
  const handleCloseRe = () => {
    setOpenDialogModalReTable(false);
  }

  const openPeriod = async () => {
    setOpenDialogModalTable(true)
  }

  const openPeriodRe = async () => {
    setOpenDialogModalReTable(true)
  }

  const StepOne = () => {
    return (
      <div>
        <Card>
          <CardHeader title="Step 1"></CardHeader>
          <CardBody>
            <div className="container">
              {/* <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Privilege Name TH:{" "}
                </span>
                <input
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={nameTh}
                  onChange={(nameTh) => setNameTh(nameTh.target.value)}
                />
              </div> */}

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Privilege Name En:{" "}
                </span>
                <span>{name}
                </span>
              </div>

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Privilege Name Th:{" "}
                </span>
                <span>{name_th}
                </span>
              </div>

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Privilege Detail:{" "}
                </span>
                <textarea
                  disabled
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  style={{
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={detail}
                  onChange={(detail) =>
                    setDetail(detail.target.value)
                  }
                />
              </div>

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Privilege Detail Th:{" "}
                </span>
                <textarea
                  disabled
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  style={{
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={detail_th}
                  onChange={(detail_th) =>
                    setDetailTh(detail_th.target.value)
                  }
                />
              </div>

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Active :{" "}
                </p>
                <Switch
                  disabled
                  checked={state.is_active}
                  onChange={handleChangeSwitch("is_active")}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>


              <div className="form-inline" style={{ marginBottom: '25px' }}>
                <span style={{ marginTop: '15px', float: 'left', width: '20%' }}>
                  Privilege Image :

                </span>

              </div>

              <div
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '50%'
                }}
              >
                {stateItem_pic_path.previewItem_pic_path ? (
                  <img
                    style={{
                      width: '100%',
                      height: '100%'
                    }}
                    src={stateItem_pic_path.previewItem_pic_path}
                  />
                ) : item_pic_path ? (
                  <img
                    style={{
                      width: '100%',
                      height: '100%'
                    }}
                    src={namepicItem_pic_path}
                  />
                ) : null}
              </div>

              <br></br>
              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Tag:{" "}
                </span>
                {tagItem ?
                  tagItem.map((data, index) => (
                    <>
                      <Chip
                        key={index}
                        label={data.tag_and_data}
                        className={classes.chip}
                      />
                    </>
                  ))
                  : null
                }

              </div>

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}>
                  Condition:{" "}
                </p>
                { }

                <div dangerouslySetInnerHTML={{ __html: deal_detail_condition }} width="100%"></div>
                {/* <input
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={deal_detail_condition}
                  onChange={(deal_detail_condition) => setdeal_detail_condition(deal_detail_condition.target.value)}
                /> */}
              </div>

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Point:{" "}
                </span>
                <span>
                  {point}
                </span>
              </div>

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Delivery :{" "}
                </p>
                <Switch
                  disabled
                  checked={state.is_delivery}
                  onChange={handleChangeSwitch("is_delivery")}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    )
  }
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return StepOne();
      case 1:
        return StepTwo();
      case 2:
        return StepTree();
      default:
        return 'Unknown stepIndex';
    }
  }

  const StepTwo = () => {
    return (
      <div>
        <Card>
          <CardHeader title="Step 2"></CardHeader>
          <CardBody>
            <div className={classes.root}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Display</Typography>
                </AccordionSummary>

                <div className="form-inline m-5" style={{ marginBottom: "25px" }}>
                  <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                    Start Date:{" "}
                  </p>

                  <span>{startDateDisplay}</span>
                </div>

                <div className="form-inline m-5" style={{ marginBottom: "25px" }}>
                  <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                    End Date:{" "}
                  </p>
                  <span>{endDateDisplay}</span>
                </div>
                <div className="m-5">
                  <Card>
                    <BootstrapTable
                      keyField="id"
                      data={dialogTable}
                      columns={columns_d}
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4
                    />
                  </Card>
                </div>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>Redeem</Typography>
                </AccordionSummary>


                <div className="form-inline m-5" style={{ marginBottom: "25px" }}>
                  <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                    Start Date:{" "}
                  </p>
                  <span>{startDateRedeem}</span>
                </div>

                <div className="form-inline m-5" style={{ marginBottom: "25px" }}>
                  <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                    End Date:{" "}
                  </p>
                  <span>{endDateRedeem}</span>
                </div>

                <div className="m-5">
                  <Card>
                    <BootstrapTable
                      keyField="id"
                      data={dialogTableRe}
                      columns={columns_d}
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4
                    />
                  </Card>
                </div>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>Quota campaign</Typography>
                </AccordionSummary>
                <div className="m-5">
                  <Card>
                    <BootstrapTable
                      keyField="id"
                      data={campaignData}
                      columns={columns}
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4
                    />
                  </Card>
                </div>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>Quota member</Typography>
                </AccordionSummary>

                <div className="m-5">
                  <Card>
                    <BootstrapTable
                      keyField="id"
                      data={memberData}
                      columns={columns_m}
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4
                    />
                  </Card>
                </div>
              </Accordion>
            </div>

          </CardBody>
        </Card>

      </div >
    )
  }

  const StepTree = () => {
    return (
      <div>
        <Card>
          <CardHeader title="Step 3"></CardHeader>
          <CardBody>
            <h1>Auto Generate Code</h1>
            <div className="container">
              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Length:{" "}
                </span>
                <input
                  type="number"
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={length}
                  onChange={(length) => setlength(length.target.value)}
                />
              </div>

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Amount:{" "}
                </span>
                <input
                  type="number"
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={amount}
                  onChange={(amount) => setamount(amount.target.value)}
                />
              </div>

              <div className="form-group form-check">
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                </span>
                <input type="checkbox" className="form-check-input" id="exampleCheck1" onClick={() => setuppercase(!uppercase)} />
                <label className="form-check-label" for="exampleCheck1">Upper Case</label>
              </div>
              <div className="form-group form-check">
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                </span>
                <input type="checkbox" className="form-check-input" id="exampleCheck2" onClick={() => setlowercase(!lowercase)} />
                <label className="form-check-label" for="exampleCheck2">Lower Case</label>
              </div>
              <div className="form-group form-check">
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                </span>
                <input type="checkbox" className="form-check-input" id="exampleCheck3" onClick={() => setnumbers(!numbers)} />
                <label className="form-check-label" for="exampleCheck3">Number</label>
              </div>
              <div className="form-group form-check">
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                </span>
                <input type="checkbox" className="form-check-input" id="exampleCheck4" onClick={() => setsymbols(!symbols)} />
                <label className="form-check-label" for="exampleCheck4">Symbol</label>
              </div>
            </div>
            <h1>Upload Code</h1>
            <div className="container">
              <div className="form-inline">
                <span style={{ marginTop: "15px", float: "left", width: "20%" }}>
                  Upload file: <br />
                </span>
                <input
                  name="name"
                  style={{
                    height: "38px",
                    width: "330px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  disabled
                  value={namefile}
                />

                <span className="btn btn-primary btn-file wbt">
                  เลือก / แก้ไข
            <input type="file" onChange={handleChangeFile} />
                </span>
              </div>
              {isUpload ? (
                <div className="form-inline" style={{ marginBottom: "25px" }}>
                  <span style={{ float: "left", width: "20%" }}> </span>
                  {progress > 0 ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {"  "}Loading...
                    </button>
                  ) : (
                    <>
                      <button className="btn btn-primary mb-5" onClick={() => save()}>
                        Upload
                    </button>
                      <button className="btn btn-danger mb-5"
                        onClick={() => deleteUpload()}
                      >
                        Delete
                  </button>
                    </>
                  )}
                </div>
              ) : null}

              {progress > 0 ? (
                <div>
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animatedprogress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      style={{ width: `${progress}%` }}
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {progress === 100 ? progress : `${progress} %`}
                    </div>
                  </div>
                </div>
              ) : null}

            </div>

          </CardBody>
        </Card>
      </div>
    )
  }

  return (
    <Card>
      <CardHeader title="View Privilege"></CardHeader>
      <CardBody>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div className="d-flex justify-content-between">
                <Typography className={classes.instructions}>All steps completed</Typography>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                <div className="d-flex justify-content-between">
                  <Button
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
              </Button>
                  {activeStep !== 1 ?
                    <Button variant="contfained" className="btn btn-primary" onClick={handleNext}>
                      {'Next'}
                    </Button>
                    : null}
                  {activeStep === 1 ?
                    <Button variant="contfained" className="btn btn-primary" onClick={handleNext}>
                      {'Finish'}
                    </Button>
                    : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
