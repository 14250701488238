import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import axios from "axios";
import { privilegeURL, privilegeURLV2, campaigns_id_privilage } from "../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import JoditEditor from "./JoditEditor";
import { Link } from "react-router-dom";
import "./component.css";
import { DatePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import { useSelector } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

export const EditDealGroup = (props) => {
  const suhbeader = useSubheader();
  const { accessTokenPrivilege } = useSelector((state) => state.auth);
  suhbeader.setTitle("Edit Privilege Group");
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [amount_limit, setAmount_limit] = useState();
  const [qty_limit, setQty_limit] = useState();
  const [selectedDateStart, handleDateChangeStart] = useState(null);
  const [selectedDateEnd, handleDateChangeEnd] = useState(null);
  const [detail, setDetail] = useState([]);
  const [dealData, setDataDeal] = useState([]);

  const history = useHistory();

  const AlertError = (message) => {
    Swal.fire("Edit Privilege Group!", message, "error");
  };


  useEffect(() => {
    setId(props.location.state.row.id)
    setName(props.location.state.row.name)
    setAmount_limit(props.location.state.row.amount_limit)
    setQty_limit(props.location.state.row.qty_limit)
    var start_cover = moment(`${props.location.state.row.start_date}`).format('yyyy/MM/DD HH:mm')
    var end_cover = moment(`${props.location.state.row.end_date}`).format('yyyy/MM/DD HH:mm')
    handleDateChangeStart(start_cover)
    handleDateChangeEnd(end_cover)
    getDeal()

  }, [])


  const getDeal = async () => {
    axios({
      method: "get",
      url: `${privilegeURL}/client-deal/list/all?campaign_id=${campaigns_id_privilage}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessTokenPrivilege}`,
      },
    }).then((res) => {
      let allData = []
      if (res.message) {
        history.push("/logout");
      } else {
        res.data.data.map((data, index) => {
          data = {
            id: data.id,
            deal_name: data.deal_name,
          };
          allData.push(data);
        });
      }
      let data_deal = []
      props.location.state.row.details.map((data, key) => {
        let get = allData.find(l => Number(l.id) === Number(data.deal_id))
        data_deal.push(get)
      })
      setDetail(data_deal)
      setDataDeal(allData);
    }).catch((err) => {
      window.location.assign("/logout");
    });
  };


  const handleSend = async () => {
    if (name === undefined || name === "") {
      return AlertError("Please insert name !!");
    }
    else if (amount_limit === undefined || amount_limit === "") {
      return AlertError("Please insert amount limit !!");
    // } else if (qty_limit === undefined || qty_limit === "") {
    //   return AlertError("Please insert qty limit !!");
    } else if (selectedDateStart === undefined || selectedDateStart === null || selectedDateStart === "") {
      return AlertError("Please insert start !!");
    }
    else if (selectedDateEnd === undefined || selectedDateEnd === null || selectedDateStart === "") {
      return AlertError("Please insert end !!");
    }
    else if (selectedDateStart._d >= selectedDateEnd._d) {
      return AlertError('End date must more than start date !!')

    } else if (
      amount_limit && amount_limit <= 0) {
      return AlertError('Please insert at amount limit more than 0 !!')
    }
    // else if (
    //   qty_limit && qty_limit <= 0) {
    //   return AlertError('Please insert at qty limit more than 0 !!')
    // }
    else if (detail.length === 0) {
      return AlertError("Please select detail !!");
    }
    else {
      let cover_detail = []

      detail.map((data, key) => {
        cover_detail.push(data.id)
      })
      var events = {
        name: name,
        amount_limit: amount_limit,
        // qty_limit: qty_limit,
        start_date: moment(selectedDateStart._d ? selectedDateStart._d : selectedDateStart).format("YYYY/MM/DD HH:mm:ss"),
        end_date: moment(selectedDateEnd._d ? selectedDateEnd._d : selectedDateEnd).format("YYYY/MM/DD HH:mm:ss"),
        detail: cover_detail,
        campaign_id: campaigns_id_privilage
      };
      axios({
        method: "PUT",
        url: `${privilegeURL}/deal-group/${id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessTokenPrivilege}`,
        },
        data: events
      })
        .then(async (res) => {
          Swal.fire("Save !", "Save Edit Privilege Group success", "success");
          history.goBack();
        })
        .catch((err) => {
          Swal.fire(
            "Created !",
            "Your can not Edit Privilege Group. !! " + err,
            "error"
          );
        });
    }

  };

  return (
    <Card>
      <CardHeader title="Edit Privilege Group"></CardHeader>
      <CardBody>
        <div className="container">
          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Name:{" "}
            </p>
            <input
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={name}
              onChange={(name) => setName(name.target.value)}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Amount limit:{" "}
            </p>
            <input
              type="number"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={amount_limit}
              onChange={(amount_limit) => setAmount_limit(amount_limit.target.value)}
            />
          </div>

          {/* <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Qty limit:{" "}
            </p>
            <input
              type="number"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={qty_limit}
              onChange={(qty_limit) => setQty_limit(qty_limit.target.value)}
            />
          </div> */}

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Start :{" "}
            </p>

            <KeyboardDateTimePicker
              openTo="year"
              ampm={false}
              value={selectedDateStart}
              onChange={handleDateChangeStart}
              format="yyyy/MM/DD HH:mm"
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              End :{" "}
            </p>
            <KeyboardDateTimePicker
              openTo="year"
              ampm={false}
              value={selectedDateEnd}
              minDate={selectedDateStart}
              onChange={handleDateChangeEnd}
              format="yyyy/MM/DD HH:mm"
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Deal:{" "}
            </p>

            <Autocomplete
              multiple
              id="grouped-demo"
              options={dealData}
              getOptionLabel={(option) => option.deal_name ? option.deal_name : ""}
              getOptionSelected={(option, value) => option.id === value.id}
              value={detail}
              onChange={(event, newValue) => {
                setDetail(newValue);
              }}
              style={{
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              renderInput={(params) =>
                <TextField {...params} variant="outlined" />
              }
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/DHLtoyou/PrivilegeGroup">
              <button type="button" className="btn btn-secondary wbt">
                Cancel
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => handleSend()}>
              Save
            </button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
