import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader } from "../../_metronic/_partials/controls";
import { baseURL } from "../service/API.js";
import { useSubheader } from "../../_metronic/layout";
import Swal from "sweetalert2";
import axios from "axios";

export const Banner = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Banner");

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    axios
      .get(`${baseURL}/banner`)
      .then((res) => {
        if (res.data.length > 0) {
          setState({
            imageId: res.data[0].id,
            imageURL: res.data[0].link,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [state, setState] = useState({
    imageId: null,
    imageName: "",
    imageURL: "",
  });
  const [isUpload, setIsUpload] = useState(false);
  const [progress, setProgress] = useState(0);

  const dhlURL = baseURL.split("/")[2];
  const handleChangeImage = async (e) => {
    setIsUpload(true);
    setProgress(25);
    if (e.target.files.length) {
      e.preventDefault();
      let file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = async function() {
          var height = this.height;
          var width = this.width;
          if (height < 280 || width < 1100) {
            Swal.fire(
              "Upload !",
              "At least you can upload a 1100*280 photo size. !! ",
              "error"
            );
            return false;
          } else {
            setProgress(50);

            const image = await FileUpload(file);
            setState({
              raw: file,
              imageURL: `https://${dhlURL}/uploads/${image.filename}`,
              imageName: `${image.filename}`,
            });
            return true;
          }
        };
      };
    }
  };

  const save = async () => {
    var events = {
      image: state.imageName,
      link: state.imageURL,
      weight: 2,
    };

    axios
      .post(`${baseURL}/banner`, events)
      .then(async (res) => {
        await Swal.fire("Save !", "Save banner success", "success");
        getList();
      })
      .catch((err) => {
        Swal.fire("Save !", "Save banner fail " + err, "error");
      });
  };

  const FileUpload = async (file) => {
    setProgress(75);

    let result = null;

    let formData = new FormData();
    formData.append("image", file);

    await fetch(`${baseURL}/upload`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then(async (data) => {
        setProgress(100);
        setIsUpload(false);

        Swal.fire("Upload !", "Image upload success", "success");
        result = data;
      })
      .catch(async (error) => {
        Swal.fire("Upload !", "Image upload fail" + error, "error");
      });

    return result;
  };

  return (
    <Card>
      <CardHeader title="Banner"></CardHeader>
      <CardBody>
        <form>
          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "100%",
            }}
          >
            {state.imageURL !== "" ? (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={state.imageURL}
              />
            ) : (
              ""
            )}
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              Banner Upload : <br></br>
              (1100*280)
            </p>
            <input
              name="ImageURL"
              style={{
                height: "38px",
                width: "330px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              disabled
              value={state.imageURL}
            />

            <span className="btn btn-primary btn-file wbt">
              เลือก / แก้ไข
              <input type="file" onChange={handleChangeImage} />
            </span>
          </div>
          {isUpload ? (
            <div>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${progress}%` }}
                  aria-valuenow={progress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {progress}
                </div>
              </div>
            </div>
          ) : null}
          <div
            className="form-inline"
            style={{ marginBottom: "25px", marginTop: "25px" }}
          >
            <button type="button" className="btn btn-primary " onClick={save}>
              Save
            </button>
          </div>
        </form>
      </CardBody>
    </Card>
  );
};
