import React from "react";
import { Jodit } from "jodit";
import JoditReact from "jodit-react";
import { FileUpload } from "../../service/ImageUploader";
import Image_img from "./icon/Image.png"
import Param_img from "./icon/Param.png"
import Video_img from "./icon/Video.png"
class JoditEditorEmailNewComing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      image: "",
      crop: { x: 0, y: 0 },
      zoom: 1,
      cropPixels: null,
      aspect: 4 / 3,
    };

    this.editorConfig = {
      readonly: false,
      autofocus: true,
      tabIndex: 1,
      useIframeResizer: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",

      placeholder: "Write something awesome ...",
      beautyHTML: true,
      toolbarButtonSize: "large",

      extraButtons: ["uploadImage", "addLink", "concat"],
    };
  }
  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ cropPixels: croppedAreaPixels });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };
  componentWillMount() {
    this.setState({ editorContent: this.props.messageValue });
    this.uploadImageButton();
    this.customButton();
  }

  uploadImageButton = () => {
    Jodit.defaultOptions.controls.uploadImage = {
      name: "Upload image to Cloudinary",
      iconURL: Image_img,
      exec: async (editor) => {
        await this.imageUpload(editor);
      },
    };
  };
  customButton = () => {
    Jodit.defaultOptions.controls.concat = {
      name: "Parameter",
      iconURL: Param_img,
      list: {
        "##site_id##": "site_id",
        "##site_name##": "site_name",
        "##customer_name##": "customer_name",
        "##customer_email##": "customer_email",
        "##segment##": "segment",
        "##target_revenue##": "target_revenue",
        "##campaign_period##": "campaign_period",
        "##year##": "year",
        "##account_no##": "account_no",
        "##voucher##": "voucher",
      },
      exec: (editor, current, control) => {
        if (control.control.args === undefined) {
          editor.e.fire("errorMessage", "You can select parameter");
        } else {
          var key = control.control.args[0],
            value = control.control.args[1];
          editor.s.insertHTML(key);
        }
      },
    };

    Jodit.defaultOptions.controls.addLink = {
      name: "Link",
      iconURL: Video_img,
      popup: function(editor) {
        try {
          var value = prompt("Insert youtube url !!");
          var a = editor.selection.j.createInside.element("a");
          var image = document.createElement("img");
          var url = value;
          var res = url.split("=");
          var text = `https://img.youtube.com/vi/${res[1]}/sddefault.jpg`;
          image.setAttribute("src", text);
          a.setAttribute("href", url);
          a.setAttribute("target", "_blank");

          a.appendChild(image);
          editor.s.insertHTML(a);
        } catch (error) {}
      },
    };
  };

  imageUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async function() {
      var preview = null;
      const imageFile = input.files[0];
      var reader = new FileReader();
      var _ = this;
      if (!imageFile) {
        return;
      }
      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        return;
      }
      reader.onload = function() {
        preview = reader.result;
      };
      reader.onloadend = function() {
        preview = reader.result;
      };
      reader.readAsDataURL(imageFile);
      const imageURL = await FileUpload(imageFile);
      this.insertImage(editor, imageURL);
    }.bind(this);
  };

  insertImage = (editor, url) => {
    const image = editor.selection.j.createInside.element("img");
    image.setAttribute("src", url);
    editor.selection.insertNode(image);
  };

  onChange = (value) => {
    this.props.messageChange(value);
  };
  render() {
    return (
      <React.Fragment>
        <JoditReact
          value={this.state.editorContent}
          config={this.editorConfig}
          onChange={this.onChange.bind(this)}
        />
      </React.Fragment>
    );
  }
}

export default JoditEditorEmailNewComing;
