import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import { Link, useHistory } from "react-router-dom";
import { privilegeURL, campaigns_id_privilage } from "../service/API.js";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination2 from "@material-ui/lab/Pagination";
import { useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export const DealGroup = () => {
  const suhbeader = useSubheader();
  const classes = useStyles();

  const allData = [];
  const [templateData, setTemplateData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalSize, setTotalSize] = useState(100);
  suhbeader.setTitle("Privilege Group");
  const { user, accessTokenPrivilege } = useSelector((state) => state.auth);
  const history = useHistory();

  useEffect(() => {
    getDealGroup(page, limit, search);
  }, []);

  const getDealGroup = async (page, limit, search) => {
    setPage(page);
    axios({
      method: "get",
      url: `${privilegeURL}/deal-group?campaign_id=${campaigns_id_privilage}&page=${page}&limit=${limit}${search !== undefined && search !== "" ? "&search=" + search : ""
        }`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessTokenPrivilege}`,
      },
    }).then((res) => {
      if (res.message) {
        history.push("/logout");
      } else {
        res.data.data.map((data, index) => {
          data = {
            id: data.id ? data.id : "",
            name: data.name ? data.name : "",
            amount_limit: data.amount_limit ? data.amount_limit : "",
            qty_limit: data.qty_limit ? data.qty_limit :"",
            details: data.details.length > 0 ? data.details : "",
            start_date: data.start_date ? data.start_date : "",
            end_date: data.end_date ? data.end_date : "",
            created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
          };
          allData.push(data);
        });
      }
      var totalpage = Math.ceil(res.data.total / res.data.perPage);
      setTotalPage(totalpage);
      setTotalSize(res.data.total);
      setLimit(res.data.perPage);
      setTemplateData(allData);
    }).catch((err) => {
      window.location.assign("/logout");
    });
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "amount_limit",
      text: "Amount limit",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    // {
    //   dataField: "qty_limit",
    //   text: "Qty limit",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    {
      text: "Deal",
      formatter: (id, row, rowIndex) => {
        return (
          <FormControl className={classes.formControl}>
            <div className={classes.chips}>
              {
                row.details.map((data, index) => (
                  <Chip
                    key={index}
                    label={data.deal.deal_name ? data.deal.deal_name : null}
                    className={classes.chip}
                  />
                ))
              }
            </div>
          </FormControl>
        );
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Start Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <p>
              {moment(row.start_date).format('YYYY-MM-DD HH:mm:ss')}
            </p>
          </div>
        );
      },
    },
    {
      text: "End Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <p>
              {moment(row.end_date).format('YYYY-MM-DD HH:mm:ss')}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "created_at",
      text: "Create Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Action",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => editItem({ id, row, rowIndex })}
            >
              Edit
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={() => deleteItem({ row, rowIndex })}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  const editItem = (data) => {
    history.push("/DHLtoyou/EditPrivilegeGroup", data);
  };

  const deleteItem = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this Privilege Group !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
          axios({
            method: "delete",
            url: `${privilegeURL}/deal-group/${data.row.id}`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${accessTokenPrivilege}`,
            },
          })
          .then((res) => {
            Swal.fire("Deleted!", "Your Privilege Group has been deleted.", "success");
            getDealGroup(page, limit, search);
          })
          .catch((err) => {
            Swal.fire(
              "Deleted!",
              "Your Privilege Group has been fail to deleted.",
              "error"
            );
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your Privilege Group is safe :)", "error");
      }
    });
  };
  const [search, setSearch] = useState();
  const handleSearch = () => {
    setPage(1);
    getDealGroup(1, limit, search);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    getDealGroup(value, limit, search);
  };

  const handlePageSizeChange = (event) => {
    setLimit(event.target.value);
    getDealGroup(1, event.target.value, search);
  };

  return (
    <Card>
      <CardHeader title="Privilege Group">
        <CardHeaderToolbar>
          <Link to="/DHLtoyou/CreatePrivilegeGroup">
            <button type="button" className="btn btn-primary">
              + Create
            </button>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <BootstrapTable
          keyField="id"
          data={templateData}
          columns={columns}
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
        />
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="mt-3">
                {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={limit}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <Pagination2
                className="mt-3 "
                count={totalPage}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
