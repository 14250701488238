import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import { Link, useHistory } from "react-router-dom";
import { baseURL } from "../service/API.js";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination2 from "@material-ui/lab/Pagination";
import { TrainOutlined } from "@material-ui/icons";

export const EmailTemplateNewAccount = () => {
  const suhbeader = useSubheader();
  const allData = [];
  const [templateData, setTemplateData] = useState([]);
  const [is_newComing, setIs_newComing] = useState(true);
  const [is_additional, setIs_additional] = useState(false);
  const [is_segment, setIs_segment] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalSize, setTotalSize] = useState(100);

  const [search, setSearch] = useState("");

  suhbeader.setTitle("Email Template New Account");
  const history = useHistory();
  useEffect(() => {
    getTemplate(true, false, false, page, limit, search);
  }, []);

  const getTemplate = async (
    is_newComing,
    is_additional,
    is_segment,
    page,
    limit,
    search
  ) => {
    setPage(page)
    fetch(
      `${baseURL}/emailTemplate?page=${page}&limit=${limit}&is_newComing=${is_newComing}&is_additional=${is_additional}&is_segment=${is_segment}&is_delete=false${search !== undefined && search !== "" ? "&search=" + search : ""
      }`
    )
      .then((response) => response.json())
      .then((res) => {
        res.data.map((data, index) => {
          let types = "";
          let types_tp = "";
          if (data.is_newComing && !data.is_additional) {
            types_tp = "is_newComing";
            types = " New Coming";
          } else if (!data.is_newComing && data.is_additional) {
            types_tp = "is_additional";
            types = "Additional";
          }else if (!data.is_newComing && !data.is_additional) {
            types_tp = "is_segment";
            types = "Segment";
          }

          data = {
            id: data.id,
            template_subject: data.template_subject,
            template_name: data.template_name,
            template_message: data.template_message,
            is_active: data.is_active,
            active: data.is_active,
            is_newComing: is_newComing,
            is_additional: is_additional,
            type: types_tp,
            types: types,
            created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
            template_id: data.id,
          };
          allData.push(data);
        });
        var totalpage = Math.ceil(res.total / res.limit);
        setTotalPage(totalpage);
        setTotalSize(res.total);
        setLimit(res.limit);

        setTemplateData(allData);
      });
  };

  const columns = [
    {
      dataField: "template_id",
      text: "Template Id",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "template_name",
      text: "Template Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "template_subject",
      text: "Template Subject",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "created_at",
      text: "Create Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "active",
      text: "Active",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "types",
      text: "Type",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Action",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => editItem({ id, row, rowIndex })}
            >
              Edit
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={() => deleteItem({ row, rowIndex })}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  const editItem = (data) => {
    history.push("/DHLtoyou/EditEmailTemplateNewAccount", data);
  };

  const deleteItem = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this template !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${baseURL}/emailTemplate/${data.row.id}`)
          .then((res) => {
            Swal.fire("Deleted!", "Your template has been deleted.", "success");
            getTemplate(is_newComing, is_additional,is_segment, page, limit, search);
          })
          .catch((err) => {
            Swal.fire(
              "Deleted!",
              "Your template has been fail to deleted.",
              "error"
            );
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your template is safe :)", "error");
      }
    });
  };

  const handleSearch = () => {
    setPage(1);
    setLimit(10);
    getTemplate(is_newComing, is_additional, is_segment, 1, limit, search);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    getTemplate(is_newComing, is_additional, is_segment, value, limit, search);
  };

  const handlePageSizeChange = (event) => {
    setLimit(event.target.value);
    getTemplate(is_newComing, is_additional, is_segment, 1, event.target.value, search);
  };

  const typeCheck = (even) => {
    if (even === "new_coming") {
      setIs_newComing(true);
      setIs_additional(false);
      setIs_segment(false);
      getTemplate(true, false, false, page, limit, search);
    } else if (even === "additional") {
      setIs_newComing(false);
      setIs_additional(true);
      setIs_segment(false);
      getTemplate(false, true, false, page, limit, search);
    } else {
      setIs_newComing(false);
      setIs_additional(false);
      setIs_segment(true);
      getTemplate(false, false, true, page, limit, search);
    }
  };
  return (
    <Card>
      <CardHeader title="Email Template New Account">
        <CardHeaderToolbar>
          <Link to="/DHLtoyou/CreateEmailTemplateNewAccount">
            <button type="button" className="btn btn-primary">
              + Create
            </button>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <p
            style={{
              marginTop: "15px",
              float: "left",
              width: "10%",
            }}
          >
            Type:{" "}
          </p>
          <select
            as="select"
            style={{
              height: "38px",
              width: "45%",
              backgroundColor: "#F4F6F9",
              borderRadius: "5px",
              borderWidth: "0",
            }}
            onChange={(type) => typeCheck(type.target.value)}
            defaultValue={"new_coming"}
          >
            <option value="new_coming">New coming</option>
            <option value="additional">Additional</option>
            <option value="segment">Segment</option>
          </select>
        </div>

        <BootstrapTable
          keyField="id"
          data={templateData}
          columns={columns}
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
        />
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="mt-3">
                {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={limit}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <Pagination2
                className="mt-3 "
                count={totalPage}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
