import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import Switch from "@material-ui/core/Switch";
import { useHistory } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import { baseURL } from "../../service/API.js";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../_metronic/_helpers";
import paginationFactory from "react-bootstrap-table2-paginator";
import Select from "react-select";
import { Link } from "react-router-dom";

export const CreatePointCal = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Create Point Calculate");
  const history = useHistory();

  const [calName, setCalName] = useState();
  const [calDesc, setCalDesc] = useState();
  const [calOperator, setCalOperator] = useState();
  const [onTopValue, setOnTopValue] = useState();
  const [rateBaht, setRateBaht] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [conditions, setConditions] = useState();
  const [previews, setPreviews] = useState();
  const columns = [
    {
      dataField: "Customer Email",
      text: "Customer Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "Customer Name",
      text: "Customer Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "Customer Mobile",
      text: "Customer Mobile",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "Shipper Company Name",
      text: "Shipper Company Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "Shipper Name",
      text: "Shipper Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "Shipper Mobile",
      text: "Shipper Mobile",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "Airway Bill",
      text: "Airway Bill",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "Amount",
      text: "Amount",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "Receiver Country Code",
      text: "Receiver Country Code",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "Shipment Type",
      text: "Shipment Type",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "Transaction Date",
      text: "Transaction Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "Source System",
      text: "Source System",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "Is Test",
      text: "Is Test",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "Point",
      text: "Point",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ];
  const AlertError = (message) => {
    Swal.fire("Point Calculate!", message, "error");
  };
  const [receiverCountry, setReceiverCountry] = useState();
  const [receiverCountryValue, setReceiverCountryValue] = useState();
  const [region, setRegion] = useState();
  const [regionValue, setRegionValue] = useState();
  const [shipmentType, setShipmentType] = useState();
  const [shipmentTypeValue, setShipmentTypeValue] = useState();
  const [sourceSystem, setSourceSystem] = useState();
  const [sourceSystemValue, setSourceSystemValue] = useState();

  useEffect(() => {
    getReceiverCountryList();
    getRegion();
    getShipmentTypeList();
    getSourceSystemList();
  }, []);

  const getReceiverCountryList = async () => {
    axios
      .get(
        `${baseURL}/customer/getReceiverCountrycodeList?page=1&limit=10000&is_delete=false`
      )
      .then((res) => {
        var newState = [];
        res.data.data.map((item) => {
          newState.push({
            value: item.countrycode,
            label: item.descrption,
          });
        });
        setReceiverCountry(newState);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getRegion = async () => {
    axios
      .get(`${baseURL}/customer/region?page=1&limit=10000&is_delete=false`)
      .then((res) => {
        var newState = [];
        res.data.map((item) => {
          newState.push({
            value: item.region,
            label: item.region,
          });
        });
        setRegion(newState);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getShipmentTypeList = async () => {
    axios
      .get(
        `${baseURL}/customer/getShipmentTypeList?page=1&limit=10000&is_delete=false`
      )
      .then((res) => {
        var newState = [];
        res.data.data.map((item) => {
          newState.push({
            value: item.id,
            label: item.name,
          });
        });
        setShipmentType(newState);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSourceSystemList = async () => {
    axios
      .get(
        `${baseURL}/customer/getSourceSystemList?page=1&limit=10000&is_delete=false`
      )
      .then((res) => {
        var newState = [];
        res.data.data.map((item) => {
          newState.push({
            value: item.id,
            label: item.name,
          });
        });
        setSourceSystem(newState);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      conditionsItem: [
        {
          field_num: "",
          field_condition: "",
          field_value: "",
        },
      ],
    },
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "conditionsItem",
    }
  );

  const [state, setState] = React.useState({
    isActive: false,
    isOnTop: false,
    isDefault: false,
    isOneTime: false,
  });

  const handleChangeSwitch = (name) => (event) => {
    if (name === "isOnTop") {
      setRateBaht(0);
      setOnTopValue(0);
    }
    setState({ ...state, [name]: event.target.checked });
  };

  const onSubmit = async (data) => {
    var n = data.conditions;
    var conditionList = n.find((x) => x.field_value !== "");

    if (conditionList === undefined) {
      return AlertError("please insert condition value !!");
    }
    if (data.conditions.length !== 1) {
      if (calOperator === undefined || calOperator === "NULL") {
        return AlertError("please select cal operator !!");
      }
    }
    setConditions(data.conditions);

    if (calName === undefined) {
      return AlertError("please insert point cal detail !!");
    }
    if (startDate === undefined || endDate === undefined) {
      return AlertError("please select date !!");
    }
    if (!state.isOnTop && (rateBaht === undefined || rateBaht === "")) {
      return AlertError("please insert rate value !!");
    }
    if (state.isOnTop && (onTopValue === undefined || onTopValue === "")) {
      return AlertError("please insert  on top value !!");
    }

    var d_start = moment(startDate).format("YYYY-MM-DD");
    var d_end = moment(endDate).format("YYYY-MM-DD");
    if (d_end < d_start) {
      return AlertError("Please select start date > end date !!");
    }

    var events = {
      cal_name: calName,
      cal_desc: calDesc === "" || calDesc === undefined ? " " : calDesc,
      cal_operator: data.conditions.length === 1 ? "AND" : calOperator,
      is_on_top: Boolean(state.isOnTop),
      on_top_value: onTopValue === undefined ? 0 : onTopValue,
      is_one_time_use: Boolean(state.isOneTime),
      rate_baht: rateBaht === undefined ? 0 : rateBaht,
      start_date: startDate,
      end_date: endDate,
      is_active: Boolean(state.isActive),
      is_default: false,
      created_by_id: 1,
      conditions: data.conditions,
    };
    events.conditions.forEach((element) => {
      if (element.field_condition === "in") {
        if (
          element.field_num === "16" ||
          element.field_num === "5" ||
          element.field_num === "6" ||
          element.field_num === "11"
        ) {
          var array = [];
          element.field_value.forEach((condition) => {
            array.push(condition.value);
          });
          element.field_value = array;
        } else {
          var array = [];
          var value = element.field_value.split(",");
          value.forEach((condition) => {
            array.push(condition);
          });
          element.field_value = array;
        }
      } else {
        if (
          element.field_num === "16" ||
          element.field_num === "5" ||
          element.field_num === "6" ||
          element.field_num === "11"
        ) {
          var object = "";
          if (Array.isArray(element.field_value)) {
            object = element.field_value[0].value;
          } else {
            object = element.field_value.value;
          }
          element.field_value = object;
        }
      }
    });

    axios
      .post(`${baseURL}/calculatePoints`, events)
      .then((res) => {
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Created !",
          "Your can not create point cal. !! " + err,
          "error"
        );
      });
  };

  const handlePreview = async (data) => {
    setConditions(data.conditions);
    setPreviews();

    if (data.conditions.length !== 1) {
      if (calOperator === undefined || calOperator === "NULL") {
        return AlertError("please select cal operator !!");
      }
    }
    var n = data.conditions;
    if (n === undefined) {
      return AlertError("please insert condition value !!");
    }
    var conditionList = n.find((x) => x.field_value !== "");
    if (conditionList === undefined) {
      return AlertError("please insert condition value !!");
    }
    if (startDate === undefined || endDate === undefined) {
      return AlertError("please select date !!");
    }
    if (!state.isOnTop && (rateBaht === undefined || rateBaht === "")) {
      return AlertError("please insert rate value !!");
    }
    if (state.isOnTop && (onTopValue === undefined || onTopValue === "")) {
      return AlertError("please insert  on top value !!");
    }

    var datas = {
      operator: data.conditions.length === 1 ? "AND" : calOperator,
      date_from: startDate,
      date_to: endDate,
      conditions: data.conditions,
    };
    datas.conditions.forEach((element) => {
      if (element.field_condition === "in") {
        if (
          element.field_num === "16" ||
          element.field_num === "5" ||
          element.field_num === "6" ||
          element.field_num === "11"
        ) {
          var array = [];
          element.field_value.forEach((condition) => {
            array.push(condition.value);
          });
          element.field_value = array;
        } else {
          var array = [];
          var value = element.field_value.split(",");
          value.forEach((condition) => {
            array.push(condition);
          });
          element.field_value = array;
        }
      } else {
        if (
          element.field_num === "16" ||
          element.field_num === "5" ||
          element.field_num === "6" ||
          element.field_num === "11"
        ) {
          var object = "";
          if (Array.isArray(element.field_value)) {
            object = element.field_value[0].value;
          } else {
            object = element.field_value.value;
          }
          element.field_value = object;
        }
      }
    });

    var value = "";
    if (!state.isOnTop) {
      value = rateBaht;
    } else {
      value = onTopValue;
    }

    axios
      .post(`${baseURL}/calculatePoints/preview`, datas)
      .then((res) => {
        var newState = [];
        if (res.data.length === 0) {
          return Swal.fire(
            "Preview !",
            "Your condition not found. !! ",
            "error"
          );
        }
        res.data.map((item, index) => {
          var point_amount = item.Amount / value;
          newState.push({
            "Customer Mobile": item["Customer Mobile"],
            "Customer Email": item["Customer Email"],
            "Customer Name": item["Customer Name"],
            "Shipper Mobile": item["Shipper Mobile"],
            "Shipper Company Name": item["Shipper Company Name"],
            "Shipper Name": item["Shipper Name"],
            "Airway Bill": item["Airway Bill"],
            Amount: item.Amount,
            "Receiver Country Code": item["Receiver Country Code"],
            "Shipment Type": item["Shipment Type"],
            "Transaction Date": moment
              .utc(item["Transaction Date"])
              .format("YYYY-MM-DD hh:mm:ss"),
            "Source System": item["Source System"],
            "Is Test": item["Is Test"],
            Point: point_amount,
          });
        });
        setPreviews(newState);
      })
      .catch((err) => {
        Swal.fire(
          "Preview !",
          "Your can not preview send email. !! " + err,
          "error"
        );
      });
  };
  const handleRemove = (index) => {
    if (fields.length !== 1) {
      remove(index);
    } else {
      Swal.fire(
        "Remove Condition !",
        "Your can not remove condition. !! ",
        "error"
      );
    }
  };
  const watchFieldName = watch();
  const addConditions = () => {
    return (
      <>
        {fields.map((item, index) => {
          var field_num = "";
          var field_condition = "";
          if (watchFieldName.conditions !== undefined) {
            try {
              field_num = watchFieldName.conditions[index].field_num;
              field_condition =
                watchFieldName.conditions[index].field_condition;
            } catch (error) {}
          }
          return (
            <div key={item.id}>
              {index === 0 ? <hr className="mt-1 mb-1"></hr> : null}
              <div className="form-inline" style={{ marginTop: "20px" }}>
                <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                  <span>
                    <b>
                      {"Condition "}
                      {index + 1}
                    </b>
                  </span>
                </p>

                {fields.length > 1 ? (
                  <>
                    <button
                      className="btn btn-success "
                      type="button"
                      onClick={() => {
                        handleRemove(index);
                      }}
                    >
                      Remove Condition
                    </button>
                    <br></br>
                  </>
                ) : (
                  ""
                )}
              </div>

              <div className="form-inline" style={{ marginTop: "20px" }}>
                <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                  Field:{" "}
                </p>
                <select
                  as="select"
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  name={`conditions[${index}].field_num`}
                  ref={register()}
                >
                  <option value={1}>{"Customer Email"}</option>
                  <option value={2}>{"Customer Name"}</option>
                  <option value={3}>{"Customer Phone"}</option>
                  <option value={4}>{"Amount"}</option>
                  <option value={5}>{"Receiver Country"}</option>
                  <option value={6}>{"Shipment Type"}</option>

                  <option value={7}>{"Transaction Date"}</option>

                  <option value={8}>{"Shipper Name"}</option>
                  <option value={9}>{"Shipper Mobile"}</option>
                  <option value={10}>{"Shipper Company Name"}</option>

                  <option value={11}>{"Source System"}</option>
                  <option value={12}>{"Airwaybill"}</option>
                  <option value={16}>{"Region"}</option>
                </select>
              </div>

              <div
                className="form-inline"
                style={{
                  marginTop: "20px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Condition:{" "}
                </p>
                <select
                  as="select"
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  name={`conditions[${index}].field_condition`}
                  ref={register()}
                >
                  {field_num === "4" ? (
                    <>
                      <option value="=">{"="}</option>
                      <option value="in">{"in"}</option>
                      <option value="<">{"<"}</option>
                      <option value=">">{">"}</option>
                      <option value="<=">{"<="}</option>
                      <option value=">=">{">="}</option>
                    </>
                  ) : (
                    <>
                      <option value="=">{"="}</option>
                      <option value="in">{"in"}</option>
                      <option value="like">{"like"}</option>
                      <option value="not like">{"not like"}</option>
                    </>
                  )}
                </select>
              </div>

              <div
                className="form-inline"
                style={{
                  marginTop: "20px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Value:{" "}
                </p>

                {field_num === "16" ||
                field_num === "5" ||
                field_num === "6" ||
                field_num === "11" ? (
                  <div
                    style={{
                      height: "auto",
                      width: "530px",
                    }}
                  >
                    {field_num === "5" ? (
                      <Controller
                        as={
                          <Select
                            isMulti={field_condition === "in" ? true : false}
                            options={receiverCountry}
                          />
                        }
                        control={control}
                        name={`conditions[${index}].field_value`}
                        defaultValue={
                          receiverCountryValue !== undefined
                            ? receiverCountryValue
                            : ""
                        }
                      />
                    ) : (
                      ""
                    )}
                    {field_num === "6" ? (
                      <Controller
                        as={
                          <Select
                            isMulti={field_condition === "in" ? true : false}
                            options={shipmentType}
                          />
                        }
                        control={control}
                        name={`conditions[${index}].field_value`}
                        defaultValue={
                          shipmentTypeValue !== undefined
                            ? shipmentTypeValue
                            : ""
                        }
                      />
                    ) : (
                      ""
                    )}
                    {field_num === "11" ? (
                      <Controller
                        as={
                          <Select
                            isMulti={field_condition === "in" ? true : false}
                            options={sourceSystem}
                          />
                        }
                        control={control}
                        name={`conditions[${index}].field_value`}
                        defaultValue={
                          sourceSystemValue !== undefined
                            ? sourceSystemValue
                            : ""
                        }
                      />
                    ) : (
                      ""
                    )}
                    {field_num === "16" ? (
                      <Controller
                        as={
                          <Select
                            isMulti={field_condition === "in" ? true : false}
                            options={region}
                          />
                        }
                        control={control}
                        name={`conditions[${index}].field_value`}
                        defaultValue={
                          regionValue !== undefined ? regionValue : ""
                        }
                      />
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <input
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    name={`conditions[${index}].field_value`}
                    ref={register()}
                    defaultValue={item.field_value}
                  />
                )}
              </div>

              <div
                style={{
                  height: "1px",
                  width: "90%",
                  border: "1px solid rgba(235, 237, 242, 1)",
                  marginTop: "20px",
                }}
              />
            </div>
          );
        })}
        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <div
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
              marginBottom: "80px",
            }}
          ></div>
          <button
            className="btn btn-secondary"
            type="button"
            onClick={handleSubmit(handlePreview)}
          >
            Preview
          </button>
          <button
            className="btn btn-success"
            type="button"
            style={{ marginLeft: "11px" }}
            onClick={() => {
              append();
            }}
          >
            Add Condition
          </button>

          {previews !== undefined ? (
            <>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                keyField="id"
                pagination={paginationFactory()}
                data={previews === undefined ? [] : previews}
                columns={columns}
              >
                <PleaseWaitMessage entities={previews} />
                <NoRecordsFoundMessage entities={previews} />
              </BootstrapTable>
            </>
          ) : null}
        </div>
      </>
    );
  };

  return (
    <>
      <Card>
        <CardHeader title="Create PointCal"></CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                Name:{" "}
              </p>
              <input
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                onChange={(name) => setCalName(name.target.value)}
              />
            </div>

            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                Description:{" "}
              </p>

              <input
                style={{
                  height: "38px",
                  width: "530px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                onChange={(name) => setCalDesc(name.target.value)}
              />
            </div>

            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                On top:{" "}
              </p>
              <Switch
                checked={state.isOnTop}
                onChange={handleChangeSwitch("isOnTop")}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>

            {!state.isOnTop ? (
              <>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    Rate baht : 1 point{" "}
                  </p>

                  <input
                    type="number"
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    step="any"
                    onChange={(name) => setRateBaht(name.target.value)}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    On top value:{" "}
                  </p>
                  <input
                    type="number"
                    style={{
                      height: "38px",
                      width: "530px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    onChange={(name) => setOnTopValue(name.target.value)}
                  />
                </div>
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    Is One Time:{" "}
                  </p>
                  <Switch
                    checked={state.isOneTime}
                    onChange={handleChangeSwitch("isOneTime")}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </div>
              </>
            )}

            {fields.length > 1 ? (
              <div className="form-inline" style={{ marginBottom: "25px" }}>
                <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                  Operator:{" "}
                </p>

                <select
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  onChange={(operator) => setCalOperator(operator.target.value)}
                  defaultValue={"NULL"}
                >
                  <option disabled value="NULL">
                    Please Select ...
                  </option>
                  <option value={"AND"}>AND</option>
                  <option value={"OR"}>OR</option>
                </select>
              </div>
            ) : (
              ""
            )}
            {addConditions()}

            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                Start Date:{" "}
              </p>
              <TextField
                id="datestart"
                type="date"
                defaultValue="yyyy-mm-dd"
                style={{
                  height: "38px",
                  width: "185px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(name) => setStartDate(name.target.value)}
              />

              <p
                style={{
                  marginTop: "15px",
                  marginLeft: "100px",
                  float: "left",
                  width: "6%",
                }}
              >
                End Date:{" "}
              </p>
              <TextField
                id="dateend"
                type="date"
                defaultValue="yyyy-mm-dd"
                style={{
                  height: "38px",
                  width: "185px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(name) => setEndDate(name.target.value)}
              />
            </div>
            <hr></hr>
            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                Active Point Calculate:{" "}
              </p>
              <Switch
                checked={state.isActive}
                onChange={handleChangeSwitch("isActive")}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>

            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <div
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              ></div>
              <Link to="/iExpress/PointCal">
                <button type="button" className="btn btn-secondary wbt">
                  Cancel
                </button>
              </Link>
              <button className="btn btn-primary">Save</button>
            </div>
          </form>
        </CardBody>
      </Card>
    </>
  );
};
