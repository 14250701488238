import React, { useState, useRef } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import JoditEditor from "./JoditEditor";
import { Link } from "react-router-dom";
import "./component.css";
export const CreateVoucherTemplate = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Create Voucher Template");
  const [state, setState] = useState({
    is_active: false,
  });
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const history = useHistory();
  const [costs, setCosts] = useState([{ amount: 0, qty: 0, expire: 0 }]);

  const AlertError = (message) => {
    Swal.fire("Voucher Template!", message, "error");
  };

  const handleSend = async () => {
    var check_null = false;
    if (name === undefined || name === "") {
      return AlertError("Please insert template name !!");
    }
    if (description === undefined || description === "") {
      return AlertError("Please insert description !!");
    }
    if (costs.length < 0) {
      return AlertError("Please insert voucher details !!");
    }

    costs.map((data, index) => {
      if (data.amount <= 0) {
        check_null = true;
        return AlertError(
          "Please insert voucher details amount must be greater than 0. !!"
        );
      } else if (data.qty <= 0) {
        check_null = true;
        return AlertError(
          "Please insert voucher details quantity must be greater than 0. !!"
        );
      } else if (data.expire <= 0) {
        check_null = true;
        return AlertError(
          "Please insert voucher details expire must be greater than 0. !!"
        );
      }
    });

    var events = {
      name: name,
      description: description,
      is_active: state.is_active ? true : false,
      voucherDetails: costs,
    };
    if (!check_null) {
      axios
        .post(`${baseURL}/voucherTemplate`, events)
        .then(async (res) => {
          Swal.fire("Save !", "Save voucher template success", "success");
          history.goBack();
        })
        .catch((err) => {
          Swal.fire(
            "Created !",
            "Your can not Create Voucher Template. !! " + err,
            "error"
          );
        });
    }
  };

  const handleCostsChange = (event) => {
    const _tempCosts = [...costs];
    _tempCosts[event.target.dataset.id][event.target.name] = event.target.value;

    setCosts(_tempCosts);
  };

  const addNewCost = () => {
    setCosts((prevCosts) => [...prevCosts, { amount: 0, qty: 0, expire: 0 }]);
  };

  const RemoveFields = (index) => {
    const values = [...costs];
    values.splice(index, 1);
    setCosts(values);
  };

  const handleChangeSwitch = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  // const getTotalCosts = () => {
  //   return costs.reduce((total, item) => {
  //     return total + Number(item.price);
  //   }, 0);
  // };

  return (
    <Card>
      <CardHeader title="Create Voucher Template"></CardHeader>
      <CardBody>
        <div className="container">
          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Template Name:{" "}
            </p>
            <input
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(name) => setName(name.target.value)}
            />
          </div>
          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Description:{" "}
            </p>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              style={{
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(description) =>
                setDescription(description.target.value)
              }
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Active :{" "}
            </p>
            <Switch
              checked={state.is_active}
              onChange={handleChangeSwitch("is_active")}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>

          <div>
            <div
              className="table"
              style={{ width: "650px", marginLeft: "20%" }}
            >
              {/* <div className="table-title">Voucher</div> */}
              <div className="table-content">
                <div className="table-header">
                  <div className="table-row">
                    <div className="table-data">
                      <div>Voucher Amount</div>
                    </div>
                    <div className="table-data">
                      <div>Quantity</div>
                    </div>
                    <div className="table-data">
                      <div>Expire (Month)</div>
                    </div>
                  </div>
                </div>
                <div className="table-body">
                  {costs
                    ? costs.map((item, index) => (
                      <div className="table-row mt-1" key={index}>
                        <div className="table-data">
                          <select
                            name="amount"
                            data-id={index}
                            type="number"
                            value={item.amount}
                            onChange={handleCostsChange}
                            style={{
                              backgroundColor: "#F4F6F9",
                              borderRadius: "5px",
                              borderWidth: "0",
                              width:"120px"
                            }}
                          >
                            <option value={0}>Select amount</option>
                            <option value={100}>100</option>
                            <option value={300}>300</option>
                            <option value={500}>500</option>
                          </select>
                        </div>
                        <div className="table-data">
                          <input
                            name="qty"
                            data-id={index}
                            type="number"
                            value={item.qty}
                            onChange={handleCostsChange}
                            style={{
                              backgroundColor: "#F4F6F9",
                              borderRadius: "5px",
                              borderWidth: "0",
                            }}
                          />
                        </div>
                        <div className="table-data">
                          <input
                            name="expire"
                            data-id={index}
                            type="number"
                            value={item.expire}
                            onChange={handleCostsChange}
                            style={{
                              backgroundColor: "#F4F6F9",
                              borderRadius: "5px",
                              borderWidth: "0",
                            }}
                          />
                        </div>
                        <button
                          className="btn btn-danger"
                          type="button"
                          onClick={() => RemoveFields(index)}
                        >
                          -
                          </button>
                      </div>
                    ))
                    : null}
                  <div className="table-row">
                    <div className="table-data text-center">
                      <button className="btn btn-primary" onClick={addNewCost}>
                        +
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className="table-footer">
                  <div className="table-row">
                    <div className="table-data">
                      <div>Total</div>
                    </div>
                    <div className="table-data">
                      <div>{getTotalCosts()}</div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/DHLtoyou/VoucherTemplate">
              <button type="button" className="btn btn-secondary wbt">
                Cancel
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => handleSend()}>
              Save
            </button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
