import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeaderToolbar, CardHeader } from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import Swal from "sweetalert2";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import { baseURL } from "../service/API.js";
import moment from "moment";
import paginationFactory from "react-bootstrap-table2-paginator";
import xlsxFile from "read-excel-file";
import Pagination2 from "@material-ui/lab/Pagination";
import "react-pagination-library/build/css/index.css";
import Modal from "@material-ui/core/Modal";

import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export const CustomerProfileSite = () => {
  const suhbeader = useSubheader();
  const history = useHistory();
  const [batchData, setBatchData] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const [state, setState] = useState({
    imageId: null,
    imageName: "",
    imageURL: "",
  });
  const [progress, setProgress] = useState(0);
  const [id, setId] = useState("");

  const [perPage, setPerPage] = useState(10);
  const [lastPage, setLastPage] = useState(0);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalSize, setTotalSize] = useState(100);

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [openDetailModalData, setOpenDetailModalData] = useState([]);
  const [openDetailModalImport, setOpenDetailModalImport] = useState({});
  const [listData, setListData] = useState([]);
  const [dataDetail, setDataDetail] = useState({});

  const [type1, setType1] = useState(true);


  const [page2, setPage2] = useState(1);
  const [totalPage2, setTotalPage2] = useState(1);
  const [limit2, setLimit2] = useState(10);
  const [totalSize2, setTotalSize2] = useState(100);
  const [saved, setSaved] = useState();

  const [file, setFile] = useState();
  const [namefile, setNameFile] = useState();

  const [success, setSuccess] = useState([]);
  const [error, setError] = useState([]);

  suhbeader.setTitle("Customer Profile Site");
  useEffect(() => {
    getProfile(page, limit, search);
  }, []);

  const getProfile = async (page, limit, search) => {
    setPage(page);
    const allData = [];

    axios({
      method: "get",
      url: `${baseURL}/site?page=${page}&limit=${limit}${search !== undefined && search !== "" ? "&search=" + search : ""
        }`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
    }).then((res) => {
      res.data.data.map((data, index) => {
        data = {
          accounts: data.accounts,
          accounts_no: data.accounts[0].account_no,
          campaign_period: data.campaign_period,
          customer_email: data.customer_email,
          customer_name: data.customer_name,
          segment: data.segment,
          site_id: data.site_id,
          site_name: data.site_name,
          created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
          created_by: data.created_by,
          updated_at: moment(data.updated_at).format("YYYY-MM-DD HH:mm:ss"),
          updated_by: data.updated_by,
          id: data.id,
          target_revenue: data.target_revenue,
          transactionNo: data.transactionNo,
          year: data.year,
          limit: data.limit,
          siteCampaigns: data.siteCampaigns
        };
        allData.push(data);
      });
      var totalpage = Math.ceil(res.data.total / res.data.limit);
      setTotalPage(totalpage);
      setTotalSize(res.data.total);
      setLimit(res.data.limit);
      setBatchData(allData);
    });
  };

  const handleChangeUpload = async (e) => {
    setFile(null);
    setNameFile(null);
    setIsUpload(true);
    if (e.target.files.length) {
      e.preventDefault();
      let file = e.target.files[0];
      setFile(file);
      setNameFile(file.name);
    }
  };

  const save = async () => {
    setTimeout(() => {
      setProgress(25);
    }, 1000);
    const formData = new FormData();

    // Update the formData object
    formData.append("file", file, file.name);
    setTimeout(() => {
      setProgress(70);
    }, 1500);

    axios({
      method: "post",
      url: `${baseURL}/site/import`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
      data: formData,
    })
      .then((res) => {
        const allData = [];
        const errorData = [];
        const successData = [];
        console.log(res)
        res.data.data.map((data, index) => {
          data = {
            no: index + 1,
            accounts_no: data.account_no ? data.account_no : "",
            revenue: data.revenue ? data.revenue : "",
            campaign_period: data.campaign_period ? data.campaign_period : "",
            customer_email: data.customer_email ? data.customer_email : "",
            year: data.year ? data.year : "",
            segment: data.segment ? data.segment : "",
            site_id: data.site_id ? data.site_id : "",
            month: data.month ? data.month : "",
            created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
            id: data.id,
            target_revenueenue: data.target_revenueenue ? data.target_revenueenue : "",
            transactionNo: data.transactionNo ? data.transactionNo : "",
            year: data.year ? data.year : "",
            status: data.status ? data.status : "",
            errors: data.errors.length > 0 ? data.errors : [],
          };
          if (data.errors.length > 0) {
            errorData.push(data.errors);
          } else {
            successData.push([{ success: 1 }]);
          }
          allData.push(data);
        });

        let lastPage = allData.length / perPage;
        setLastPage(lastPage);
        setOpenDetailModalData(allData);
        setOpenDetailModalImport(res.data.import);
        setError(errorData);
        setSuccess(successData);
        setTimeout(() => {
          setProgress(100);
          setOpenDetailModal(true);
        }, 2000);
      })
      .catch((err) => {
        Swal.fire("Error", "Your can't upload file.", "error");
        setProgress(0);
        setIsUpload(false);
      });
  };

  var columns = [
    {
      dataField: "site_id",
      text: "Site Id",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "accounts_no",
      text: "Account No",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "site_name",
      text: "Site Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "customer_name",
      text: "Contract Person",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "customer_email",
      text: "Customer Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return <span style={{ width: "25px" }}>{row.customer_email}</span>;
      },
    },
    // {
    //   dataField: "segment",
    //   text: "Segment",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    // {
    //   dataField: "target_revenue",
    //   text: "Target revenue",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    // {
    //   dataField: "limit",
    //   text: "Limit",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    // {
    //   dataField: "campaign_period",
    //   text: "Campaign Period",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    // {
    //   dataField: "year",
    //   text: "Year",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    {
      dataField: "created_at",
      text: "Created date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return <span style={{ width: "25px" }}>{row.created_at}</span>;
      },
    },
    {
      dataField: "created_by",
      text: "Created by",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "updated_at",
      text: "Updated date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return <span style={{ width: "25px" }}>{row.updated_at}</span>;
      },
    },
    {
      dataField: "updated_by",
      text: "Updated by",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Action",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => editItem({ id, row, rowIndex })}
            >
              Edit
            </button>
          </div>
        );
      },
    },
  ];
  const editItem = (data) => {
    history.push("/DHLtoyou/EditCustomerProfileSite", data);
  };
  const [search, setSearch] = useState();
  const handleSearch = () => {
    setPage(1);
    getProfile(1, limit, search);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    getProfile(value, limit, search);
  };

  const handlePageSizeChange = (event) => {
    setLimit(event.target.value);
    getProfile(1, event.target.value, search);
  };

  const confirm = () => {
    let event = {
      transactionNo: openDetailModalImport.transactionNo,
    };
    axios({
      method: "post",
      url: `${baseURL}/site/import/approve`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
      data: event,
    })
      .then(async (res) => {
        await setProgress(0);
        await handleClose();
        await Swal.fire("Save!", "Your Save Customer Profile Site.", "success");
      })
      .catch((err) => {
        Swal.fire(
          "Error !",
          "Your can't save Customer Profile Site. !! " + err,
          "error"
        );
        setProgress(0);
      });
  };

  const columns_detail = [
    {
      dataField: "no",
      text: "No.",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "site_id",
      text: "Site Id",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: (colum, colIndex) => {
        return { width: '80px' };
      },
      formatter: (col, row) => {
        return <span style={{ display: 'block', width: 100, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          {col}
        </span>
      }
    },
    {
      dataField: "accounts_no",
      text: "Account No",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "transactionNo",
      text: "Transaction No",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "customer_email",
      text: "Customer Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return <span>{row.errors.length > 0 ? "Fail" : "Success"}</span>;
      },
    },
  ];

  const handleClose = () => {
    setId("");
    setOpenDetailModal(false);
    setOpenDetailModalData([]);
    setProgress(0);
  };

  return (
    <Card>
      <CardHeader title="Customer Profile Site">
        <CardHeaderToolbar>
          <Link to="/DHLtoyou/CreateCustomerProfileSite">
            <button type="button" className="btn btn-primary">
              + Create
            </button>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Link to={`/files/Update_Site_Detail_Template.xlsx`} target="_blank" download>
          <button className="btn btn-primary mt-3">
            Export Template Excel
              </button>
        </Link>
        <div className="form-inline">
          <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            Update Site Detail: <br />
          </p>
          <input
            name="file"
            style={{
              height: "38px",
              width: "330px",
              backgroundColor: "#F4F6F9",
              borderRadius: "5px",
              borderWidth: "0",
            }}
            disabled
            value={namefile}
          />

          <span className="btn btn-primary btn-file wbt">
            เลือก / แก้ไข
            <input type="file" onChange={handleChangeUpload} />
          </span>
        </div>
        {isUpload ? (
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ float: "left", width: "20%" }}> </p>
            {progress > 0 ? (
              <button className="btn btn-primary" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {"  "}Loading...
              </button>
            ) : (
              <button className="btn btn-primary mb-5" onClick={() => save()}>
                Upload
              </button>
            )}
          </div>
        ) : null}

        {progress > 0 ? (
          <div>
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped progress-bar-animatedprogress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                style={{ width: `${progress}%` }}
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {progress === 100 ? progress : `${progress} %`}
              </div>
            </div>
          </div>
        ) : null}

        <div className="row form-inline mb-2 mt-6">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <BootstrapTable
          keyField="id"
          data={batchData}
          columns={columns}
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
        />
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="mt-3">
                {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={limit}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <Pagination2
                className="mt-3 "
                count={totalPage}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </CardBody>

      <Modal
        open={openDetailModal}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <div className="modal-dialog-full-width modal-dialog momodel modal-fluid">
          <div className="modal-content">
            <div className="m-2">
              <span style={{ fontSize: "30px", textAlign: "center", margin: "20px" }}>
                Upload Data
                </span>
            </div>
            <div className="modal-body my_scroll_divs">
              {/* <Form className="col"> */}
              <BootstrapTable
                hover
                keyField="id"
                pagination={paginationFactory()}
                data={
                  openDetailModalData === undefined ? [] : openDetailModalData
                }
                columns={columns_detail}
              />

              <div className="card my_scroll_div" style={{ width: "100%" }}>
                <div className="card-body">
                  <h5 className="card-title">Log</h5>

                  {openDetailModalData.length > 0 ? (
                    openDetailModalData.map((data, index) => {
                      return data.errors ? (
                        data.errors.length > 0 ? (
                          data.errors.map((datas, indexs) => {
                            return (
                              <p className="card-text">
                                Row {index + 1} : {data.site_id} :{" "}
                                {datas.field} : {datas.message}
                              </p>
                            );
                          })
                        ) : (
                          <p className="card-text">
                            Row {index + 1} : {data.site_id} : Success
                          </p>
                        )
                      ) : null;
                    })
                  ) : (
                    <p className="card-text"></p>
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-between mt-2">
                <div className="mt-2">
                  {" "}
                  <span>Success: {success.length}</span>{" "}
                  <span>Error: {error.length}</span>{" "}
                  <span>Total: {openDetailModalData.length}</span>
                </div>
                <div
                  style={{
                    color: "#3b4fbc",
                    fontSize: "16px",
                    textAlign: "right",
                  }}
                >
                  {error.length > 0 ? (
                    <button
                      disabled
                      type="button"
                      className="btn btn-primary"
                    >
                      Confirm
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => confirm()}
                    >
                      Confirm
                    </button>
                  )}

                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => handleClose()}
                  >
                    Cancel
                    </button>
                </div>
              </div>
              {/* </Form> */}
            </div>
          </div>
        </div>
      </Modal>

    </Card>
  );
};
