import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import JoditEditor from "./JoditEditor";
import { Link } from "react-router-dom";
import "./component.css";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

export const EditCampaign = (props) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Edit Campaign");

  const [state, setState] = useState({
    is_active: false,
  });
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [revTarget, setRevTarget] = useState();
  const [selectedDateStart, handleDateChangeStart] = useState(null);
  const [selectedDateEnd, handleDateChangeEnd] = useState(null);
  const [selectedYear, handleDateChangeYear] = useState(null);

  const history = useHistory();

  useEffect(() => {

    setId(props.location.state.row.id);
    setName(props.location.state.row.name);
    setDescription(props.location.state.row.description);
    setRevTarget(props.location.state.row.rev_target);
    var start_cover = moment(`01/${props.location.state.row.start}`).format('DD-MM-YYYY HH:mm')
    var end_cover = moment(`01/${props.location.state.row.end}`).format('DD-MM-YYYY HH:mm')
    var year_cover = moment(`01/${props.location.state.row.start_month}/${props.location.state.row.year}`).format('DD-MM-YYYY HH:mm')
    handleDateChangeStart(start_cover);
    handleDateChangeEnd(end_cover);
    handleDateChangeYear(year_cover)
    setState({ is_active: props.location.state.row.active });
  }, []);

  const AlertError = (message) => {
    Swal.fire("Edit Campaign!", message, "error");
  };

  const handleSend = async () => {
    if (name === undefined || name === "") {
      return AlertError("Please insert name !!");
    }
    else if (description === undefined || description === "") {
      return AlertError("Please insert description !!");
    }
    else if (revTarget === undefined || revTarget === "") {
      return AlertError("Please insert rev target !!");
    } else if (
      revTarget && revTarget < 0) {
      return AlertError('Please insert at rev target more than 0 or 0!!')
    }
    else if (selectedDateStart === undefined || selectedDateStart === null || selectedDateStart === "") {
      return AlertError("Please insert start !!");
    }
    else if (selectedDateEnd === undefined || selectedDateEnd === null || selectedDateStart === "") {
      return AlertError("Please insert end !!");
    } else if (selectedYear === undefined || selectedYear === null || selectedYear === "") {
      return AlertError("Please insert year !!");
    }
    else if (Number(moment(selectedDateStart._d ? selectedDateStart._d : selectedDateStart).format("MM")) > Number(moment(selectedDateEnd._d ? selectedDateEnd._d : selectedDateEnd).format("MM"))) {
      return AlertError('End date must more than start date !!')
    }
    else {
      var events = {
        name: name,
        description: description,
        rev_target: revTarget,
        start_month: moment(selectedDateStart._d ? selectedDateStart._d : selectedDateStart).format("MM"),
        start_year: moment(selectedYear._d ? selectedYear._d : selectedYear).format("YYYY"),
        end_month: moment(selectedDateEnd._d ? selectedDateEnd._d : selectedDateEnd).format("MM"),
        end_year: moment(selectedYear._d ? selectedYear._d : selectedYear).format("YYYY"),
        year: moment(selectedYear._d ? selectedYear._d : selectedYear).format("YYYY"),
        is_active: state.is_active ? true : false,
      };
      console.log(events)
      axios
        .put(`${baseURL}/campaign/${id}`, events)
        .then(async (res) => {
          Swal.fire("Save !", "Save Edit Campaign success", "success");
          history.goBack();
        })
        .catch((err) => {
          Swal.fire(
            "Created !",
            "Your can not Edit Campaign. !! " + err,
            "error"
          );
        });
    }

  };

  const handleChangeSwitch = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  return (
    <Card>
      <CardHeader title="Edit Campaign"></CardHeader>
      <CardBody>
        <div className="container">
          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Name:{" "}
            </p>
            <input
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={name}
              onChange={(name) => setName(name.target.value)}
            />
          </div>
          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Description:{" "}
            </p>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              style={{
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={description}
              onChange={(description) =>
                setDescription(description.target.value)
              }
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Rev target:{" "}
            </p>
            <input
              type="number"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={revTarget}
              onChange={(rev_target) => setRevTarget(rev_target.target.value)}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Start :{" "}
            </p>
            <DatePicker
              autoOk
              clearable
              variant="inline"
              openTo="month"
              views={["month"]}
              label="Month"
              value={selectedDateStart}
              onChange={handleDateChangeStart}
              format="MM"
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              End :{" "}
            </p>
            <DatePicker
              autoOk
              clearable
              variant="inline"
              openTo="month"
              views={["month"]}
              label="Month"
              value={selectedDateEnd}
              onChange={handleDateChangeEnd}
              format="MM"
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Year :{" "}
            </p>

            <DatePicker
              autoOk
              clearable
              variant="inline"
              views={["year"]}
              label="Year only"
              value={selectedYear}
              onChange={handleDateChangeYear}
              format="YYYY"
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Active :{" "}
            </p>
            <Switch
              checked={state.is_active}
              value="state"
              onChange={handleChangeSwitch("is_active")}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/DHLtoyou/Campaign">
              <button type="button" className="btn btn-secondary wbt">
                Cancel
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => handleSend()}>
              Save
            </button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
