import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import { Link, useHistory } from "react-router-dom";
import { baseURL } from "../service/API.js";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination2 from "@material-ui/lab/Pagination";
import { useSelector } from "react-redux";

export const YearLimit = () => {
  const suhbeader = useSubheader();
  const allData = [];
  const [templateData, setTemplateData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalSize, setTotalSize] = useState(100);
  suhbeader.setTitle("Year Limit");
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  useEffect(() => {
    getLimit(page, limit, search);
  }, []);

  const getLimit = async (page, limit, search) => {
    setPage(page);
    axios({
      method: "get",
      url: `${baseURL}/limitPoint?page=${page}&limit=${limit}&is_delete=false${search !== undefined && search !== "" ? "&search=" + search : ""
        }`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
    }).then((res) => {
      if (res.message) {
        history.push("/logout");
      } else {
        res.data.data.map((data, index) => {
          data = {
            id: data.id,
            year: data.year,
            limit: data.limit,
            created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
          };
          allData.push(data);
        });
      }

      var totalpage = Math.ceil(res.data.total / res.data.limit);
      setTotalPage(totalpage);
      setTotalSize(res.data.total);
      setLimit(res.data.limit);
      setTemplateData(allData);
    });
  };

  const columns = [
    {
      dataField: "year",
      text: "Year",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "limit",
      text: "Limit",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "created_at",
      text: "Create Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Action",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => editItem({ id, row, rowIndex })}
            >
              Edit
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={() => deleteItem({ row, rowIndex })}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  const editItem = (data) => {
    history.push("/DHLtoyou/EditYearLimit", data);
  };

  const deleteItem = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this limit point !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${baseURL}/limitPoint/${data.row.id}`)
          .then((res) => {
            Swal.fire("Deleted!", "Your limit point has been deleted.", "success");
            getLimit(page, limit, search);
          })
          .catch((err) => {
            Swal.fire(
              "Deleted!",
              "Your limit point has been fail to deleted.",
              "error"
            );
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your limit point is safe :)", "error");
      }
    });
  };
  const [search, setSearch] = useState();
  const handleSearch = () => {
    setPage(1);
    getLimit(1, limit, search);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    getLimit(value, limit, search);
  };

  const handlePageSizeChange = (event) => {
    setLimit(event.target.value);
    getLimit(1, event.target.value, search);
  };

  return (
    <Card>
      <CardHeader title="Year Limit">
        <CardHeaderToolbar>
          <Link to="/DHLtoyou/CreateYearLimit">
            <button type="button" className="btn btn-primary">
              + Create
            </button>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div> */}

        <BootstrapTable
          keyField="id"
          data={templateData}
          columns={columns}
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
        />
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="mt-3">
                {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={limit}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <Pagination2
                className="mt-3 "
                count={totalPage}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
