import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import Swal from 'sweetalert2';
import {
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import axios from "axios";
import { Link } from "react-router-dom";
import { baseURL, } from "../service/API.js";
import { useHistory } from "react-router-dom";

import Pagination from "@material-ui/lab/Pagination";

export const UserProfile = () => {
  const suhbeader = useSubheader();
  const [userData, setUserData] = useState([]);
  suhbeader.setTitle("User Manangement");
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [is_loading, setIs_Loading] = useState(false)

  useEffect(() => {
    getList(1, 10);
  }, []);

  const getList = async (page, limit, search) => {
    setIs_Loading(true)
    fetch(`${baseURL}/user/list?page=${page}&limit=${limit}&is_delete=false${search !== undefined && search !== '' ? "&search=" + search : ''}`)
      .then((response) => response.json())
      .then((res) => {
        var totalpage = Math.ceil(res.total / res.limit);
        setTotalPage(totalpage);
        setLimit(res.limit);
        setIs_Loading(false)
        setUserData(res.data);
      });
  };

  const columns = [
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "username",
      text: "Username",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "fullname",
      text: "Fullname",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Active",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            {row.is_active ? "true" : "false"}
          </div>
        );
      },
    },
    {
      text: "Action:",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button type="button" className="btn btn-primary mr-2" onClick={() => goEdit({ id, row, rowIndex })}>
              Edit
            </button>

            <button type="button" className="btn btn-primary" onClick={() => deleteItem({ row, rowIndex })}>
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  const goEdit = (data) => {
    history.push("/iExpress/EditUserProfile", data);
  };

  const [search, setSearch] = useState();

  const handleSearch = () => {
    setPage(1);
    setLimit(10);
    getList(1, 10, search);
  }

  const handlePageChange = (event, value) => {
    setPage(value);
    getList(value, limit);
  };

  const handlePageSizeChange = (event) => {
    setLimit(event.target.value);
    setPage(1);
    getList(1, event.target.value);
  };

  const deleteItem = (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this user manangement !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        axios.delete(`${baseURL}/user/remove/${data.row.id}`)
          .then((res) => {
            Swal.fire(
              'Deleted!',
              'Your user manangement has been deleted.',
              'success'
            )
            getList();
          })
          .catch((err) => {
            Swal.fire(
              'Deleted!',
              'Your user manangement has been fail to deleted.',
              'error'
            )
          })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your user manangement is safe :)',
          'error'
        )
      }
    })
  };
  return (
    <Card>
      <CardHeader title="User Manangement">
        <CardHeaderToolbar>
          <Link to="/iExpress/CreateUserProfile">
            <button type="button" className="btn btn-primary">
              + Create
            </button>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>



        <div className="row">
          {
            is_loading ? <div className="row">
              <div style={{
                position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
              }}>

                <div className="spinner-grow text-primary spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-secondary spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-success spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-danger spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-warning spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-info spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-light spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-dark spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
              :
              <>
                <BootstrapTable
                  keyField="id"
                  data={userData}
                  columns={columns}
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                />
                <div className=" col-lg-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mt-3">
                        {"Items per Page: "}
                        <select onChange={handlePageSizeChange} value={limit}>
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <Pagination
                        className="mt-3 "
                        count={totalPage}
                        page={page}
                        siblingCount={1}
                        boundaryCount={1}
                        variant="outlined"
                        shape="rounded"
                        onChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </>}
        </div>

      </CardBody>
    </Card>
  );
};
