import React, { useState, useRef } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import JoditEditorEmailNew from "./JoditEditorEmailNew";
import JoditEditorEmailNewComing from "./JoditEditorEmailNewComing";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const CreateEmailTemplateNewAccount = () => {
  const suhbeader = useSubheader();
  const { user } = useSelector((state) => state.auth);

  suhbeader.setTitle("Create Email Template New Account");
  const [state, setState] = useState({
    is_active: false,
  });
  const [is_newComing, setIs_newComing] = useState(true);
  const [is_additional, setIs_additional] = useState(false);
  const [is_sagment, setIs_sagment] = useState(false);
  const [templateName, setTemplateName] = useState();
  const [templateSubject, setTemplateSubject] = useState();
  const [templateMessage, setTemplateMessage] = useState("<p></p>");
  const history = useHistory();

  const AlertError = (message) => {
    Swal.fire("Email Template!", message, "error");
  };

  const handleMessageChange = (message) => {
    setTemplateMessage(message);
  };
  const handleSend = async () => {
    if (templateName === undefined || templateName === "") {
      return AlertError("Please insert name email template !!");
    }
    if (templateSubject === undefined || templateSubject === "") {
      return AlertError("Please insert subject email template !!");
    }
    if (templateMessage === undefined || templateMessage === "") {
      return AlertError("Please insert message email template !!");
    }

    var events = {
      is_newComing: is_newComing,
      is_additional: is_additional,
      is_segment: is_sagment,
      template_name: templateName,
      template_subject: templateSubject,
      template_message: templateMessage,
      is_active: state.is_active ? true : false,
      created_by_id: 1,
    };

    axios({
      method: "post",
      url: `${baseURL}/emailTemplate`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
      data: events,
    })
      .then((res) => {
        Swal.fire("Save !", "Save email template success", "success");
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Created !",
          "Your can not create email Template New Account. !! " + err,
          "error"
        );
      });
  };
  const handleChangeSwitch = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  const typeCheck = (even) => {
    if (even === "is_newComing") {
      setIs_newComing(true);
      setIs_additional(false);
      setIs_sagment(false);
    } else if (even === "is_additional") {
      setIs_newComing(false);
      setIs_additional(true);
      setIs_sagment(false);
    } else {
      setIs_newComing(false);
      setIs_additional(false);
      setIs_sagment(true);
    }
  };

  return (
    <Card>
      <CardHeader title="Create Email Template New Account"></CardHeader>
      <CardBody>
        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <p
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          >
            Type:{" "}
          </p>
          <select
            as="select"
            style={{
              height: "38px",
              width: "530px",
              backgroundColor: "#F4F6F9",
              borderRadius: "5px",
              borderWidth: "0",
            }}
            onChange={(type) => typeCheck(type.target.value)}
            defaultValue={"is_newComing"}
          >
            <option value="is_newComing">New Coming</option>
            <option value="is_additional">Additional</option>
            <option value="is_sagment">Segment</option>
          </select>
        </div>

        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <p
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          >
            Template Name:{" "}
          </p>
          <input
            style={{
              height: "38px",
              width: "530px",
              backgroundColor: "#F4F6F9",
              borderRadius: "5px",
              borderWidth: "0",
            }}
            onChange={(name) => setTemplateName(name.target.value)}
          />
        </div>

        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <p
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          >
            Template Subject:{" "}
          </p>
          <input
            style={{
              height: "38px",
              width: "530px",
              backgroundColor: "#F4F6F9",
              borderRadius: "5px",
              borderWidth: "0",
            }}
            onChange={(name) => setTemplateSubject(name.target.value)}
          />
        </div>
        {is_newComing ? (
          <div>
            <JoditEditorEmailNewComing messageChange={handleMessageChange} />
          </div>
        ) : (
          <div>
            <JoditEditorEmailNew messageChange={handleMessageChange} />
          </div>
        )}

        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        ></div>
        <hr></hr>
        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <p
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          >
            Active Email Template:{" "}
          </p>
          <Switch
            checked={state.is_active}
            onChange={handleChangeSwitch("is_active")}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </div>

        <div
          className="form-inline"
          style={{
            marginBottom: "25px",
          }}
        >
          <div
            style={{
              marginTop: "15px",
              float: "left",
              width: "20%",
            }}
          ></div>
          <Link to="/DHLtoyou/EmailTemplateNewAccount">
            <button type="button" className="btn btn-secondary wbt">
              Cancel
            </button>
          </Link>
          <button className="btn btn-primary" onClick={() => handleSend()}>
            Save
          </button>
        </div>
      </CardBody>
    </Card>
  );
};
export default CreateEmailTemplateNewAccount;
