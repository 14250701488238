import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader } from "../../_metronic/_partials/controls";
import { baseURL } from "../service/API.js";
import { useSubheader } from "../../_metronic/layout";
import { useSelector } from "react-redux";
import "react-pagination-library/build/css/index.css";
import BootstrapTable from "react-bootstrap-table-next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Pagination2 from "@material-ui/lab/Pagination";
import { sortCaret, headerSortingClasses } from "../../_metronic/_helpers";
import Swal from "sweetalert2";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  inputColor: {
    backgroundColor: "#F4F6F9",
    borderRadius: "5px",
    borderWidth: "0",
  },
}));

export const SendEmail = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Send Email");
  const { user } = useSelector((state) => state.auth);

  const classes = useStyles();
  const [setEmailData_new, setEmailDataNew] = useState([]);
  const [setEmailData_add, setEmailDataAdd] = useState([]);
  const [setEmailData_seg, setEmailDataSeg] = useState([]);

  const [emailData_id, setEmailData_id] = useState();
  const [voucherData, setVoucherData] = useState([]);
  const [voucherData_id, setVoucherData_id] = useState();

  const [segmentData, setSegmentData] = useState([]);
  const [segmentData_id, setSegmentData_id] = useState();

  const [listData, setListData] = useState([]);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalSize, setTotalSize] = useState(100);

  const [type, setType] = useState("new_coming");
  const [status, setStatus] = useState("pending");
  const [rowSelect, setRowSelect] = useState([]);
  const [table, setTable] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [emailData_Masa, setEmailData_Masa] = useState();
  const [emailData_Name, setEmailData_Name] = useState();
  const [segmentData_id_list, setSegmentData_id_list] = useState();

  const AlertError = (message) => {
    Swal.fire("Sent Email!", message, "error");
  };

  useEffect(() => {
    getSegment();
    getEmailT(type);
    getVoucherTemplate();
    getList(type, status, page, limit, search);

  }, []);

  const getEmailT = (type) => {
    let data_new = [];
    let data_add = [];
    let data_seg = [];
    let allDataEmailData_new = [];
    let allDataEmailData_add = [];
    let allDataEmailData_seg = [];
    axios
      .get(`${baseURL}/emailTemplate/all?is_newComing=${type === "new_coming" ? true : false}&is_additional=${type === "additional" ? true : false}&is_segment=${type === "segment" ? true : false}&is_delete=false&page=1&limit=100000`)
      .then((res) => {
        res.data.map((data, index) => {
          if (data.is_newComing) {
            data_new = {
              id: data.id,
              template_name: data.template_name,
              template_message: data.template_message,
            };
            allDataEmailData_new.push(data_new);
          } else if (data.is_additional) {
            data_add = {
              id: data.id,
              template_name: data.template_name,
              template_message: data.template_message,
            };
            allDataEmailData_add.push(data_add);
          } else {
            data_seg = {
              id: data.id,
              template_name: data.template_name,
              template_message: data.template_message,
            };
            allDataEmailData_seg.push(data_seg);
          }
        });
        setEmailDataNew(allDataEmailData_new);
        setEmailDataAdd(allDataEmailData_add);
        setEmailDataSeg(allDataEmailData_seg);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getVoucherTemplate = () => {
    let data_vou = {};
    let allDataVoucherData = [];
    axios
      .get(`${baseURL}/voucherTemplate?page=1&limit=100000`)
      .then((res) => {
        res.data.data.map((data, index) => {
          data_vou = {
            id: data.id,
            name: data.name,
          };

          allDataVoucherData.push(data_vou);
        });
        setVoucherData(allDataVoucherData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSegment = async () => {
    var allData = []
    axios({
      method: "get",
      url: `${baseURL}/segment`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
    }).then((res) => {
      allData.push({
        id: null,
        name: "All"
      });

      res.data.map((data, index) => {
        data = {
          id: data.id,
          name: data.name,
        };
        allData.push(data);
      });
      setSegmentData(allData);
    })
      .catch((err) => {
        console.log(err);
      })
  };

  const getEmailTem = (id) => {
    try {
      let data = setEmailData_add.find(t => Number(t.id) === Number(id))
      return setEmailData_add.find(t => Number(t.id) === Number(id)).template_name
    } catch (error) {
      return ""
    }
  }

  const getList = (type, status, page, limit, search, isNewBind = false, segmentData_id_list) => {
    setRowSelect([]);
    setListData([]);
    handleOnSelectAll(false, { page: 1 });
    if (isNewBind) {
      try {
        table.selectionContext.selected = []
      } catch (error) {

      }
    }
    const allDataListData = [];
    setPage(page);
    axios
      .get(
        `${baseURL}/${type === "new_coming" ? "site" : type === "additional" ? `additional/group` : "toyou/sendmail/segment"
        }?page=${page}&limit=${limit}${type === "new_coming" ? "&isNewComing=true&newComingStatus=" : type === "additional" ? "&additionalStatus=" : ""
        }${type === "segment" ? "" : status === "pending" ? "0" : status === "canceled" ? "1" : "2"}${segmentData_id_list ? "&segment=" : ""}${type === "segment" ? segmentData_id_list ? segmentData_id_list : "" : ""}${search !== undefined && search !== "" ? "&search=" + search : ""
        }`
      )
      .then((res) => {
        res.data.data.map((data, index) => {
          let datas = {}
          if (type === "new_coming") {
            datas = {
              id: data.id,
              transactionNo: data.transactionNo ? data.transactionNo : "-",
              site_id: data.site_id ? data.site_id : "-",
              siteId: data.siteId ? data.siteId : "-",
              mailTemplate: data.toYouSendMail ? data.mailTemplate.emailTemplateId : "-",
              voucherTemplate: data.voucherTemplate ? data.voucherTemplate.name : "-",
              fileName: data.import ? data.import.originalname : "-",
              site_name: data.site_name ? data.site_name : "-",
              customer_email: data.customer_email ? data.customer_email : "-",
              created_at: data.created_at ? moment(data.created_at).format("YYYY-MM-DD HH:mm:ss") : "-",
              send_date: data.sendDateNewComing
                ? moment(data.sendDateNewComing).format("YYYY-MM-DD HH:mm:ss")
                : "-",
              status_new: data.newComingStatus === 0
                ? "pending"
                : data.newComingStatus === 1
                  ? "cancel"
                  : "sent",
              status_add: data.additionalStatus === 0
                ? "pending"
                : data.additionalStatus === 1
                  ? "cancel"
                  : "sent",
            };
          } else if (type === "additional") {
            datas = {
              id: data.id,
              transactionNo: data.transactionNo ? data.transactionNo : "-",
              site_id: data.site_id ? data.site_id : "-",
              siteId: data.siteId ? data.siteId : "-",
              mailTemplate: data.mailTemplate ? data.mailTemplate.template_name : "-",
              voucherTemplate: data.voucherTemplate ? data.voucherTemplate.name : "-",
              fileName: data.import ? data.import.originalname : "-",
              site_name: data.site.site_name ? data.site.site_name : "-",
              customer_email: data.site.customer_email ? data.site.customer_email : "-",
              created_at: data.site.created_at ? moment(data.site.created_at).format("YYYY-MM-DD HH:mm:ss") : "-",
              send_date: data.sendDateAdditional
                ? moment(data.sendDateAdditional).format("YYYY-MM-DD HH:mm:ss")
                : "-",
              status_new: data.additionalStatus === 0
                ? "pending"
                : data.additionalStatus === 1
                  ? "cancel"
                  : "sent",
              status_add: data.additionalStatus === 0
                ? "pending"
                : data.additionalStatus === 1
                  ? "cancel"
                  : "sent",
            };
          } else if (type === "segment") {
            datas = {
              id: data.id,
              transactionNo: data.transactionNo ? data.transactionNo : "-",
              site_id: data.site.site_id ? data.site.site_id : "-",
              siteId: data.siteId ? data.siteId : "-",
              mailTemplate: data.mailTemplate ? data.mailTemplate.template_name : "-",
              voucherTemplate: data.voucherTemplate ? data.voucherTemplate.name : "-",
              fileName: data.import ? data.import.originalname : "-",
              site_name: data.site.site_name ? data.site.site_name : "-",
              customer_email: data.email ? data.email : "-",
              created_at: data.created_at ? moment(data.created_at).format("YYYY-MM-DD HH:mm:ss") : "-",
              send_date: data.site.sendDateNewComing
                ? moment(data.site.sendDateNewComing).format("YYYY-MM-DD HH:mm:ss")
                : "-",
              status_new: "sent",
              status_add: "sent",
            };
          }
          allDataListData.push(datas);
        });
        var totalpage = Math.ceil(res.data.total / res.data.limit);
        setTotalPage(totalpage);
        setTotalSize(res.data.total);
        setLimit(res.data.limit);

        setListData(allDataListData);
        getVoucherTemplate();

      })
      .catch((err) => {
        console.log(err);
      });
  };



  const handleChangeEmailTemplate = (event, values) => {
    if (values) {
      setEmailData_id(values.id);
      setEmailData_Masa(values.template_message);
      setEmailData_Name(values.template_name)
    } else {
      setEmailData_id("");
    }
  };

  const handleChangeVoucherTemplate = (event, values) => {
    if (values) {
      setVoucherData_id(values.id);
    } else {
      setVoucherData_id("");
    }
  };


  const handleChangeSegment = (event, values) => {
    if (values) {
      setSegmentData_id(values.id);
    } else {
      setSegmentData_id("");
    }
  };

  const handleChangeSegmentSelect = (event) => {
    if (event) {
      setSegmentData_id_list(event);
      getList("segment", status, 1, 10, search, false, Number(event));

    } else {
      setSegmentData_id_list("");
    }
  };


  const [search, setSearch] = useState();
  const handleSearch = () => {
    setPage(1);
    getList(type, status, 1, limit, search);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    getList(type, status, value, limit, search);
  };

  const handlePageSizeChange = (event) => {
    setLimit(event.target.value);
    getList(type, status, 1, event.target.value, search);
  };

  const handleChangeType = (name) => {
    if (name === "new_coming") {
      setListData([]);
      setRowSelect([]);
      setType("new_coming");
      setPage(1);
      getList("new_coming", status, 1, limit, search);
      getEmailT("new_coming");
    } else if (name === "additional") {
      setListData([]);
      setRowSelect([]);
      setType("additional");
      setPage(1);
      getList("additional", status, 1, limit, search);
      getEmailT("additional");
    } else if (name === "segment") {
      setListData([]);
      setRowSelect([]);
      setType("segment");
      setPage(1);
      getList("segment", status, 1, limit, search);
      getEmailT("segment");
    } else {
      setRowSelect([]);
      setType(null);
    }
  };

  const handleChangeStatus = (name) => {
    if (name === "pending") {
      setListData([]);
      setRowSelect([]);
      getList(type, "pending", 1, limit, search, true);
      setStatus("pending");
      setPage(1);
    } else if (name === "sent") {
      setListData([]);
      setRowSelect([]);
      getList(type, "sent", 1, limit, search, true);
      setStatus("sent");
      setPage(1);
    } else if (name === "canceled") {
      setListData([]);
      setRowSelect([]);
      getList(type, "canceled", 1, limit, search, true);
      setStatus("canceled");
      setPage(1);
    }
  };

  const send = async () => {
    if (!emailData_id && type === "new_coming") {
      return AlertError("Please select email template !!");
    }
    if (!voucherData_id && type === "new_coming") {
      return AlertError("Please select voucher template !!");
    }

    if (!emailData_id && type === "additional") {
      return AlertError("Please select email template !!");
    }

    if (!emailData_id && type === "segment") {
      return AlertError("Please select email template  !!");
    }
    if (!segmentData_id && type === "segment") {
      return AlertError("Please select segment !!");
    }

    if (rowSelect.length === 0 && type !== "segment") {
      return AlertError("Please select data in table !!");
    }

    var all_new_coming = [];
    var all_additional = [];
    if (rowSelect.length > 0 && type !== "segment") {
      await rowSelect.map((data) => {
        data = {
          siteId: data.id,
          transactionNo: data.transactionNo,
          email: data.customer_email,
        };
        all_new_coming.push(data);
      });

      await rowSelect.map((data) => {
        data = {
          siteId: data.siteId,
          transactionNo: data.transactionNo,
          email: data.customer_email,
        };
        all_additional.push(data);
      });
    }
    var send = {};

    if (type === "new_coming") {
      send = {
        type: 0,
        emailTemplateId: emailData_id,
        voucherTemplateId: voucherData_id,
        details: all_new_coming,
      };
      axios({
        method: "post",
        url: `${baseURL}/toyou/sendMail/newComing`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.data.access_token}`,
        },
        data: send,
      })
        .then((res) => {
          Swal.fire("Send !", "Send email success", "success");
          getList(type, status, 1, limit, search, true);
        })
        .catch((err) => {
          Swal.fire("Send !", "Your can not send email. !! " + err, "error");
        });
    }

    if (type === "additional") {
      send = {
        type: 1,
        emailTemplateId: emailData_id,
        details: all_additional,
      };

      axios({
        method: "post",
        url: `${baseURL}/toyou/sendMail/additional`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.data.access_token}`,
        },
        data: send,
      })
        .then((res) => {
          Swal.fire("Send !", "Send email success", "success");
          getList(type, status, 1, limit, search, true);
        })
        .catch((err) => {
          Swal.fire("Send !", "Your can not send email. !! " + err, "error");
        });
    }
    if (type === "segment") {
      send = {
        type: 2,
        emailTemplateId: emailData_id,
        segmentId: segmentData_id,
      };

      axios({
        method: "post",
        url: `${baseURL}/toyou/sendMail/segment`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.data.access_token}`,
        },
        data: send,
      })
        .then((res) => {
          Swal.fire("Send !", "Send email success", "success");
          getList(type, status, 1, limit, search, true);
        })
        .catch((err) => {
          Swal.fire("Send !", "Your can not send email. !! " + err, "error");
        });
    }
  };

  const resendAddNew = async () => {

    if (rowSelect.length === 0) {
      return AlertError("Please select data in table !!");
    }

    var all_new_coming = [];
    var all_additional = [];

    if (rowSelect.length > 0 && type !== "segment") {
      await rowSelect.map((data) => {
        all_new_coming.push(data.id);
      });

      await rowSelect.map((data) => {
        all_additional.push({ siteId: Number(data.siteId), transactionNo: Number(data.transactionNo) });
      });
    }
    var send = {};

    if (type === "new_coming") {
      send = {
        siteIds: all_new_coming,
      };
      axios({
        method: "post",
        url: `${baseURL}/toyou/sendMail/newComing/resend`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.data.access_token}`,
        },
        data: send,
      })
        .then((res) => {
          Swal.fire("Send !", "Send email success", "success");
          getList(type, status, 1, limit, search, true);
        })
        .catch((err) => {
          Swal.fire("Send !", "Your can not send email. !! " + err, "error");
        });
    }

    if (type === "additional") {
      send = {
        additions: all_additional,
      };

      axios({
        method: "post",
        url: `${baseURL}/toyou/sendMail/additional/resend`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.data.access_token}`,
        },
        data: send,
      })
        .then((res) => {
          Swal.fire("Send !", "Send email success", "success");
          getList(type, status, 1, limit, search, true);
        })
        .catch((err) => {
          Swal.fire("Send !", "Your can not send email. !! " + err, "error");
        });
    }
  };

  const resend = async () => {

    if (rowSelect.length === 0) {
      return AlertError("Please select data in table !!");
    }

    var all_segment = [];
    if (rowSelect.length > 0) {
      await rowSelect.map((data) => {
        all_segment.push(data.id);
      });
    }

    if (type === "segment") {

      axios({
        method: "post",
        url: `${baseURL}/toyou/sendMail/segment/resend`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.data.access_token}`,
        },
        data: {
          sendmaildetailId: all_segment
        },
      })
        .then((res) => {
          Swal.fire("Send !", "Send email success", "success");
          getList(type, status, 1, limit, search, true);
        })
        .catch((err) => {
          Swal.fire("Send !", "Your can not send email. !! " + err, "error");
        });
    }
  };

  const canceled = async () => {
    if (rowSelect.length === 0) {
      return AlertError("Please select data in table !!");
    } else {
    }

    let alls_new = [];
    await rowSelect.map((data) => {
      alls_new.push(data.id);
    });
    let alls = [];
    await rowSelect.map((data) => {
      alls.push({ siteId: data.siteId, transactionNo: data.transactionNo });
    });

    let send = {};

    if (type === "new_coming") {
      send = { newComingStatus: 1, siteId: alls_new };
      axios({
        method: "post",
        url: `${baseURL}/site/updateStatus`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.data.access_token}`,
        },
        data: send,
      })
        .then((res) => {
          Swal.fire("Canceled !", "canceled email success", "success");
          setRowSelect([]);
          setListData([]);
          getList(type, status, 1, limit, search);
        })
        .catch((err) => {
          Swal.fire(
            "Canceled !",
            "Your can not canceled email. !! " + err,
            "error"
          );
        });
    } else {
      send = { additionalStatus: 1, items: alls };
      axios({
        method: "post",
        url: `${baseURL}/additional/updateStatus`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.data.access_token}`,
        },
        data: send,
      })
        .then((res) => {
          Swal.fire("Canceled !", "canceled email success", "success");
          setRowSelect([]);
          setListData([]);
          getList(type, status, 1, limit, search);
        })
        .catch((err) => {
          Swal.fire(
            "Canceled !",
            "Your can not canceled email. !! " + err,
            "error"
          );
        });
    }
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setRowSelect([
        ...rowSelect,
        {
          id: row.id,
          siteId: row.siteId,
          site_id: row.site_id,
          transactionNo: row.transactionNo,
          customer_email: row.customer_email,
          page: page,
        },
      ]);
    } else {
      let check = rowSelect.filter((x) => x.id !== row.id);
      setRowSelect(check);
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      let all = [];
      rows.map((r) =>
        all.push({
          id: r.id,
          site_id: r.site_id,
          siteId: r.siteId,
          transactionNo: r.transactionNo,
          customer_email: r.customer_email,
          page: page,
        })
      );
      all.push(...rowSelect);
      setRowSelect(all);
    } else {
      let result = rowSelect.filter((row) => row.page !== page);
      setRowSelect(result);
    }
  };
  const columns = [
    {
      dataField: "site_id",
      text: "Site Id",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "site_name",
      text: "Site Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "customer_email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "created_at",
      text: "Upload Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "send_date",
      text: "Send Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Status",
      dataField: "status",
      sort: true,
      formatter: (id, row, rowIndex) => {
        return (
          <span>{type === "new_coming" ? row.status_new : row.status_add}</span>
        );
      },
    },
    {
      text: "Email Template",
      dataField: "mailTemplate",
      sort: true,
      formatter: (id, row, rowIndex) => {
        return (
          <span>{row.mailTemplate}</span>
        );
      },
    },
    {
      text: "Voucher Template",
      dataField: "voucherTemplate",
      sort: true,
      formatter: (id, row, rowIndex) => {
        return (
          <span>{row.voucherTemplate}</span>
        );
      },
    },
    {
      text: "File name",
      dataField: "fileName",
      sort: true,
      formatter: (id, row, rowIndex) => {
        return (
          <span>{row.fileName}</span>
        );
      },
    },
  ];

  const preview = () => {
    if (!emailData_id && type === "new_coming") {
      return AlertError("Please select email template !!");
    }

    setOpenPreview(true);
  }

  const handleClose = () => {
    setOpenPreview(false);
  };


  return (
    <>
      <Card>
        <CardHeader title="Send Email"></CardHeader>
        <CardBody>
          <div>
            <div className="container">
              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "10%",
                  }}
                >
                  Type:{" "}
                </p>
                <select
                  as="select"
                  style={{
                    height: "38px",
                    width: "45%",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  onChange={(type) => handleChangeType(type.target.value)}
                  defaultValue={"new_coming"}
                >
                  <option value="new_coming">New coming</option>
                  <option value="additional">Additional</option>
                  <option value="segment">Segment</option>
                </select>
              </div>

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "10%",
                  }}
                >
                  Email template:{" "}
                </p>

                {type === "new_coming" ? (
                  setEmailData_new.length > 0 ? (
                    <Autocomplete
                      id="emailtemplate"
                      options={setEmailData_new}
                      getOptionLabel={(option) => option.template_name}
                      style={{
                        height: "38px",
                        width: "20%",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      onChange={handleChangeEmailTemplate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={classes.inputColor}
                          style={{
                            borderWidth: 0,
                          }}
                          label="Email template"
                        />
                      )}
                    />
                  ) : null
                ) : null}

                {type === "additional" ?
                  setEmailData_add.length > 0 ? (
                    <Autocomplete
                      id="emailtemplate"
                      options={setEmailData_add}
                      getOptionLabel={(option) => option.template_name}
                      style={{
                        height: "38px",
                        width: "20%",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      onChange={handleChangeEmailTemplate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={classes.inputColor}
                          style={{
                            borderWidth: 0,
                          }}
                          label="Email template"
                        />
                      )}
                    />
                  ) : null : null}

                {type === "segment" ?
                  setEmailData_seg.length > 0 ? (
                    <Autocomplete
                      id="emailtemplate"
                      options={setEmailData_seg}
                      getOptionLabel={(option) => option.template_name}
                      style={{
                        height: "38px",
                        width: "20%",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      onChange={handleChangeEmailTemplate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={classes.inputColor}
                          style={{
                            borderWidth: 0,
                          }}
                          label="Email template"
                        />
                      )}
                    />
                  ) : null : null}

                {type === "new_coming" ? (
                  <>
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      Voucher template:
                  </p>
                    {voucherData.length > 0 ? (
                      <Autocomplete
                        id="VoucherTemplate"
                        options={voucherData}
                        getOptionLabel={(option) => option.name}
                        style={{
                          height: "38px",
                          width: "20%",
                          backgroundColor: "#F4F6F9",
                          borderRadius: "5px",
                          borderWidth: "0",
                        }}
                        onChange={handleChangeVoucherTemplate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={classes.inputColor}
                            style={{
                              borderWidth: 0,
                            }}
                            label="Voucher template"
                          />
                        )}
                      />
                    ) : null}
                  </>
                ) : null}

                {type === "segment" ? (
                  <>
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      By Segment :
                  </p>
                    {segmentData.length > 0 ? (
                      <Autocomplete
                        id="Segment"
                        options={segmentData}
                        getOptionLabel={(option) => option.name}
                        style={{
                          height: "38px",
                          width: "20%",
                          backgroundColor: "#F4F6F9",
                          borderRadius: "5px",
                          borderWidth: "0",
                        }}
                        onChange={handleChangeSegment}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={classes.inputColor}
                            style={{
                              borderWidth: 0,
                            }}
                            label="Segment"
                          />
                        )}
                      />
                    ) : null}
                  </>
                ) : null}

                <div>
                  <button
                    type="button"
                    className="btn btn-primary m-3"
                    onClick={() => preview()}
                  >
                    Preview
                </button>
                </div>
              </div>
              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <div
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "10%",
                  }}
                ></div>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => send()}
                >
                  Send
                </button>
                <button
                  type="button"
                  className="btn btn-primary m-3"
                  onClick={() => canceled()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

        </CardBody>
      </Card>

      <Card>

        <CardHeader title="Send Email Transaction"></CardHeader>
        <CardBody>
          <div className="container">
            <div className="form-inline">
              {type === "segment" ? null :
                <>
                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "10%",
                    }}
                  >
                    Status:{" "}
                  </p>
                  <select
                    as="select"
                    style={{
                      height: "38px",
                      width: "30%",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                      marginRight: "10px"

                    }}
                    onChange={(type) => handleChangeStatus(type.target.value)}
                    defaultValue={"pending"}
                  >
                    <option value="pending">Pending</option>
                    <option value="sent">Sent</option>
                    <option value="canceled">Canceled</option>
                  </select>
                </>
              }

              {type === "segment" ? (
                <div>
                  <p
                    style={{
                      marginTop: "10px",
                      float: "left",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  >
                    By Segment :
                  </p>
                  <select
                    as="select"
                    style={{
                      height: "38px",
                      width: "250px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                      marginRight: "10px"

                    }}
                    onChange={(name) => handleChangeSegmentSelect(name.target.value)}
                  >
                    {segmentData.map((data, key) => {
                      return (
                        <option value={data.id}>{data.name}</option>
                      )
                    })}
                  </select>
                </div>

              ) : null}

            </div>
          </div>

          <div className="container mt-5">
            <div className="col">


              <div className="row">
                <div className="col-sm-10">
                  <div className="row form-inline mb-2">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Text Search"
                          style={{ width: "100%" }}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2">
                      <div className="form-group">
                        <button
                          type="button"
                          id="button"
                          className="btn btn-primary"
                          style={{ width: "100%" }}
                          onClick={() => handleSearch()}
                        >
                          Search
                    </button>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2">

                  <div className="d-flex justify-content-between">
                    <a style={{ color: "#000", fontSize: "16px" }}></a>
                    <div style={{ color: "#3b4fbc", fontSize: "16px", textAlign: "right" }}>
                      {status === "sent" || type === "segment" ?
                        < button
                          type="button"
                          id="button"
                          className="btn btn-primary mb-1"
                          onClick={type === "segment" ? () => resend() : () => resendAddNew()}
                        >
                          Resend
                    </button>
                        : null
                      }
                    </div>
                  </div>
                </div>
              </div>

              <BootstrapTable
                ref={n => setTable(n)}
                key="id"
                keyField="id"
                data={listData}
                columns={columns}
                selectRow={{
                  mode: "checkbox",
                  clickToSelect: true,
                  selected: rowSelect.id,
                  onSelect: handleOnSelect,
                  onSelectAll: handleOnSelectAll,
                }}
              />

              <div className=" col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mt-3">
                      {"Items per Page: "}
                      <select onChange={handlePageSizeChange} value={limit}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Pagination2
                      className="mt-3 "
                      count={totalPage}
                      page={page}
                      siblingCount={1}
                      boundaryCount={1}
                      variant="outlined"
                      shape="rounded"
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>



          <Modal
            className="my_scroll_divs"
            open={openPreview}
            disablePortal
            disableEnforceFocus
            disableAutoFocus
          >
            <div className="modal-dialog-full-width modal-dialog momodel modal-fluid">
              <div className="modal-content">
                <div className="justify-content-center d-flex align-items-center mt-1">
                  <span style={{ fontSize: "30px" }}></span>
                </div>
                <div className="modal-body">
                  <div className="card">
                    <div className="card-header">
                      {emailData_Name}
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">{emailData_Name}</h5>
                      <span
                        style={{ width: "100%" }}
                        dangerouslySetInnerHTML={{
                          __html:
                            emailData_Masa
                        }}
                      />
                    </div>
                  </div>
                  {/* <Form className="col"> */}
                  <div className="d-flex justify-content-between mt-2">
                    <div className="mt-2">
                      {" "}
                    </div>
                    <div
                      style={{
                        color: "#3b4fbc",
                        fontSize: "16px",
                        textAlign: "right",
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => handleClose()}
                      >
                        Close
                    </button>
                    </div>
                  </div>
                  {/* </Form> */}
                </div>
              </div>
            </div>
          </Modal>

        </CardBody>
      </Card>
    </>
  );
};
