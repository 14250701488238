import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { baseURL } from "../../service/API.js";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from "@material-ui/core/Modal";

import "./component.css";

export const EditUserProfile = (props) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Edit User Manangement");
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [username, setUsername] = useState("");
  const [fullname, setFullname] = useState("");
  const [openCheck, setOpenCheck] = useState(false);

  const [state, setState] = useState(false);

  useEffect(() => {
    setEmail(props.location.state.row.email);
    setUsername(props.location.state.row.username);
    setFullname(props.location.state.row.fullname);
    setState(props.location.state.row.is_active ? true : false);
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    await setSubmitting(false);
    await editUser(values);
    return;
  };

  const handleSubmitPassword = async (values, { setSubmitting }) => {
    await setSubmitting(false);
    await editPassword(values);
    return;
  };

  const handleChangeSwitch = () => {
    setState(!state);
  };

  const editUser = async (values) => {
    let data = {
      email: values.email,
      username: values.username,
      fullname: values.full_name,
      is_active: state,
    };

    if (data.email && data.username && data.fullname) {
      axios
        .put(`${baseURL}/user/edit/${props.location.state.row.id}`, data)
        .then((res) => {
          history.goBack();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const editPassword = async (values) => {

    let data = {
      passwordCer: values.password,
      passwordNew: values.newpassword,
    };

    if (data.passwordCer && data.passwordNew) {
      axios
        .put(
          `${baseURL}/user/editPassword/${props.location.state.row.id}`,
          data
        )
        .then((res) => {
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          alert("Password don't match");
        });
    }
  };

  const ChangePassword = () => {
    setState(!state);
  };

  const handleClose = () => {
    setOpenCheck(false);
  };
  return (
    <>
      <Card>
        <CardHeader title="Edit User Manangement"></CardHeader>
        <CardBody>
          <Formik
            initialValues={{
              email: props.location.state.row.email,
              username: props.location.state.row.username,
              password: props.location.state.row.password,
              full_name: props.location.state.row.fullname,
            }}
            validate={(values) => {
              let errors = {};

              if (!values["email"]) {
                errors["email"] = "Please enter your email Address.";
              }

              if (typeof values["email"] !== "undefined") {
                var pattern = new RegExp(
                  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
                );
                if (!pattern.test(values["email"])) {
                  errors["email"] = "Please enter valid email address.";
                }
              }

              if (!values.username) errors.username = "Username is required.";

              if (!values.password) errors.password = "Password is required.";

              if (!values.full_name)
                errors.full_name = "Full name is required.";

              //check if my values have errors
              return errors;
            }}
            onSubmit={handleSubmit}
            render={(formProps) => {
              return (
                <Form>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      Email :{" "}
                    </p>
                    <Field
                      type="text"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                    />
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></p>
                    <ErrorMessage className="colorError" name="email">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      Username :{" "}
                    </p>
                    <Field
                      type="text"
                      name="username"
                      className="form-control"
                      placeholder="Username"
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                    />
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></p>
                    <ErrorMessage className="colorError" name="username">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      Password :{" "}
                    </p>

                    <a
                      style={{
                        textDecoration: "#007BFF underline",
                        color: "#007BFF",
                      }}
                      data-toggle="modal"
                      data-target="#modal-EditPassword"
                      onClick={() => setOpenCheck(true)}
                    >
                      Change Password
                    </a>
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></p>
                    <ErrorMessage className="colorError" name="password">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      Full name :{" "}
                    </p>
                    <Field
                      type="text"
                      name="full_name"
                      className="form-control"
                      placeholder="Full name"
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                    />
                  </div>
                  <div className="form-inline">
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    ></p>
                    <ErrorMessage className="colorError" name="full_name">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="form-inline" style={{ marginBottom: "25px" }}>
                    <p
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      Active:{" "}
                    </p>
                    <Switch
                      checked={state}
                      onChange={() => handleChangeSwitch()}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <CardHeaderToolbar>
                  <div
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              ></div>
                    <Link to="/iExpress/UserProfile">
                      <button type="button" className="btn btn-secondary wbt">
                        Cancel
                      </button>
                    </Link>
                    <button
                      className="btn btn-primary wbt"
                      type="submit"
                      disabled={formProps.isSubmitting}
                    >
                      Save
                    </button>
                  </CardHeaderToolbar>
                </Form>
              );
            }}
          />
        </CardBody>

        <Modal
          open={openCheck}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="justify-content-center d-flex align-items-center mt-1">
                <span style={{ fontSize: "30px" }}>Change Password</span>
              </div>
              <div className="modal-body">
                <Formik
                  initialValues={{
                    password: "",
                    newpassword: "",
                  }}
                  validate={(values) => {
                    let errors = {};

                    if (!values.password)
                      errors.password = "Password is required.";

                    if (!values.newpassword)
                      errors.newpassword = "New password is required.";

                    //check if my values have errors
                    return errors;
                  }}
                  onSubmit={handleSubmitPassword}
                  render={(formProps) => {
                    return (
                      <Form className="col">
                        <div className="row">
                          <span
                            style={{
                              fontSize: "20px",
                              float: "left",
                              width: "40%",
                            }}
                          >
                            Password :
                          </span>

                          <Field
                            type="password"
                            name="password"
                            className="form-control"
                            placeholder="Password"
                            style={{
                              height: "38px",
                              width: "530px",
                              backgroundColor: "#F4F6F9",
                              borderRadius: "5px",
                              borderWidth: "0",
                            }}
                          />
                        </div>
                        <div className="form-inline">
                          <ErrorMessage className="colorError" name="password">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>

                        <div className="row mt-1">
                          <span
                            style={{
                              fontSize: "20px",
                              float: "left",
                              width: "40%",
                            }}
                          >
                            New Password :{" "}
                          </span>
                          <Field
                            type="password"
                            name="newpassword"
                            className="form-control"
                            placeholder="Password"
                            style={{
                              height: "38px",
                              width: "530px",
                              backgroundColor: "#F4F6F9",
                              borderRadius: "5px",
                              borderWidth: "0",
                            }}
                          />
                        </div>

                        <div className="form-inline">
                          <ErrorMessage
                            className="colorError"
                            name="newpassword"
                          >
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>

                        <div className="modal-footer">
                          <button
                            className="btn btn-danger"
                            type="submit"
                            disabled={formProps.isSubmitting}
                          >
                            Confirm
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => handleClose()}
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      </Card>
    </>
  );
};
