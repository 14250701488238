import React, { useState, useRef } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import JoditEditor from "./JoditEditor";
import { Link } from "react-router-dom";
import "./component.css";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

export const CreateYearLimit = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Create Year Limit");
  const [state, setState] = useState({
    is_active: false,
  });
  const [year, setYear] = useState();
  const [limit, setLimit] = useState();

  const history = useHistory();

  const AlertError = (message) => {
    Swal.fire("Create Year Limit!", message, "error");
  };

  const handleSend = async () => {
    if (year === undefined || year === "") {
      return AlertError("Please insert year !!");
    }
    else if (limit === undefined || limit === "") {
      return AlertError("Please insert limit !!");
    } else {
      var events = {
        year: year,
        limit: limit,
      };
      axios
        .post(`${baseURL}/limitPoint`, events)
        .then(async (res) => {
          Swal.fire("Save !", "Save Create Year Limit success", "success");
          history.goBack();
        })
        .catch((err) => {
          Swal.fire(
            "Created !",
            "Your can not Create Year Limit. !! " + err,
            "error"
          );
        });
    }

  };

  return (
    <Card>
      <CardHeader title="Create Year Limit"></CardHeader>
      <CardBody>
        <div className="container">
          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Year:{" "}
            </p>
            <input
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(year) => setYear(year.target.value)}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Limit:{" "}
            </p>
            <input
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(limit) => setLimit(limit.target.value)}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/DHLtoyou/YearLimit">
              <button type="button" className="btn btn-secondary wbt">
                Cancel
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => handleSend()}>
              Save
            </button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
