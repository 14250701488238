/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../_metronic/_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Components */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">MANAGE</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/iExpress",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/iExpress">
            <span className="menu-text">iExpress</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className="menu-item  menu-item-parent active"
                aria-haspopup="true"
              >
                <span className="menu-link">
                  <span className="menu-text">iExpress</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/iExpress/TargetedGroup") || getMenuItemActive("/iExpress/CreateTargetedGroup") || getMenuItemActive("/iExpress/EditTargetedGroup")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/iExpress/TargetedGroup">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Targeted Group</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/iExpress/EmailTemplate") || getMenuItemActive("/iExpress/CreateEmailTemplate") || getMenuItemActive("/iExpress/EditEmailTemplate")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/iExpress/EmailTemplate">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Email Template</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/iExpress/EmailList") ||
                  getMenuItemActive("/iExpress/CreateSendEmail") ||
                  getMenuItemActive("/iExpress/EditSendEmail")
                  }`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/iExpress/EmailList">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Email List</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/iExpress/PointCal") ||
                  getMenuItemActive("/iExpress/CreatePointCal") ||
                  getMenuItemActive("/iExpress/EditPointCal")
                  }`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/iExpress/PointCal">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Point Calculate</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/iExpress/PrivilegeSetting") ||
                  getMenuItemActive("/iExpress/CreatePrivilegeSetting") ||
                  getMenuItemActive("/iExpress/EditPrivilegeSetting")
                  }`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/iExpress/PrivilegeSetting">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Privilege Setting</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/iExpress/UserProfile") ||
                  getMenuItemActive("/iExpress/CreateUserProfile") ||
                  getMenuItemActive("/iExpress/EditUserProfile")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/iExpress/UserProfile">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">User Manangement</span>
                </NavLink>
              </li>

              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/iExpress/CustomerProfile")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/iExpress/CustomerProfile">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Customer Profile</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/iExpress/Banner")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/iExpress/Banner">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Banner</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/iExpress/UpdateRewardsStatus"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/iExpress/UpdateRewardsStatus">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Update Rewards Status</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/iExpress/Report")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/iExpress/Report">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Report</span>
                </NavLink>
              </li>

              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*begin::1 Level*/}

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/DHLtoyou",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/DHLtoyou">
            <span className="menu-text">DHLtoyou</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">DHLtoyou</span>
                </span>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/DHLtoyou/UploadDataNewAccount"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/DHLtoyou/UploadDataNewAccount">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Upload Data New Account</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/DHLtoyou/Banner"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/DHLtoyou/Banner">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Banner</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/DHLtoyou/SendEmail")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/DHLtoyou/SendEmail">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Send Email</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/DHLtoyou/SendEmailHistory"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/DHLtoyou/SendEmailHistory">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Send Email History</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/DHLtoyou/EmailTemplateNewAccount") ||
                  getMenuItemActive("/DHLtoyou/CreateEmailTemplateNewAccount") ||
                  getMenuItemActive("/DHLtoyou/EditEmailTemplateNewAccount")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/DHLtoyou/EmailTemplateNewAccount">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Email Template New Account</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/DHLtoyou/VoucherTemplate") ||
                  getMenuItemActive("/DHLtoyou/CreateVoucherTemplate") ||
                  getMenuItemActive("/DHLtoyou/EditVoucherTemplate")
                  }`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/DHLtoyou/VoucherTemplate">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Voucher Template</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/DHLtoyou/CustomerProfileSite") ||
                  getMenuItemActive("/DHLtoyou/CreateCustomerProfileSite") ||
                  getMenuItemActive("/DHLtoyou/EditCustomerProfileSite") ||
                  getMenuItemActive("/DHLtoyou/AccountNo") ||
                  getMenuItemActive("/DHLtoyou/CreateAccountNo") ||
                  getMenuItemActive("/DHLtoyou/EditAccountNo")
                  }`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/DHLtoyou/CustomerProfileSite">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Customer Profile Site</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/DHLtoyou/SpecialCampaign"
                ) ||
                  getMenuItemActive("/DHLtoyou/CreateSpecialCampaign") ||
                  getMenuItemActive("/DHLtoyou/EditSpecialCampaign")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/DHLtoyou/SpecialCampaign">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Special Campaign</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/DHLtoyou/Campaign"
                ) ||
                  getMenuItemActive("/DHLtoyou/CreateCampaign") ||
                  getMenuItemActive("/DHLtoyou/EditCampaign")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/DHLtoyou/Campaign">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Campaign</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/DHLtoyou/UploadTransaction"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/DHLtoyou/UploadTransaction">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Upload Transaction</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/DHLtoyou/UpdateRewardsStatus"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/DHLtoyou/UpdateRewardsStatus">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Update Rewards Status</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/DHLtoyou/PrivilegeSettingToYou"
                ) ||
                  getMenuItemActive("/DHLtoyou/EditPrivilegeSettingToYou") ||
                  getMenuItemActive("/DHLtoyou/CreatePrivilegeSettingToYou") ||
                  getMenuItemActive("/DHLtoyou/ViewPrivilegeSettingToYou")

                  }`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/DHLtoyou/PrivilegeSettingToYou">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Privilege Setting</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/DHLtoyou/Topic"
                ) ||
                getMenuItemActive("/DHLtoyou/CreateTopic") ||
                getMenuItemActive("/DHLtoyou/EditTopic")
              }`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/DHLtoyou/Topic">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Tag Topic</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/DHLtoyou/YearLimit"
                ) ||
                getMenuItemActive("/DHLtoyou/EditYearLimit") ||
                getMenuItemActive("/DHLtoyou/CreateYearLimit")
              }`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/DHLtoyou/YearLimit">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Year limit</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/DHLtoyou/PrivilegeGroup"
                ) ||
                getMenuItemActive("/DHLtoyou/CreatePrivilegeGroup") ||
                getMenuItemActive("/DHLtoyou/EditPrivilegeGroup")
              }`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/DHLtoyou/PrivilegeGroup">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Privilege Group</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/DHLtoyou/Report")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/DHLtoyou/Report">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Report</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
