import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";

import Pagination from "@material-ui/lab/Pagination";

import { useSubheader } from "../../_metronic/layout";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import Swal from 'sweetalert2';
import { baseURL } from "../service/API.js";

export const TargetedGroup = () => {
  const suhbeader = useSubheader();
  const allData = [];
  const [targetData, setTargetData] = useState([]);
  suhbeader.setTitle("Targeted Group");
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [is_loading, setIs_Loading] = useState(false)

  useEffect(() => {
    getTargetGroup(1, 10);
  }, []);

  const getTargetGroup = (page, limit, search) => {
    setIs_Loading(true)
    axios.get(`${baseURL}/targetGroup?page=${page}&limit=${limit}&is_delete=false${search !== undefined && search !== '' ? "&search=" + search : ''}`)
      .then((res) => {
        res.data.data.map((data, index) => {
          data = {
            id: data.id,
            group_name: data.group_name,
            group_desc: data.group_desc,
            group_operator: data.group_operator,
            all_day: data.all_day,
            date_from: data.date_from !== null ? moment(data.date_from).format('YYYY-MM-DD') : null,
            date_to: data.date_to !== null ? moment(data.date_to).format('YYYY-MM-DD') : null,
            created_at: moment(data.created_at).format('YYYY-MM-DD HH:mm:ss'),
            is_active: data.is_active,
            active: data.is_active,
            status: data.is_active,
            group_id: data.id
          };
          allData.push(data);
        });
        var totalpage = Math.ceil(res.data.total / res.data.limit);
        setTotalPage(totalpage);
        setLimit(res.data.limit);
        setTargetData(allData);
        setIs_Loading(false)
      })
      .catch((err) => {
        console.log(err);
      })

  };

  const columns = [
    {
      dataField: "group_id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "group_name",
      text: "Group Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "group_desc",
      text: "Description",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "created_at",
      text: "Create date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "active",
      text: "Active",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Action",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button type="button" className="btn btn-primary mr-2" onClick={() => editItem({ id, row, rowIndex })}>
              Edit
            </button>

            <button type="button" className="btn btn-primary" onClick={() => deleteItem({ row, rowIndex })}>
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  const editItem = (data) => {
    history.push("/iExpress/EditTargetedGroup", data);
  };

  const deleteItem = (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this target group !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        axios.delete(`${baseURL}/targetGroup/${data.row.id}`)
          .then((res) => {
            Swal.fire(
              'Deleted!',
              'Your target group has been deleted.',
              'success'
            )
            getTargetGroup();
          })
          .catch((err) => {
            Swal.fire(
              'Deleted!',
              'Your target group has been fail to deleted.',
              'error'
            )
          })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your target group is safe :)',
          'error'
        )
      }
    })
  };
  const [search, setSearch] = useState();
  const handleSearch = () => {
    setPage(1);
    setLimit(10);
    getTargetGroup(1, 10, search);
  }

  const handlePageChange = (event, value) => {
    setPage(value);
    getTargetGroup(value, limit);
  };

  const handlePageSizeChange = (event) => {
    setLimit(event.target.value);
    setPage(1);
    getTargetGroup(1, event.target.value);
  };

  return (
    <Card>
      <CardHeader title="Targeted Group">
        <CardHeaderToolbar>
          <Link to="/iExpress/CreateTargetedGroup">
            <button type="button" className="btn btn-primary">
              + Create
            </button>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          {
            is_loading ? <div className="row">
              <div style={{
                position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
              }}>

                <div className="spinner-grow text-primary spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-secondary spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-success spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-danger spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-warning spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-info spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-light spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-dark spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
              :
              <>
                <BootstrapTable
                  keyField="id"
                  data={targetData}
                  columns={columns}
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                />
                <div className=" col-lg-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mt-3">
                        {"Items per Page: "}
                        <select onChange={handlePageSizeChange} value={limit}>
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <Pagination
                        className="mt-3 "
                        count={totalPage}
                        page={page}
                        siblingCount={1}
                        boundaryCount={1}
                        variant="outlined"
                        shape="rounded"
                        onChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </>}
        </div>

      </CardBody>
    </Card>
  );
};
