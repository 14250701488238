import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import { Link, useHistory } from "react-router-dom";
import { baseURL } from "../service/API.js";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useSelector } from "react-redux";

export const AccountNo = (props) => {
  const suhbeader = useSubheader();
  const { user } = useSelector((state) => state.auth);

  const allData = [];
  const [accountData, setAccountData] = useState([]);

  const [accounts, setAccounts] = useState([]);
  const [id, setId] = useState();

  suhbeader.setTitle("Account No");
  const history = useHistory();

  useEffect(() => {
    setAccounts(props.location.state.accounts);
    setId(props.location.state.id)

    if (props.location.state.id) {
      getTemplate(props.location.state.id, search)
    }

  }, []);

  const getTemplate = async (id, search) => {
    axios({
      method: "get",
      url: `${baseURL}/site/${id}?page=1&limit=10000${search !== undefined && search !== "" ? "&search=" + search : ""
        }`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
    }).then((res) => {
      res.data.accounts.map((data, index) => {
        data = {
          id: data.id,
          siteId: res.id,
          account_no: data.account_no,
          created_at: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
          created_by: data.created_by,
          updated_at: moment(data.updated_at).format("YYYY-MM-DD HH:mm:ss"),
          updated_by: data.updated_by,
        };
        allData.push(data);
      });
      setAccountData(allData);
    });
  };

  const columns = [
    {
      dataField: "account_no",
      text: "Account No",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "created_at",
      text: "Created Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "created_by",
      text: "Created By",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "updated_at",
      text: "Updated Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "updated_by",
      text: "Updated By",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Action",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => editItem({ id, row, rowIndex })}
            >
              Edit
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={() => deleteItem({ row, rowIndex })}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  const createItem = (data) => {
    history.push("/DHLtoyou/CreateAccountNo", data);
  };

  const editItem = (data) => {
    history.push("/DHLtoyou/EditAccountNo", data);
  };

  const deleteItem = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this template !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${baseURL}/toyou/account/${data.row.id}`)
          .then(async (res) => {
            await Swal.fire(
              "Deleted !",
              "Your template has been deleted.",
              "success"
            );
            getTemplate(id, search);
          })
          .catch((err) => {
            console.log(err);

            Swal.fire(
              "Deleted !",
              "Your template has been fail to deleted.",
              "error"
            );
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your template is safe :)", "error");
      }
    });
  };
  const [search, setSearch] = useState();
  const handleSearch = () => {
    getTemplate(id, search);
  };

  const goBack = (data) => {
    history.goBack();
  };

  return (
    <Card>
      <CardHeader title={`Account No : ${props.location.state.site_id}`}>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary mr-2"
            onClick={() => createItem(props.location.state)}
          >
            Create
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          keyField="id"
          pagination={paginationFactory()}
          data={accountData === null ? [] : accountData}
          columns={columns}
        >
          <PleaseWaitMessage entities={accountData} />
          <NoRecordsFoundMessage entities={accountData} />
        </BootstrapTable>
      </CardBody>
      <div
        className="form-inline"
        style={{
          marginBottom: "25px",
        }}
      >
        <div
          style={{
            marginTop: "15px",
            float: "left",
            width: "20%",
          }}
        ></div>
        <button
          type="button"
          className="btn btn-secondary wbt"
          onClick={() => goBack()}
        >
          Back
        </button>
      </div>
    </Card>
  );
};
