import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import { useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import { Link, useHistory } from "react-router-dom";
import { baseURL } from "../service/API.js";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import Pagination2 from "@material-ui/lab/Pagination";
import Modal from "@material-ui/core/Modal";
import "./page.css";

export const SendEmailHistory = () => {
  const suhbeader = useSubheader();
  const { user } = useSelector((state) => state.auth);
  const [templateData, setTemplateData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalSize, setTotalSize] = useState(100);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [openDetailModalData, setOpenDetailModalData] = useState([]);
  const [id, setId] = useState("");

  const [templateData2, setTemplateData2] = useState([]);
  const [page2, setPage2] = useState(1);
  const [totalPage2, setTotalPage2] = useState(1);
  const [limit2, setLimit2] = useState(10);
  const [totalSize2, setTotalSize2] = useState(100);

  suhbeader.setTitle("Send Email History");
  const history = useHistory();
  useEffect(() => {
    getTemplate(1, 10);
  }, []);

  const getTemplate = async (page, limit, search) => {
    const allData = [];
    axios({
      method: "get",
      url: `${baseURL}/toyou/sendMail?page=${page}&limit=${limit}${search !== undefined && search !== "" ? "&search=" + search : ""
        }`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
    }).then((res) => {
      if (res.message) {
        history.push("/logout");
      } else {
        res.data.data.map((data, index) => {
          data = {
            id: data.id,
            voucher_template_name: data.voucherTemplate
              ? data.voucherTemplate.name
              : "",
            email_template_name: data.mailTemplate
              ? data.mailTemplate.template_name
              : "",
            totalSent: data.totalSent,
            totalRead: data.totalRead,
            sentDate: moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
          };
          allData.push(data);
        });
      }
      var totalpage = Math.ceil(res.data.total / res.data.limit);
      setTotalPage(totalpage);
      setTotalSize(res.data.total);
      setLimit(res.data.limit);
      setTemplateData(allData);
    });
  };

  const getDetailTemplate = async (id, page, limit, search) => {
    const allData2 = [];
    setPage(page);
    axios({
      method: "get",
      url: `${baseURL}/toyou/sendMail/${id}/details?page=${page}&limit=${limit}${search2 !== undefined && search2 !== "" ? "&search=" + search2 : ""}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
    }).then((res) => {
      res.data.data.map((data, index) => {
        data = {
          id: data.id,
          siteId: data.site ? data.site.site_id : " ",
          subject: data.subject,
          email: data.email,
          message: data.message,
          read_date: data.read_date
            ? moment(data.read_date).format("YYYY-MM-DD HH:mm:ss")
            : "",
          readed: data.readed ? "Open" : "Not Open",
          color: data.readed ? "green" : "red",
        };
        allData2.push(data);
      });
      var totalpage = Math.ceil(res.data.total / res.data.limit);
      setTotalPage2(totalpage);
      setTotalSize2(res.data.total);
      setLimit2(res.data.limit);
      setTemplateData2(allData2);
    });
  };

  const columns = [
    {
      dataField: "voucher_template_name",
      text: "Voucher Template Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "email_template_name",
      text: "Email Template Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "totalSent",
      text: "Total Send",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "totalRead",
      text: "Total Read",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "sentDate",
      text: "Sent Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "sentBy",
      text: "Sent By",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Detail",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setOpenDetail(id, row)}
            >
              Detail
            </button>
          </div>
        );
      },
    },
  ];

  const columns2 = [
    {
      dataField: "siteId",
      text: "Site id",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "subject",
      text: "Subject",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "readed",
      text: "Readed",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return <span style={{ color: row.color }}>{row.readed}</span>;
      },
    },
    {
      dataField: "read_date",
      text: "Read date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ];

  const [search, setSearch] = useState();
  const handleSearch = () => {
    setPage(1);
    getTemplate(1, limit, search);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    getTemplate(value, limit, search);
  };

  const handlePageSizeChange = (event) => {
    setLimit(event.target.value);
    getTemplate(1, event.target.value, search);
  };

  const handlePageChange2 = (event, value) => {
    setPage2(value);
    getDetailTemplate(id, value, limit);
  };

  const handlePageSizeChange2 = (event) => {
    setLimit2(event.target.value);
    getDetailTemplate(id, 1, event.target.value);
  };

  const setOpenDetail = (id, row) => {
    setOpenDetailModal(true);
    setOpenDetailModalData(row);
    setId(row.id);
    getDetailTemplate(row.id, 1, 10);
  };

  const handleClose = () => {
    setId("");
    setTemplateData2([]);
    setPage2(1);
    setTotalPage2(1);
    setLimit2(10);
    setTotalSize2(100);

    setOpenDetailModal(false);
    setOpenDetailModalData([]);
  };

  const [search2, setSearch2] = useState();
  const handleSearch2 = () => {
    setPage(1);
    getDetailTemplate(id, 1, limit2, search2);
  };

  return (
    <Card>
      <CardHeader title="Send Email History">
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <BootstrapTable
          keyField="id"
          data={templateData}
          columns={columns}
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
        />
        <div className=" col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="mt-3">
                {"Items per Page: "}
                <select onChange={handlePageSizeChange} value={limit}>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <Pagination2
                className="mt-3 "
                count={totalPage}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </CardBody>

      <Modal
        open={openDetailModal}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="justify-content-center d-flex align-items-center mt-1">
              <span style={{ fontSize: "30px" }}></span>
            </div>
            <div className="modal-body my_scroll_divs">
              {/* <Form className="col"> */}
                <div className="row form-inline mb-2">
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Text Search"
                        style={{ width: "100%" }}
                        onChange={(e) => setSearch2(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2">
                    <div className="form-group">
                      <button
                        type="button"
                        id="button"
                        className="btn btn-primary"
                        style={{ width: "100%" }}
                        onClick={() => handleSearch2()}
                      >
                        Search
                    </button>
                    </div>
                  </div>
                </div>
              <div className="row">

                <BootstrapTable
                  keyField="id"
                  data={templateData2}
                  columns={columns2}
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                />
                <div className=" col-lg-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mt-3">
                        {"Items per Page: "}
                        <select onChange={handlePageSizeChange2} value={limit2}>
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <Pagination2
                        className="mt-3 "
                        count={totalPage2}
                        page={page2}
                        siblingCount={1}
                        boundaryCount={1}
                        variant="outlined"
                        shape="rounded"
                        onChange={handlePageChange2}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => handleClose()}
                >
                  Cancel
                </button>
              </div>
              {/* </Form> */}
            </div>
          </div>
        </div>
      </Modal>
    </Card>
  );
};
