import { baseURL } from "../service/API.js";
const dhlURL = baseURL.split('/')[2]
export const FileUpload = async (file) => {
    let result = null;

    let formData = new FormData();
    formData.append('image', file);

    await fetch(`${baseURL}/upload`, {
        method: 'POST',
        body: formData
    })
        .then((response) => response.json())
        .then((data) => {
            result = data;
        })
        .catch((error) => {
            console.error('Error:', error);
        });

    var imageurl = `https://${dhlURL}/uploads/${result.filename}`
    return imageurl;
}


