import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL } from "../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import JoditEditor from "./JoditEditor";
import { Link } from "react-router-dom";
import "./component.css";
export const CreateAccountNo = (props) => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Create Account No");
  const [accountNo, setAccountNo] = useState();
  const history = useHistory();

  useEffect(() => {
  }, []);

  const AlertError = (message) => {
    Swal.fire("Create Account No!", message, "error");
  };

  const handleSend = async () => {
    if (accountNo === undefined || accountNo === "") {
      return AlertError("Please insert Account No!!");
    }
    var events = {
      siteId: props.location.state.id,
      account_no: accountNo,
    };

    axios
      .post(`${baseURL}/toyou/account`, events)
      .then(async (res) => {
        await Swal.fire("Save !", "Save Create Account No success", "success");

        await history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Created !",
          "Your can not Create Account No. !! " + err.response.data.message,
          "error"
        );
      });
  };

  const goBack = (data) => {
    history.goBack();
  };

  return (
    <Card>
      <CardHeader title="Create Account No"></CardHeader>
      <CardBody>
        <div className="container">
          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Account No:{" "}
            </p>
            <input
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(name) => setAccountNo(name.target.value)}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <button
              type="button"
              className="btn btn-secondary wbt"
              onClick={() => goBack()}
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={() => handleSend()}>
              Save
            </button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
