import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import { useSubheader } from "../../_metronic/layout";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { campaign_id, privilegeURL } from "../service/API.js";
import { useHistory } from "react-router-dom";

import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import moment from 'moment-timezone';
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));


export const PrivilegeSetting = () => {
  const suhbeader = useSubheader();
  const [privilage_setting, setprivilage_setting] = useState([]);
  const [total, setTotal] = useState(0);

  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [search, setSearch] = useState();

  suhbeader.setTitle("Privilege Setting");
  const history = useHistory();
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [is_loading, setIs_Loading] = useState(false)


  useEffect(() => {
    getList(1, 10);
  }, []);

  const getList = async (page, limit, search) => {
    setIs_Loading(true)
    fetch(
      `${privilegeURL}/dhl/deal?page=${page}&limit=${limit}&campaign_id=${campaign_id}
      ${search !== undefined && search !== '' ? "&search=" + search : ''}
      ${dateFrom !== undefined && dateFrom !== '' ? "&date_from=" + dateFrom : ''}
      ${dateTo !== undefined && dateTo !== '' ? "&date_to=" + dateTo : ''}`
    )
      .then((response) => response.json())
      .then((res) => {
        let allData = [];
        let allTotal = [];
        res.data.map((data, index) => {
          allData.push(data);
        });
        var totalpage = Math.ceil(res.total / res.perPage);
        setTotalPage(totalpage);
        setLimit(res.perPage);
        setprivilage_setting(allData);
        setIs_Loading(false)

      });
  };

  const columns = [
    {
      dataField: "deal_image",
      text: "Privilege Image",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <img
              src={row.deal_image}
              style={{ width: "50px", height: "50px" }}
            />
          </div>
        );
      },
    },
    {
      dataField: "deal_name",
      text: "Privilege Name",
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "deal_detail_short",
      text: "Redeem Confirm Message",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "350px" };
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Category",
      formatter: (id, row, rowIndex) => {
        return (
          <FormControl className={classes.formControl}>
            <div className={classes.chips}>
              {row.dealTags.map((data, index) => (
                <Chip
                  key={index}
                  label={data.tag ? data.tag.tag_name : null}
                  className={classes.chip}
                />
              ))}
            </div>
          </FormControl>
        );
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Start Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <p>
              {row.dealRules.length > 0
                ? moment.tz(row.dealRules[0].start_date, "Asia/Bangkok").format('YYYY-MM-DD HH:mm:ss')
                : null}
            </p>
          </div>
        );
      },
    },
    {
      text: "End Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <p>
              {row.dealRules.length > 0
                ? moment.tz(row.dealRules[0].end_date, "Asia/Bangkok").format('YYYY-MM-DD HH:mm:ss')
                : null}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "deal_active",
      text: "Active",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Action:",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => goEdit({ id, row, rowIndex })}
            >
              Edit
            </button>

          </div>
        );
      },
    },
  ];

  const goEdit = (data) => {
    history.push("/iExpress/EditPrivilegeSetting", data);
  };

  const handleSearch = () => {
    setPage(1);
    setLimit(10);
    getList(1, 10, search);
  }

  const handlePageChange = (event, value) => {
    setPage(value);
    getList(value, limit);
  };

  const handlePageSizeChange = (event) => {
    setLimit(event.target.value);
    setPage(1);
    getList(1, event.target.value);
  };

  return (
    <Card>
      <CardHeader title="Privilege Setting">
        <CardHeaderToolbar>
          <Link to="/iExpress/CreatePrivilegeSetting">
            <button type="button" className="btn btn-primary">
              + Create
            </button>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          {
            is_loading ? <div className="row">
              <div style={{
                position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
              }}>

                <div className="spinner-grow text-primary spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-secondary spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-success spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-danger spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-warning spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-info spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-light spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-dark spinner-grow-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
              :
              <>
                <BootstrapTable
                  keyField="id"
                  data={privilage_setting}
                  columns={columns}
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                />
                <div className=" col-lg-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mt-3">
                        {"Items per Page: "}
                        <select onChange={handlePageSizeChange} value={limit}>
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <Pagination
                        className="mt-3 "
                        count={totalPage}
                        page={page}
                        siblingCount={1}
                        boundaryCount={1}
                        variant="outlined"
                        shape="rounded"
                        onChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </>}
        </div>

      </CardBody>
    </Card>
  );
};
