import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader } from "../../_metronic/_partials/controls";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useSubheader } from "../../_metronic/layout";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import moment from "moment";
import { baseURL } from "../service/API.js";
import TextField from "@material-ui/core/TextField";
import Pagination from "@material-ui/lab/Pagination";
import * as XLSX from "xlsx";

export const Report = () => {
  const suhbeader = useSubheader();
  const [reportData, setReportData] = useState([]);
  // const [report8_columns, setReport8_columns] = useState([]);
  const [reportValue, setReportValue] = useState(0);
  const [loading, setLoading] = useState(false);

  var d = new Date();
  d.setDate(d.getDate() - 30);
  const [dateFrom, setDateFrom] = useState(moment(d).format("YYYY-MM-DD"));
  const [dateTo, setDateTo] = useState(moment(new Date()).format("YYYY-MM-DD"));
  suhbeader.setTitle("Report");
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(100);
  const bindReport = (id) => {
    setReportValue(id);
    setReportData([]);
    setColumns([]);
    var data = {
      report_id: id,
      date_from: moment(dateFrom).format("YYYY-MM-DD"),
      date_to: moment(dateTo).format("YYYY-MM-DD"),
    };

    bindData(id, data, page, sizePerPage);
  };
  const bindColumn = (data, report_id) => {
    var key = [];
    var _columns = [];
    key = Object.keys(data[0]);
    for (let index = 0; index < key.length; index++) {
      var field = key[index];
      if (field !== "Privilege Image") {
        _columns.push({
          dataField: field,
          text: field,
          sort: true,
          csvType: String,
          sortCaret: sortCaret,
          headerSortingClasses,
        });
      } else {
        _columns.push({
          dataField: "Privilege Image",
          text: "Privilege Image",
          csvType: String,
          formatter: imageFormatter,
        });
      }
    }

    if (Number(report_id) === 10) {
      _columns.push({
        text: "Action",
        dataField: "Authentication key",
        formatter: (id, row, rowIndex) => {
          return (
            <div>
              <button
                type="button"
                className="btn btn-primary m-2"
                onClick={() => copyItem({ id, row, rowIndex })}
              >
                Copy
              </button>
            </div>
          );
        },
      });
    }
    setColumns(_columns);
  };
  function copy(text) {
    var input = document.createElement("input");
    input.setAttribute("value", text);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand("copy");
    document.body.removeChild(input);
    return result;
  }

  const copyItem = (data) => {
    copy(data.id);
    alert("Copied the text: " + data.id);
  };
  const bindFormatDate = (data, id) => {
    data.map((row) => {
      try {
        if (row["Start Date"]) {
          row["Start Date"] = moment
            .tz(
              moment.utc(row["Start Date"]).format("YYYY-MM-DDTHH:mm:ssZ"),
              "Asia/Bangkok"
            )
            .format("YYYY-MM-DD HH:mm:ss");
        }
        if (row["End Date"]) {
          row["End Date"] = moment
            .tz(
              moment.utc(row["End Date"]).format("YYYY-MM-DDTHH:mm:ssZ"),
              "Asia/Bangkok"
            )
            .format("YYYY-MM-DD HH:mm:ss");
        }
        if (Number(id) === 4) {
          if (row["Created Date"]) {
            row["Created Date"] = moment
              .tz(row["Created Date"], "Asia/Bangkok")
              .format("YYYY-MM-DD HH:mm:ss");
          }
        } else {
          if (row["Created Date"]) {
            row["Created Date"] = moment
              .tz(
                moment.utc(row["Created Date"]).format("YYYY-MM-DDTHH:mm:ssZ"),
                "Asia/Bangkok"
              )
              .format("YYYY-MM-DD HH:mm:ss");
          }
        }
        if (row["Last Run at"]) {
          row["Last Run at"] = moment
            .tz(row["Last Run at"], "Asia/Bangkok")
            .format("YYYY-MM-DD HH:mm:ss");
        }
        if (row["First shipping month"]) {
          row["First shipping month"] = moment
            .tz(
              moment
                .utc(row["First shipping month"])
                .format("YYYY-MM-DDTHH:mm:ssZ"),
              "Asia/Bangkok"
            )
            .format("YYYY-MM-DD HH:mm:ss");
        }
        if (row["Date"]) {
          row["Date"] = moment
            .tz(
              moment.utc(row["Date"]).format("YYYY-MM-DDTHH:mm:ssZ"),
              "Asia/Bangkok"
            )
            .format("YYYY-MM-DD HH:mm:ss");
        }
        if (row["Transaction Date"]) {
          row["Transaction Date"] = moment
            .tz(
              moment
                .utc(row["Transaction Date"])
                .format("YYYY-MM-DDTHH:mm:ssZ"),
              "Asia/Bangkok"
            )
            .format("YYYY-MM-DD HH:mm:ss");
        }
        if (row["Created_at"]) {
          row["Created_at"] = moment
            .tz(
              moment.utc(row["Created_at"]).format("YYYY-MM-DDTHH:mm:ssZ"),
              "Asia/Bangkok"
            )
            .format("YYYY-MM-DD HH:mm:ss");
        }
        if (row["Redemption Date"]) {
          row["Redemption Date"] = moment
            .tz(
              moment.utc(row["Redemption Date"]).format("YYYY-MM-DDTHH:mm:ssZ"),
              "Asia/Bangkok"
            )
            .format("YYYY-MM-DD HH:mm:ss");
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
  const bindData = (report_id, data, page, sizePerPage) => {
    if (Number(report_id) === 6) {
      axios
        .get(`${baseURL}/privilege/deals/report?page=${page}&limit=${sizePerPage}`)
        .then((res) => {
          if (res.data.data.length > 0) {
            var newState = [];
            res.data.data.map((item) => {
              var quota = item.dealRules.find(
                (x) => x.type === "quota_campaign"
              );
              var quota_display = item.dealRules.find(
                (x) => x.type === "display"
              );

              let start_date = quota_display
                ? moment
                    .tz(quota_display.start_date, "Asia/Bangkok")
                    .format("YYYY-MM-DD HH:mm:ss")
                : "";
              let end_date = quota_display
                ? moment
                    .tz(quota_display.end_date, "Asia/Bangkok")
                    .format("YYYY-MM-DD HH:mm:ss")
                : "";
              let requested_date = item.last_redeem
                ? moment
                    .tz(item.last_redeem.requested_date, "Asia/Bangkok")
                    .format("YYYY-MM-DD HH:mm:ss")
                : "";

              newState.push({
                "Privilege ID": item.id,
                "Start Date": quota_display ? String(start_date) : "",
                "End Date": quota_display ? String(end_date) : "",
                "Privilege Image": item.deal_image,
                "Privilege Name":
                  item.deal_name === "" || item.deal_name === null
                    ? ""
                    : item.deal_name,
                "Privilege Name TH":
                  item.deal_name_th === "" || item.deal_name_th === null
                    ? ""
                    : item.deal_name_th,
                "Privilege Point": item.point,
                "Privilege Quota":
                  quota === undefined ? "" : quota.period_limit,
                "Total Redemption":
                  quota === undefined
                    ? ""
                    : Number(quota.period_limit) - Number(item.remain),
                "Remaining Quota": Number(item.remain),
                "Total Point": Number(item.point) * Number(item.used.total),
                "Last Redemption Date": item.last_redeem ? requested_date : "",
              });
            });
            var totalpage = Math.ceil(res.data.total / res.data.perPage);
            setTotalPage(totalpage);
            setTotalSize(res.data.total);
            setPage(res.data.currentPage);
            setSizePerPage(res.data.perPage);

            bindColumn(newState, reportValue);
            setReportData(newState);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(`${baseURL}/report?page=${page}&limit=${sizePerPage}`, data)
        .then((res) => {
          if (res.data.data.length > 0) {
            var totalpage = Math.ceil(res.data.total / res.data.limit);
            setTotalPage(totalpage);
            setTotalSize(res.data.total);
            setPage(res.data.page);
            setSizePerPage(res.data.limit);
            bindColumn(res.data.data, reportValue);
            bindFormatDate(res.data.data, reportValue);
            setReportData(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const changeReportDateFrom = (_dateFrom) => {
    setDateFrom(_dateFrom);
    setReportData([]);
    setColumns([]);
    var data = {
      report_id: reportValue,
      date_from: moment(_dateFrom).format("YYYY-MM-DD"),
      date_to: moment(dateTo).format("YYYY-MM-DD"),
    };
    bindData(reportValue, data, page, sizePerPage);
  };

  const changeReportDateTo = (dateTo) => {
    setDateTo(dateTo);
    setReportData([]);
    setColumns([]);
    var data = {
      report_id: reportValue,
      date_from: moment(dateFrom).format("YYYY-MM-DD"),
      date_to: moment(dateTo).format("YYYY-MM-DD"),
    };
    bindData(reportValue, data, page, sizePerPage);
  };

  const exportExcel = () => {
    setLoading(true);
    const headers = [];
    const rows = [];
    const reportTitle =
      Number(reportValue) === 1
        ? "shipment-history"
        : Number(reportValue) === 4
        ? "customer-profile"
        : Number(reportValue) === 5
        ? "summary-email"
        : Number(reportValue) === 6
        ? "inventory-report"
        : Number(reportValue) === 7
        ? "transaction-user-reward"
        : Number(reportValue) === 8
        ? "user-rank-up"
        : Number(reportValue) === 9
        ? "api-log"
        : Number(reportValue) === 10
        ? "authentication-key-by-site"
        : Number(reportValue) === 11
        ? "email-log"
        : Number(reportValue) === 12
        ? "voucher-for-financial"
        : null;

    columns.map((c) => {
      headers.push(c.dataField);
    });

    if (Number(reportValue) === 6) {
      axios
        .get(
          `${baseURL}/privilege/deals/report?page=${page}&limit=${totalSize}`
        )
        .then((res) => {
          bindFormatDate(res.data.data, reportValue);

          if (res.data.data.length > 0) {
            var newState = [];
            res.data.data.map((item) => {
              var quota = item.dealRules.find(
                (x) => x.type === "quota_campaign"
              );
              var quota_display = item.dealRules.find(
                (x) => x.type === "display"
              );

              // console.log(Number(item.remain))
              // console.log(Number(quota.period_limit) - Number(item.remain))

              let start_date = quota_display
                ? moment
                    .tz(quota_display.start_date, "Asia/Bangkok")
                    .format("YYYY-MM-DD HH:mm:ss")
                : "";
              let end_date = quota_display
                ? moment
                    .tz(quota_display.end_date, "Asia/Bangkok")
                    .format("YYYY-MM-DD HH:mm:ss")
                : "";
              let requested_date = item.last_redeem
                ? moment
                    .tz(item.last_redeem.requested_date, "Asia/Bangkok")
                    .format("YYYY-MM-DD HH:mm:ss")
                : "";

              newState.push({
                "Privilege ID": item.id,
                "Start Date": quota_display ? String(start_date) : "",
                "End Date": quota_display ? String(end_date) : "",
                "Privilege Image": item.deal_image,
                "Privilege Name":
                  item.deal_name === "" || item.deal_name === null
                    ? ""
                    : item.deal_name,
                "Privilege Name TH":
                  item.deal_name_th === "" || item.deal_name_th === null
                    ? ""
                    : item.deal_name_th,
                "Privilege Point": item.point,
                "Privilege Quota":
                  quota === undefined ? "" : quota.period_limit,
                "Total Redemption":
                  quota === undefined
                    ? ""
                    : Number(quota.period_limit) - Number(item.remain),
                "Remaining Quota": Number(item.remain),
                "Total Point": Number(item.point) * Number(item.used.total),
                "Last Redemption Date": item.last_redeem ? requested_date : "",
              });
            });

            newState.map((item, num) => {
              const alphaNumOut = Object.keys(item).map((key) => item[key]);
              rows.push(alphaNumOut);
            });
            bindFormatDate(res.data.data, reportValue);

            const datas = [headers, ...rows];

            const fileName = `export-dhl-${reportTitle}.xlsx`;
            const ws = (XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([[]]));
            const wb = (XLSX.WorkBook = XLSX.utils.book_new());

            XLSX.utils.sheet_add_aoa(ws, datas);
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            XLSX.writeFile(wb, fileName);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      var data = {
        report_id: reportValue,
        date_from: moment(dateFrom).format("YYYY-MM-DD"),
        date_to: moment(dateTo).format("YYYY-MM-DD"),
      };

      axios
        .post(`${baseURL}/report?page=${page}&limit=${totalSize}`, data)
        .then((res) => {
          bindFormatDate(res.data.data, reportValue);
          if (res.data.data.length > 0) {
            res.data.data.map((item, num) => {
              const alphaNumOut = Object.keys(item).map((key) => item[key]);
              rows.push(alphaNumOut);
            });

            const datas = [headers, ...rows];

            const fileName = `export-dhl-${reportTitle}.xlsx`;
            const ws = (XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([[]]));
            const wb = (XLSX.WorkBook = XLSX.utils.book_new());

            XLSX.utils.sheet_add_aoa(ws, datas);
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            XLSX.writeFile(wb, fileName);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function imageFormatter(cell, row) {
    return (
      <input
        type="image"
        src={cell}
        alt="priv-image"
        width="48"
        height="48"
      ></input>
    );
  }

  const handlePageChange = (event, value) => {
    setPage(value);
    var data = {
      report_id: reportValue,
      date_from: moment(dateFrom).format("YYYY-MM-DD"),
      date_to: moment(dateTo).format("YYYY-MM-DD"),
    };
    bindData(reportValue, data, value, sizePerPage);
  };

  const handlePageSizeChange = (event) => {
    setSizePerPage(event.target.value);
    setPage(1);
    var data = {
      report_id: reportValue,
      date_from: moment(dateFrom).format("YYYY-MM-DD"),
      date_to: moment(dateTo).format("YYYY-MM-DD"),
    };
    bindData(reportValue, data, 1, event.target.value);
  };

  return (
    <Card>
      <CardHeader title="Report">
        <select
          as="select"
          className="m-3"
          style={{ width: "300px" }}
          defaultValue={0}
          onChange={(name) => bindReport(name.target.value)}
        >
          <option disabled value={0}>
            {" "}
            Please Select ...
          </option>
          <option value={1}>Shipment History</option>
          <option value={4}>Customer Profile</option>
          <option value={5}>Summary Email</option>
          <option value={6}>Inventory Report</option>
          <option value={7}>Transaction User Reward</option>
          <option value={8}>User Rank Up</option>
          <option value={9}>API Log</option>
          <option value={10}>Authentication Key By Site</option>
          <option value={11}>Email Log</option>
        </select>
      </CardHeader>
      <CardBody>
        {Number(reportValue) !== 0 &&
        Number(reportValue) !== 6 &&
        Number(reportValue) !== 8 &&
        Number(reportValue) !== 10 ? (
          <>
            <TextField
              id="DateFrom"
              type="date"
              label="Date From"
              defaultValue={dateFrom}
              className="mt-3 mb-3 mr-3"
              style={{
                height: "38px",
                width: "185px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(name) => changeReportDateFrom(name.target.value)}
            />
            <TextField
              id="DateFrom"
              type="date"
              label="Date To"
              defaultValue={dateTo}
              className="mt-3 mb-3 mr-3"
              style={{
                height: "38px",
                width: "185px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(name) => changeReportDateTo(name.target.value)}
            />
          </>
        ) : null}

        {columns.length > 0 ? (
          <>
            <ToolkitProvider
              keyField="id"
              data={reportData}
              columns={columns}
              exportCSV={{
                blobType: "data:text/csv;charset=utf-8",
              }}
            >
              {(props) => (
                <div>
                  <hr />
                  {loading === true ? (
                    <button
                      style={{ width: "150px" }}
                      className="btn btn-primary"
                      type="button"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <button
                      type="button"
                      id="button"
                      className="btn btn-primary"
                      style={{ width: "150px" }}
                      onClick={() => exportExcel()}
                    >
                      Export CSV
                    </button>
                  )}
                  {reportData ? (
                    <>
                      <BootstrapTable
                        {...props.baseProps}
                        data={reportData}
                        columns={columns}
                        wrapperClasses="table-responsive"
                        bordered={false}
                        classes="table table-head-custom table-vertical-center overflow-hidden"
                        bootstrap4
                      />

                      <div className=" col-lg-12">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mt-3">
                              {"Items per Page: "}
                              <select
                                onChange={handlePageSizeChange}
                                value={sizePerPage}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <Pagination
                              className="mt-3 "
                              count={totalPage}
                              page={page}
                              siblingCount={1}
                              boundaryCount={1}
                              variant="outlined"
                              shape="rounded"
                              onChange={handlePageChange}
                            />
                          </div>
                        </div>
                      </div>
                      <span className="react-bootstrap-table-pagination-total">
                        Showing {page === 1 ? 1 : sizePerPage * (page - 1) + 1}{" "}
                        to{" "}
                        {page === 1
                          ? sizePerPage
                          : page === totalPage
                          ? totalSize
                          : sizePerPage * page}{" "}
                        of {totalSize} Results
                      </span>
                    </>
                  ) : null}
                </div>
              )}
            </ToolkitProvider>
          </>
        ) : (
          ""
        )}
      </CardBody>
    </Card>
  );
};
